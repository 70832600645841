import React from 'react';

const TransactionIcon = () => {
	return (
		<svg
			width={32}
			height={32}
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M16 4C9.3727 4 4 9.3727 4 16C4 22.6273 9.3727 28 16 28C22.6273 28 28 22.6273 28 16C28 9.3727 22.6277 4 16 4ZM18.6894 20.6044C18.6894 20.8166 18.5178 20.9882 18.3056 20.9882H12.5506V22.1392C12.561 22.4539 12.1603 22.6417 11.924 22.4363L7.70373 18.9833C7.61489 18.9103 7.5631 18.8013 7.5631 18.6862C7.5631 18.5711 7.61489 18.4621 7.70373 18.3891L11.924 14.936C12.1599 14.7289 12.561 14.9198 12.5506 15.2331V16.3841H18.3056C18.5178 16.3841 18.6894 16.5557 18.6894 16.7679V20.6044ZM24.3035 13.6117L20.0832 17.0647C19.8473 17.2719 19.4458 17.0809 19.4566 16.7676V15.6166H13.7016C13.4894 15.6166 13.3178 15.445 13.3178 15.2328V11.3963C13.3178 11.1841 13.4894 11.0125 13.7016 11.0125H19.4566V9.86152C19.4566 9.71297 19.5422 9.57844 19.6757 9.51514C19.8098 9.45147 19.9685 9.47054 20.0832 9.56441L24.3035 13.0174C24.4819 13.1635 24.4819 13.4656 24.3035 13.6117Z"
				fill="url(#paint0_linear_243_5685)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_243_5685"
					x1={4}
					y1={28}
					x2={28}
					y2={28}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FFBF1A" />
					<stop offset={1} stopColor="#FCD535" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default TransactionIcon;
