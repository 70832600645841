import React from 'react';
import TableAdManagement from '../../components/Account/AdManagement/TableAdManagement';
import TabsAdManagement from '../../components/Account/AdManagement/TabsAdManagement';

const AdManagementPage = () => {
	return (
		<div>
			<TabsAdManagement />
			<TableAdManagement />
		</div>
	);
};

export default AdManagementPage;
