import React from 'react';

export default function BlackListIcon() {
	return (
		<svg
			width={20}
			height={20}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10 1C5.0356 1 1 5.0356 1 10C1 14.9608 5.0356 19 10 19C14.9608 19 19 14.9608 19 10C19 5.0356 14.9608 1 10 1ZM5.22028 13.199C5.20552 13.199 5.19076 13.1979 5.17564 13.1961C5.06548 13.1824 4.96792 13.1187 4.91104 13.0236C4.36276 12.1046 4.07296 11.0591 4.07296 10C4.07296 6.73192 6.73156 4.07332 9.99928 4.07332C11.0573 4.07332 12.1031 4.36348 13.0229 4.9114C13.1183 4.96828 13.182 5.0662 13.1957 5.176C13.2094 5.28652 13.1716 5.39668 13.0931 5.47516L5.4748 13.0935C5.40712 13.1612 5.31532 13.199 5.22028 13.199ZM9.99964 15.9256C8.94052 15.9256 7.89472 15.6358 6.976 15.0875C6.8806 15.031 6.81688 14.9331 6.8032 14.8229C6.78952 14.7128 6.82732 14.6026 6.9058 14.5241L14.5241 6.9058C14.6026 6.82732 14.7142 6.79096 14.8233 6.8032C14.9334 6.81688 15.0314 6.8806 15.0879 6.976C15.6362 7.8958 15.926 8.9416 15.926 9.99964C15.926 13.267 13.2674 15.9256 9.99964 15.9256Z"
				fill="#737283"
			/>
		</svg>
	);
}
