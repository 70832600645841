import { Box } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import DepositModal from '../../components/Wallets/DepositModal/DepositModal';
import TransferModal from '../../components/Wallets/TransferModal/TransferModal';
import WithdrawModal from '../../components/Wallets/WithdrawModal/WithdrawModal';
import TradeHistoryModal from '../../components/Wallets/TradeHistoryModal';
import { ROUTES } from '../../routes/config';
import Footer from './Footer';
import TopNav from './TopNav';
import { LayoutWrapper } from './styles';

export default function MainLayout() {
	const topNavRef = useRef<any>();
	const footerRef = useRef<any>();
	const [footerHeight, setFooterHeight] = useState(0);
	const [ptBody, setPtBody] = useState(0);
	const location = useLocation();

	useEffect(() => {
		if (footerRef.current) {
			setFooterHeight(footerRef.current?.clientHeight || 0);
		}
		if (topNavRef.current) {
			setPtBody(topNavRef.current?.clientHeight || 0);
		}
	}, [topNavRef.current, footerRef.current]);

	return (
		<>
			<LayoutWrapper>
				<Box
					display={{
						base: [
							ROUTES.HOME,
							ROUTES.LOGIN,
							ROUTES.REGISTER,
							ROUTES.P2P_BUY_SELL,
							ROUTES.P2P_ADS,
							ROUTES.ABOUT_US,
							ROUTES.PRIVACY_POLICY,
							ROUTES.RISH_DISCLAIMER,
							ROUTES.USER_AGREEMENT,
						].includes(location.pathname)
							? 'block'
							: 'none',
						lg: 'block',
					}}
				>
					<TopNav ref={topNavRef} />
				</Box>

				<Box
					minH={footerHeight ? `calc(100vh - ${footerHeight}px)` : 'auto'}
					pt={`${ptBody}px`}
					bg={'#050713'}
				>
					<Outlet />
				</Box>

				<div ref={footerRef}>
					<Footer />
				</div>
			</LayoutWrapper>
			<DepositModal />
			<WithdrawModal />
			<TransferModal />
			<TradeHistoryModal />
		</>
	);
}
