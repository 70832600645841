import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import TableUI from '../../UI/TableUI';

const columns = [
	{
		key: 'time',
		title: 'Time',
	},
	{
		key: 'id',
		title: 'ID',
	},
	{
		key: 'currency',
		title: 'Currency',
	},
	{
		key: 'price',
		title: 'Price',
	},
	{
		key: 'amount',
		title: 'Amount',
	},
	{
		key: 'status',
		title: 'Status',
		render: (value: boolean) =>
			value ? (
				<Box color="#21FF0E">Successful</Box>
			) : (
				<Box color={'#FF0E39'}>Failed</Box>
			),
	},
];

const dataTable: { [key: string]: any }[] = [
	{
		time: '2023-05-06 16:10:50',
		id: '32055017',
		currency: 'Bitcoin',
		price: '29,365.62',
		amount: '0,365.83',
		status: false,
	},
	{
		time: '2023-05-06 16:10:50',
		id: '32055017',
		currency: 'Bitcoin',
		price: '29,365.62',
		amount: '0,365.83',
		status: true,
	},
	{
		time: '2023-05-06 16:10:50',
		id: '32055017',
		currency: 'Bitcoin',
		price: '29,365.62',
		amount: '0,365.83',
		status: true,
	},
];

const TableAdManagement = () => {
	return (
		<>
			<Box display={{ base: 'none', md: 'block' }}>
				<TableUI isBorder={true} columns={columns} dataTable={dataTable} />
			</Box>

			<Box display={{ base: 'block', md: 'none' }}>
				{dataTable.map((data, index) => {
					return (
						<Flex
							justifyContent={'space-between'}
							key={index}
							borderTop={'1px solid #FFFFFF1E'}
							p={'20px'}
						>
							<Box>
								{columns.slice(0, 3).map((col) => (
									<Flex key={col.key}>
										<Box w={'60px'} color={'#737283'} fontSize={'12px'}>
											{col.title}:{' '}
										</Box>
										<Box fontSize={'14px'}>{data[col.key]}</Box>
									</Flex>
								))}
							</Box>
							<Box>
								{columns.slice(3, columns.length).map((col) => (
									<Flex key={col.key}>
										<Box w={'60px'} color={'#737283'} fontSize={'12px'}>
											{col.title}:{' '}
										</Box>
										<Box fontSize={'14px'}>
											{data[col.key] === true ? (
												<Text color={'#21FF0E'}>Successful</Text>
											) : data[col.key] === false ? (
												<Text color={'#FF0E39'}>Failed</Text>
											) : (
												data[col.key]
											)}
										</Box>
									</Flex>
								))}
							</Box>
						</Flex>
					);
				})}
			</Box>
		</>
	);
};

export default TableAdManagement;
