import {
	Box,
	Flex,
	Grid,
	GridItem,
	Input,
	InputGroup,
	InputLeftElement,
	InputProps,
	InputRightElement,
	Text,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import React, { useEffect, useMemo, useState } from 'react';
import MinusIcon from '../../Icons/MinusIcon';
import PlusIcon from '../../Icons/PlusIcon';
import QuestionIcon from '../../Icons/QuestionIcon';
import SelectCustom from '../../UI/SelectCustom';
import { Label, NextButton } from './PostAdModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { mapAssetsToOptions } from '../../../utils/asset';
import {
	AdOrderFiatSymbol,
	AdOrderType,
	AdOrderAssetSymbol,
	AdOrderPriceType,
} from 'skychain-p2p-trading-js/lib/dto/ad-order.dto';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '../../Wallets/TransferModal/styles';

const FiatOptions = Object.keys(AdOrderFiatSymbol).map((key) => ({
	value: AdOrderFiatSymbol[key as keyof typeof AdOrderFiatSymbol] as string,
	key: AdOrderFiatSymbol[key as keyof typeof AdOrderFiatSymbol] as string,
}));

interface FormData {
	type: AdOrderType;
	assetSymbol: AdOrderAssetSymbol;
	fiatSymbol: AdOrderFiatSymbol;
	priceType: AdOrderPriceType;
	priceValue: number;
}
interface SetTypeAndPriceStepProps {
	onNextStep: (data: FormData) => void;
	data: FormData;
}
export default function SetTypeAndPriceStep({
	onNextStep,
	data,
}: SetTypeAndPriceStepProps) {
	const supportedAssets = useSelector(
		(state: RootState) => state.system.supportedAssets
	);
	const supportedAssetsOptions = useMemo(
		() => mapAssetsToOptions(supportedAssets),
		[supportedAssets]
	);

	const [selectedAsset, setSelectedAsset] = useState<Option>(
		supportedAssetsOptions[0]
	);
	const [selectedFiat, setSelectedFiat] = useState<Option>(FiatOptions[0]);
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		watch,
		clearErrors,
	} = useForm<FormData>({
		defaultValues: {
			type: AdOrderType.BUY,
			priceType: AdOrderPriceType.FIXED,
			assetSymbol: selectedAsset.value as AdOrderAssetSymbol,
			fiatSymbol: selectedFiat.value as AdOrderFiatSymbol,
		},
	});
	useEffect(() => {
		if (data) {
			setValue('type', data.type);
			setValue('priceType', data.priceType);
			setValue('assetSymbol', data.assetSymbol);
			setValue('fiatSymbol', data.fiatSymbol);
			setValue('priceValue', data.priceValue);
		}
	}, [data]);
	const onSelectFiatSymbol = (option: Option) => {
		setSelectedFiat(option);
		setValue('fiatSymbol', option.value as AdOrderFiatSymbol);
	};
	const onSelectAsset = (option: Option) => {
		setSelectedAsset(option);
		setValue('assetSymbol', option.value as AdOrderAssetSymbol);
	};
	const handleNextStep = (data: FormData) => {
		onNextStep(data);
	};
	return (
		<form onSubmit={handleSubmit(handleNextStep)}>
			<Grid
				// templateRows="repeat(2, 1fr)"
				templateColumns="repeat(2, 1fr)"
				gap={{ base: '10px', lg: '20px' }}
			>
				<GridItem colSpan={1}>
					<OptionBlock
						content="Buy"
						{...register('type', {
							required: 'This field is required.',
						})}
						isActive={watch('type') === AdOrderType.BUY}
						onClick={() => setValue('type', AdOrderType.BUY)}
					/>
				</GridItem>
				<GridItem colSpan={1}>
					<OptionBlock
						content="Sell"
						isActive={watch('type') === AdOrderType.SELL}
						onClick={() => setValue('type', AdOrderType.SELL)}
					/>
				</GridItem>
				<GridItem colSpan={1}>
					<Box w={{ base: '100%' }}>
						<Label mb={{ base: '10px' }}>Asset</Label>
						<SelectCustom
							value={selectedAsset}
							list={supportedAssetsOptions}
							onSelect={onSelectAsset}
						/>
					</Box>
				</GridItem>
				<GridItem colSpan={1}>
					<Box w={{ base: '100%' }}>
						<Flex alignItems={'center'} gap="5px" mb={{ base: '10px' }}>
							<Label>With Fiat</Label>
							<QuestionIcon width={13} height={13} />
						</Flex>
						<SelectCustom
							value={selectedFiat}
							list={FiatOptions}
							onSelect={onSelectFiatSymbol}
						/>
					</Box>
				</GridItem>
				<GridItem colSpan={1}>
					<Label mb={{ base: '10px' }}>Price Type</Label>
					<OptionBlock
						content="Fixed"
						{...register('priceType', {
							required: 'This field is required.',
						})}
						isActive={watch('priceType') === AdOrderPriceType.FIXED}
						onClick={() => setValue('priceType', AdOrderPriceType.FIXED)}
					/>
				</GridItem>
				<GridItem colSpan={1}>
					<Flex h="100%" alignItems={'flex-end'}>
						<OptionBlock content="Floating" disabled />
					</Flex>
				</GridItem>
				<GridItem colSpan={2}>
					<Label mb={{ base: '10px' }}>Amount</Label>
					<PriceInput
						type={watch('priceType')}
						register={register('priceValue', {
							required: 'This field is required.',
							validate: (value) => {
								if (value <= 0) {
									return 'Price must be greater than 0';
								}
								return true;
							},
						})}
						error={errors.priceValue?.message}
						increaseValue={() => {
							setValue('priceValue', Number(watch('priceValue')) + 1);
							clearErrors('priceValue');
						}}
						decreaseValue={() => {
							setValue('priceValue', Number(watch('priceValue')) - 1);
							clearErrors('priceValue');
						}}
					/>
					{watch('priceType') === AdOrderPriceType.FIXED ? (
						<Flex
							w={'100%'}
							justifyContent={'space-between'}
							alignItems={'center'}
						>
							<Flex alignItems={'center'} gap="5px" mt={{ base: '10px' }}>
								<Label>Highest Order Price</Label>
								<QuestionIcon width={13} height={13} />
							</Flex>
							<Label>$1,100</Label>
						</Flex>
					) : (
						<>
							<Flex
								w={'100%'}
								justifyContent={'space-between'}
								alignItems={'center'}
							>
								<Flex alignItems={'center'} gap="5px" mt={{ base: '10px' }}>
									<Label>Your Price</Label>
								</Flex>
								<Label>0</Label>
							</Flex>
							<Flex
								w={'100%'}
								justifyContent={'space-between'}
								alignItems={'center'}
							>
								<Flex alignItems={'center'} gap="5px" mt={{ base: '10px' }}>
									<Label>Lowest Order Price</Label>
									<QuestionIcon width={13} height={13} />
								</Flex>
								<Label>$1,100</Label>
							</Flex>
						</>
					)}
				</GridItem>

				<GridItem colSpan={2}>
					<NextButton type="submit">Next</NextButton>
				</GridItem>
			</Grid>
		</form>
	);
}

interface OptionBlockProps extends React.HTMLAttributes<HTMLDivElement> {
	content: string;
	isActive?: boolean;
	disabled?: boolean;
}
const OptionBlock = ({
	content,
	isActive,
	disabled,
	...props
}: OptionBlockProps) => {
	return (
		<Flex
			w={'100%'}
			h={{ base: '48px' }}
			alignItems={'center'}
			justifyContent={'center'}
			background={'rgba(255, 255, 255, 0.05)'}
			borderRadius={{ base: '4px' }}
			border={
				isActive
					? '1px solid rgba(255, 255, 255, 0.5)'
					: '1px solid rgba(255, 255, 255, 0.08)'
			}
			cursor={disabled ? 'no-drop' : 'pointer'}
			{...props}
		>
			<Text
				fontSize={{ base: '14px' }}
				textColor={isActive ? '#ffffff' : '#393942'}
			>
				{content}
			</Text>
		</Flex>
	);
};

const IconWrapper = styled(Box)`
	cursor: pointer;
	svg > path {
		fill: #737283;
	}
	svg > g > path {
		fill: #737283;
	}
`;

const InputCustom = styled(Input)`
	border: 1px solid #ffffff1e;
`;

const PriceInput = ({
	type,
	register,
	error,
	increaseValue,
	decreaseValue,
	inputProps,
}: {
	type: AdOrderPriceType;
	register: any;
	error?: string;
	increaseValue: any;
	decreaseValue: any;
	inputProps?: InputProps;
}) => {
	return (
		<>
			<InputGroup w={'100%'} h={{ base: '48px' }}>
				<InputCustom
					px="2.5rem"
					focusBorderColor="#FFFFFF1E"
					size={'md'}
					textAlign={'center'}
					type="number"
					{...register}
					{...inputProps}
				/>
				<InputLeftElement width={'20px'} left={'10px'}>
					<IconWrapper onClick={decreaseValue}>
						<MinusIcon width={16} height={16} />
					</IconWrapper>
				</InputLeftElement>
				<InputRightElement
					width={'20px'}
					right={type === AdOrderPriceType.FLOATING ? '22px' : '10px'}
					display={'flex'}
					gap={'10px'}
				>
					{type === AdOrderPriceType.FLOATING && '%'}
					<IconWrapper onClick={increaseValue}>
						<PlusIcon width={16} height={16} />
					</IconWrapper>
				</InputRightElement>
			</InputGroup>
			{error && <ErrorMessage>{error}</ErrorMessage>}
		</>
	);
};
