import React from 'react';
import P2PAccountBanner from '../../components/Wallets/P2PAccount/P2PAccountBanner';
import P2PAccountTable from '../../components/Wallets/P2PAccount/P2PAccountTable';

const P2PAccountPage = () => {
	return (
		<div>
			<P2PAccountBanner />
			<P2PAccountTable />
		</div>
	);
};

export default P2PAccountPage;
