export default function IncreaseSortIcon(props: any) {
	return (
		<svg
			{...props}
			width={6}
			height={3}
			viewBox="0 0 6 3"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1.20711 3C0.761654 3 0.538571 2.46143 0.853553 2.14645L2.64645 0.353554C2.84171 0.158292 3.15829 0.158291 3.35355 0.353554L5.14645 2.14645C5.46143 2.46143 5.23835 3 4.79289 3L1.20711 3Z"
				fill="#737283"
			/>
		</svg>
	);
}
