import {
	Table,
	TableContainer,
	TableProps,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { HTMLAttributes } from 'react';
import { format } from 'date-fns';
import BigNumber from 'bignumber.js';
import { shortenAddress } from '../../utils/address';
import NotFound from '../NotFound/NotFound';
const StyledTh = styled(Th)`
	font-size: 14px;
	line-height: 20px;
	color: #737283;
`;
const StyledTd = styled(Td)`
	font-size: 16px;
`;
const Status = styled.div<{ status: TransactionState }>(
	(props) => `
     color: ${
				props.status === 'COMPLETED'
					? '#21FF0E'
					: props.status === 'FAILED' ||
					  props.status === 'REJECTED' ||
					  props.status === 'INVALID'
					? '#FF0000'
					: props.status === 'PROCESSING'
					? '#FFA500'
					: '#737283'
			}
`
);
interface TransactionHistoryDesktopProps
	extends HTMLAttributes<HTMLDivElement> {
	list: TransactionHistory[];
	tableProps?: TableProps;
}
const TransactionHistoryDesktop: React.FC<TransactionHistoryDesktopProps> = ({
	list,
	tableProps,
	...props
}: TransactionHistoryDesktopProps) => {
	return (
		<TableContainer {...props} style={{ margin: 'auto', width: '100%' }}>
			{list.length === 0 ? (
				<div style={{ width: '100%' }}>
					<NotFound />
				</div>
			) : (
				<Table w={'100%'} overflowX={'auto'} {...tableProps}>
					<Thead>
						<Tr>
							<StyledTh>Token</StyledTh>
							<StyledTh>Time</StyledTh>
							<StyledTh>Amount</StyledTh>
							<StyledTh>Deposit Adrress</StyledTh>
							<StyledTh w={'12%'}>Blockchain Transaction ID</StyledTh>
							<StyledTh isNumeric>Status</StyledTh>
						</Tr>
					</Thead>
					<Tbody>
						{list.map((item: TransactionHistory, index: any) => (
							<Tr key={index}>
								<StyledTd>{item.currency}</StyledTd>
								<StyledTd>{format(new Date(item.time), 'yyyy-MM-dd')}</StyledTd>
								<StyledTd>{new BigNumber(item.amount).toFixed(2)}</StyledTd>
								<StyledTd>{shortenAddress(item.address)}</StyledTd>
								<StyledTd w={'12%'}>
									{shortenAddress(item.transactionId)}
								</StyledTd>
								<StyledTd isNumeric>
									<Status status={item.state as TransactionState}>
										{item.state}
									</Status>
								</StyledTd>
							</Tr>
						))}
					</Tbody>
				</Table>
			)}
		</TableContainer>
	);
};

export default TransactionHistoryDesktop;
