import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import LineBox from '../../UI/LineBox';

const listInformation = [
	{
		key: 1,
		title: 'Crypto Deposit Limit',
		description: 'Enable Deposit',
	},
	{
		key: 2,
		title: 'Crypto Withdrawal Limit',
		description: '50000 USDT Daily',
	},
	{
		key: 3,
		title: 'P2P Transaction Limit',
		description: 'Enable Transaction',
	},
];

const CryptoLimit = () => {
	return (
		<div>
			<Text fontSize={'20px'} my={'30px'} fontWeight={'500'}>
				Crypto limit
			</Text>
			<LineBox />

			<Box my={'30px'}>
				{listInformation.map((info) => (
					<Flex
						key={info.key}
						alignItems={'flex-end'}
						justifyContent={'space-between'}
						mb={'20px'}
					>
						<Text fontSize={'16px'}>{info.title}</Text>
						<Text fontSize={'14px'} color={'#737283'}>
							{info.description}
						</Text>
					</Flex>
				))}
			</Box>
			<LineBox />
		</div>
	);
};

export default CryptoLimit;
