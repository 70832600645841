import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Authentication from '.';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setAccount } from '../../redux/reducers/auth.slice';
import { useToast } from '@chakra-ui/react';
import { ROUTES } from '../../routes/config';
import { USER } from '../../constants';
import { setTokenId } from '../../redux/reducers/system.slice';

const formFields = [
	{
		key: 1,
		label: 'Email or Phone number',
		name: 'emailOrPhone',
		type: 'text',
		placeholder: 'Enter your Email or Phone number...',
		required: true,
	},
	{
		key: 2,
		label: 'Password',
		name: 'password',
		type: 'password',
		placeholder: 'Enter your password...',
		required: true,
	},
];

const Login = () => {
	const { register, handleSubmit } = useForm();
	const navigate = useNavigate();
	const toast = useToast();
	const dispatch = useDispatch();
	const userIdentity = useSelector(
		(state: RootState) => state.system.userIdentity
	);
	const onSubmit = async (value: any) => {
		try {
			const res = await userIdentity?.loginByEmail(
				value.emailOrPhone,
				value.password
			);
			if (res) {
				dispatch(setAccount(res));
				if (res.idToken) dispatch(setTokenId(res.idToken));
				localStorage.setItem(USER, JSON.stringify(res));
				navigate(ROUTES.HOME);
			}
		} catch (error: any) {
			toast({
				title: 'Error',
				description: error?.response?.data?.message || 'Unknown error',
				status: 'error',
			});
		}
	};
	return (
		<Authentication
			title={'Login'}
			titleButton={'Login'}
			subtitle={
				<>
					No Account?{' '}
					<Link
						style={{ color: '#FFBF1A', marginLeft: '4px' }}
						to={'/register'}
					>
						Sign up
					</Link>
				</>
			}
			formFields={formFields}
			register={register}
			onSubmit={handleSubmit(onSubmit)}
			forgotPassword
		/>
	);
};

export default Login;
