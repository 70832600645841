export const ROUTES = {
	ERROR: '*',
	HOME: '/',
	LOGIN: '/login',
	P2P_BUY_SELL: '/p2p/listing',
	P2P_ADS: '/p2p/ads',
	FORGOT_PASSWORD: '/forgot-password',
	RESET_PASSWORD: '/reset-password',
	P2P_ORDERS: '/p2p/orders',
	BUY_SELL_DETAIL: '/order/:id',
	SECURITY_SETTING: '/security-settings',
	REGISTER: '/register',
	ACCOUNT: {
		MAIN: '/account',
		ACCOUNT_LIST: '/account/account-list',
		SAFETY_RECORD: '/account/safety-record',
		PERSONAL_VERIFICATION: '/account/personal-verification',
		P2P_SETTINGS: '/account/p2p-settings',
		ADD_PAYMENT_METHOD: '/account/p2p-settings/add-payment-method',
		AD_MANAGEMENT: '/account/ad-management',
		BLACKLIST: '/account/blacklist',
		API_MANAGEMENT: '/account/api-management',
		GOOGLE_AUTHENTICATION: '/account/google-authentication',
		PHONE_AUTHENTICATION: '/account/phone-authentication',
		ID_VERIFICATION: '/account/id-verification',
	},

	WALLET: {
		MAIN: '/wallet',
		SPOT: '/wallet/spot',
		ASSET_OVERVIEW: '/wallet/asset-overview',
		P2P: '/wallet/p2p',
		FUTURES_ACCOUNT: '/wallet/futures-account',
		MARGIN_ACCOUNT: '/wallet/margin-account',
		ASSET_AUTDIT: '/wallet/asset-audit',
	},
	ABOUT_US: '/about',
	PRIVACY_POLICY: '/privacy',
	USER_AGREEMENT: '/agreement',
	RISH_DISCLAIMER: '/disclaimer',
};
