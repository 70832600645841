import { Box, Flex, Show, Text } from '@chakra-ui/react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes/config';
import ArrowNormalIcon from '../../components/Icons/ArrowNormalIcon';
import styled from '@emotion/styled';
import WalletIcon from '../../components/Icons/Wallet/WalletIcon';
import TradeIcon from '../../components/Icons/Wallet/TradeIcon';
import P2PIcon from '../../components/Icons/Wallet/P2PIcon';
import AssetAuditIcon from '../../components/Icons/Wallet/AssetAuditIcon';

const menus = [
	{
		key: 'asset-overview',
		name: 'Asset Overview',
		icon: <WalletIcon />,
		link: ROUTES.WALLET.ASSET_OVERVIEW,
	},
	{
		key: 'spot-account',
		name: 'Spot Account',
		icon: <TradeIcon />,
		link: ROUTES.WALLET.SPOT,
	},
	{
		key: 'p2p-account',
		name: 'P2P Account',
		icon: <P2PIcon />,
		link: ROUTES.WALLET.P2P,
	},
	{
		key: 'asset-audit',
		name: 'Asset Audit',
		icon: <AssetAuditIcon />,
		link: ROUTES.WALLET.ASSET_AUTDIT,
	},
];

export default function AccountMenu() {
	const location = useLocation();
	return (
		<Box bg={'#09122980'} height={'100%'} width={{ base: '100%', md: '336px' }}>
			{menus.map((menu) => (
				<MenuItem
					key={menu.key}
					icon={menu.icon}
					name={menu.name}
					link={menu.link}
					isActive={location.pathname === menu.link}
				/>
			))}
		</Box>
	);
}

interface MenuItemProps {
	icon: React.ReactNode;
	name: string;
	link: string;
	isActive: boolean;
}

const ItemIcon = styled.div<any>(
	(props) => `svg {
		path {
			fill: ${!props.isActive ? '#737283' : 'white'};
		}
	}`
);

const IconWrapper = styled.div<any>(
	(props) => `svg {
		path {
			fill: ${!props.isActive ? '#737283' : 'white'};
		}
	}`
);

const MenuItem = ({ icon, name, link, isActive }: MenuItemProps) => {
	const navigate = useNavigate();
	return (
		<Box
			p={{ base: '25px 25px 25px 50px', lg: '25px 70px 25px 50px' }}
			bg={isActive ? '#091229' : 'transparent'}
			cursor={'pointer'}
			onClick={() => {
				navigate(link);
			}}
			_hover={{ background: '#091229' }}
		>
			<Flex justifyContent={'space-between'}>
				<Flex alignItems={'center'} gap={'15px'}>
					<ItemIcon isActive={isActive}>{icon}</ItemIcon>

					{/* <ItemIcon
						isActive={isActive}
						src={`/images/icons/${icon}.svg`}
						alt={icon}
					/> */}
					<Text color={isActive ? '#FFFFFF' : '#737283'}>{name}</Text>
				</Flex>
				<Show below="md">
					<IconWrapper isActive={isActive}>
						<ArrowNormalIcon />
					</IconWrapper>
				</Show>
			</Flex>
		</Box>
	);
};
