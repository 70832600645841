import {
	Box,
	Collapse,
	Flex,
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { ColumnsBuySellTable, DataBuySellTable } from '../P2PBuySell/interface';
import TradeItemExpand from '../P2PBuySell/TradeItemExpand';
import { ColumnsAdsTable, DataAdsTable } from '../P2PAds/interface';
import { useNavigate } from 'react-router-dom';

const BuySellTable = ({
	dataTable,
	columns,
	isBorder = true,
	isCollapse = false,
	redirectUrl,
}: {
	dataTable: DataBuySellTable[] | DataAdsTable[] | DataOrdersTable[];
	columns: ColumnsBuySellTable[] | ColumnsAdsTable[] | ColumnsOrdersTable[];
	isBorder?: boolean;
	isCollapse?: boolean;
	redirectUrl?: string;
}) => {
	return (
		<TableContainer mt={'10px'}>
			<Table variant={'simple'} colorScheme={'blackAlpha'}>
				<Thead>
					<Tr>
						{columns.map((col) => (
							<Th
								color={'#737283'}
								textTransform={'capitalize'}
								key={col.key}
								p={'20px 0px'}
								isNumeric={col.isNumeric}
								fontSize={{ base: '14px' }}
								fontWeight={400}
								border={'none'}
								fontFamily={'Roboto'}
								textAlign={col.align ? col.align : 'start'}
							>
								{col.title}
							</Th>
						))}
					</Tr>
				</Thead>
				<Tbody>
					{dataTable.length > 0 &&
						dataTable.map((data: any) => (
							<BodyRow
								key={data.key}
								data={data}
								columns={columns}
								isBorder={isBorder}
								isCollapse={isCollapse}
								redirectUrl={redirectUrl}
							/>
						))}
				</Tbody>
			</Table>
		</TableContainer>
	);
};

export default BuySellTable;

const BodyRow = ({
	data,
	columns,
	isBorder,
	isCollapse,
	redirectUrl,
}: {
	data: any;
	columns: ColumnsBuySellTable[];
	isBorder?: boolean;
	isCollapse?: boolean;
	redirectUrl?: string;
}) => {
	const { isOpen, onToggle } = useDisclosure();
	const navigate = useNavigate();
	return (
		<React.Fragment>
			<Tr
				onClick={() =>
					redirectUrl && navigate(redirectUrl.replace(':id', data.key))
				}
				cursor={redirectUrl ? 'pointer' : 'default'}
				_hover={redirectUrl ? { background: '#ffffff1a' } : {}}
			>
				{columns.map((col, index) => (
					<Td
						key={col.key}
						isNumeric={col.isNumeric}
						borderBottom={'none'}
						borderTop={'1px solid'}
						borderColor={isBorder ? '#FFFFFF1E' : '#ffffff00'}
						p={'0px'}
						fontSize={{ base: '14px' }}
						textAlign={col.align ? col.align : 'start'}
					>
						<Collapse in={!isOpen} animateOpacity>
							<Box p={'16px 0'}>
								{col.render ? (
									index === columns.length - 1 ? (
										<Flex w={'100%'} justifyContent={'flex-end'}>
											<Box cursor={'pointer'} onClick={onToggle}>
												{col.render(data)}
											</Box>
										</Flex>
									) : (
										col.render(data)
									)
								) : (
									data[col.key]
								)}
							</Box>
						</Collapse>
					</Td>
				))}
			</Tr>
			{isCollapse && (
				<Tr>
					<Td colSpan={5} borderBottom={'none'} p={0}>
						<Collapse in={isOpen} animateOpacity>
							<TradeItemExpand data={data} onToggleCollapse={onToggle} />
						</Collapse>
					</Td>
				</Tr>
			)}
		</React.Fragment>
	);
};
