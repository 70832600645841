import { Box, chakra, Flex, Image, Text } from '@chakra-ui/react';
import React from 'react';
import LineBox from '../../UI/LineBox';
import Checked from './icons/Checked';
import CoinIcon from './icons/CoinIcon';
import DepositIcon from './icons/DepositIcon';
import TransactionIcon from './icons/TransactionIcon';
import { useAppSelector } from '../../../redux/store';
import Unverified from '../../Icons/Unverified';

const SecondaryText = chakra(Text, {
	baseStyle: {
		fontSize: 14,
		color: '#737283',
	},
});

const PrimaryText = chakra(Text, {
	baseStyle: {
		fontSize: 16,
		color: '#FFFFFF',
	},
});

const Avatar = chakra(Image, {
	baseStyle: {
		width: {
			base: '76px',
			lg: '110px',
		},
	},
});

const tabs = [
	{
		key: 1,
		title: 'Enable Deposit',
		description: 'Crypto Deposit Limit',
		icon: <DepositIcon />,
	},
	{
		key: 2,
		title: '50000 USDT Daily',
		description: 'Crypto Withdrawal Limit',
		icon: <CoinIcon />,
	},
	{
		key: 3,
		title: 'Enable Transaction',
		description: 'P2P Transaction Limit',
		icon: <TransactionIcon />,
	},
];

interface Props {
	isVerified?: boolean;
}
const CurrenFeature = ({ isVerified }: Props) => {
	const userProfile = useAppSelector((state) => state.user.userProfile);

	return (
		<Box>
			<Text fontSize={'20px'} my={'30px'} fontWeight={'500'}>
				Current Features
			</Text>
			<LineBox />
			<Flex
				flexDir={{ base: 'column', md: 'row' }}
				alignItems={{ base: 'flex-start', md: 'center' }}
				py={'30px'}
				w={'100%'}
			>
				<Flex
					w={{ base: '100%', md: '30%' }}
					gap={'10px'}
					alignItems={{ base: 'flex-start', md: 'center' }}
					justifyContent={{ base: '', lg: 'flex-start' }}
					mb={{ base: '30px', md: '0' }}
				>
					<Box borderRadius={'100px'} overflow={'hidden'}>
						<Avatar
							src={
								userProfile?.avatar ||
								'/images/account-list/personal-avatar.png'
							}
							alt="personal avatar"
						/>
					</Box>
					<Flex direction={'column'} gap={'10px'}>
						<Flex alignItems={'center'} gap="10px">
							<SecondaryText>
								{userProfile?.firstName || '' + ' ' + userProfile?.lastName}
							</SecondaryText>
							<Box cursor={'pointer'}>
								<Image src="/images/icons/edit-icon.svg" alt="edit icon" />
							</Box>
						</Flex>
						<PrimaryText>{userProfile?.email}</PrimaryText>
						<Flex alignItems={'center'}>
							<SecondaryText mr={'12px'}>ID: {userProfile?.id}</SecondaryText>
							{isVerified ? <Checked /> : <Unverified />}
						</Flex>
					</Flex>
				</Flex>
				<Flex
					justifyContent={'space-between'}
					w={{ base: '100%', md: '70%' }}
					flexWrap={'wrap'}
				>
					{tabs.map((tab) => (
						<Flex key={tab.key} py={'1rem'}>
							<Box mr={'8px'} w={'32px'}>
								{tab.icon}
							</Box>
							<Box>
								<Text fontSize={{ base: '16px', md: '22px' }}>{tab.title}</Text>
								<Text fontSize={{ base: '14px', md: '16px' }} color={'#737283'}>
									{tab.description}
								</Text>
							</Box>
						</Flex>
					))}
				</Flex>
			</Flex>
			<LineBox />
		</Box>
	);
};

export default CurrenFeature;
