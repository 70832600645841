import { useRef, useState, useEffect } from 'react';
import WalletMenu from './WalletMenu';
import { Box, Flex, Show } from '@chakra-ui/react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes/config';
import NavigateMobile from '../../components/UI/NavigateMobile';

const navigateTitles = [
	{ route: ROUTES.WALLET.MAIN, title: 'Wallet' },
	{ route: ROUTES.WALLET.ASSET_OVERVIEW, title: 'Asset Overview' },
	{ route: ROUTES.WALLET.SPOT, title: 'Spot Account' },
	{ route: ROUTES.WALLET.P2P, title: 'P2P Account' },
	{ route: ROUTES.WALLET.FUTURES_ACCOUNT, title: 'Futures Account' },
	{ route: ROUTES.WALLET.MARGIN_ACCOUNT, title: 'Margin Account' },
	{ route: ROUTES.WALLET.ASSET_AUTDIT, title: 'Asset Audit' },
];

export default function WalletLayout() {
	const menuRef = useRef<any>();
	const [bodywidth, setBodyWidth] = useState(0);
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (menuRef.current) {
			setBodyWidth(menuRef.current?.clientWidth);
		}
	}, [menuRef.current]);

	const handleBack = () => {
		if (location.pathname === ROUTES.WALLET.MAIN) {
			navigate(ROUTES.HOME);
		} else navigate(ROUTES.WALLET.MAIN);
	};

	return (
		<Flex h={'100%'}>
			<Show above="sm">
				<Box ref={menuRef}>
					<WalletMenu />
				</Box>

				<Box w={{ base: `calc(100% - ${bodywidth}px)` }} p={{ base: '20px' }}>
					<Outlet />
				</Box>
			</Show>

			<Show below="md">
				<Box w="100%">
					<NavigateMobile
						title={
							navigateTitles.find((title) => title.route === location.pathname)
								?.title || ''
						}
						handleBack={handleBack}
					/>
					{location.pathname === ROUTES.WALLET.MAIN ? (
						<WalletMenu />
					) : (
						<Outlet />
					)}
				</Box>
			</Show>
		</Flex>
	);
}
