import React from 'react';
import Container from '../layouts/Container';
import { Box, Flex, Text, chakra } from '@chakra-ui/react';

const Title = chakra(Text, {
	baseStyle: {
		fontSize: {
			base: '32px',
		},
		fontWeight: 500,
	},
});

const Content = chakra(Text, {
	baseStyle: {
		fontSize: {
			base: '16px',
		},
		color: '#737283',
	},
});

export default function AboutUsPage() {
	return (
		<Container>
			<Flex direction={'column'} gap={'20px'} py={{ base: '20px' }}>
				<Title>About Us</Title>

				<Box>
					<Content>2022-01-20</Content>
					<Content>11:45:35</Content>
				</Box>

				<Flex direction={'column'} gap={'20px'}>
					<Content>About Metaniex</Content>

					<Content>
						Founded in 2022, Metaniex is known as the exchange of high
						performance and mega transaction matching technology. The team at
						Metaniex are some of the first movers and pioneers of financial and
						blockchain technology. Currently, Metaniex caters to 6 million+
						users in more than 70 countries around the world and we have just
						started. This magnificent achievement is a significant milestone,
						and Metaniex aims to become the go-to platform for new traders and
						experienced investors as they move forward in their financial
						journey.
					</Content>

					<Content>
						Metaniex is present across continents obtaining key licenses and
						passing jurisdictions in countries like Australia, Estonia and the
						United States. Metaniex also offers localized language support for
						investors from different countries making it easier for them to
						trade. The exchange at Metaniex is a high-performance trading engine
						which has been developed by experts from banking industry and is
						capable of completing 1.4 million transactions per second, which
						results in ground breaking efficiency and enhanced performance. User
						security if of top priority at Metaniex because of which our servers
						are hosted independently across multiple countries ensuring optimal
						data integrity and security.
					</Content>
				</Flex>
			</Flex>
		</Container>
	);
}
