import Banner from '../Banner';

export default function P2PAccountBanner() {
	const actions = ['transfer', 'trade-history'] as BannerAction[];

	const valuation = {
		balance: '0.00000000',
		currency: 'BTC',
	} as Valuation;

	return (
		<Banner
			title={'P2P Account'}
			valuation={valuation}
			yesterdayPNL={'000 00'}
			actions={actions}
			isPayment
			chain="FUNDING"
		/>
	);
}
