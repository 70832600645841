import {
	Modal,
	ModalCloseButton,
	ModalOverlay,
	useToast,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { setModal } from '../../../redux/reducers/system.slice';
import Steps from '../Steps';
import QuestionIcon from '../../Icons/QuestionIcon';
import SelectCustom from '../../UI/SelectCustom';
import { useEffect, useMemo, useState } from 'react';
import WarningIcon from '../../Icons/WarningIcon';
import { QRCodeSVG } from 'qrcode.react';
import TextGradient from '../../UI/TextGradient';
import MetaverseClient from 'metaverse-js';
import { Transaction } from 'metaverse-js/lib/proto/model/wallet';
import { Responsive } from 'metaverse-js/lib/index';
import { format } from 'date-fns';

import {
	ChainItem,
	ChainListWrapper,
	DepositAddressInput,
	DepositAddressWrapper,
	DepositContent,
	DepositHistory,
	Header,
	Label,
	LeftColumn,
	QRCodeWrapper,
	Retangle,
	RightColumn,
	Row,
	StepPanel,
	StyledCopyIcon,
	StyledDepositHistoryDesktop,
	StyledDepositHistoryMobile,
	StyledModalBody,
	StyledModalContent,
	StyledModalHeader,
	Title,
	Warning,
	WarningContent,
} from './styles';
import { DEPOSIT_STEPS } from '../../../constants/deposit';
import BigNumber from 'bignumber.js';
import { mapAssetsToOptions, mapChainsToOptions } from '../../../utils/asset';
import useWalletAddress from '../../../hooks/useWalletAddress';

const DepositModal: React.FC = () => {
	const toast = useToast();
	const modal = useSelector((state: RootState) => state.system.modal);
	const chain = useSelector((state: RootState) => state.system.chain);
	const myAssetBalances = useSelector(
		(state: RootState) => state.user.userAssetBalances
	);
	const dispatch = useDispatch();
	const metaverseJs = useSelector(
		(state: RootState) => state.system.metaverseJs
	);
	const supportedChains = useSelector(
		(state: RootState) => state.system.supportedChains
	);
	const supportedAssets = useSelector(
		(state: RootState) => state.system.supportedAssets
	);
	const [selectedToken, setSelectedToken] = useState<Option | undefined>();
	const [selectedChain, setSelectedChain] = useState<Option | undefined>();
	const [transactions, setTransactions] = useState<TransactionHistory[]>([]);
	const { address: depositAddress } = useWalletAddress(
		selectedChain?.value || ''
	);

	const assetBalance = useMemo(() => {
		if (!selectedToken || !myAssetBalances || !chain) return;
		const res = myAssetBalances.find(
			(asset) =>
				asset.assetSymbol === selectedToken.value && asset.chain === chain
		);
		return res;
	}, [myAssetBalances, selectedToken, chain]);

	const supportedAssetsOptions = useMemo(
		() => mapAssetsToOptions(supportedAssets),
		[supportedAssets]
	);

	const supportedChainOptions = useMemo(
		() => mapChainsToOptions(supportedChains),
		[supportedChains]
	);

	useEffect(() => {
		if (supportedAssetsOptions.length > 0)
			setSelectedToken(supportedAssetsOptions[0]);
	}, [supportedAssetsOptions]);

	useEffect(() => {
		if (supportedChainOptions.length > 0)
			setSelectedChain(supportedChainOptions[0]);
	}, [supportedChainOptions]);

	useEffect(() => {
		if (modal === 'deposit' && metaverseJs) {
			getTransactions(metaverseJs);
		}
	}, [metaverseJs, modal]);

	const getTransactions = async (metaverseJs: MetaverseClient) => {
		try {
			const res: Responsive<Transaction[]> =
				await metaverseJs.getMyTransactions({ type: 'DEPOSIT' });
			const transactions: Transaction[] = res.data;
			setTransactions(
				transactions.map((item) => ({
					currency: item.assetSymbol || '',
					time: item.createdAt
						? format(new Date(item.createdAt * 1000), 'yyyy-MM-dd')
						: '',
					amount: item.assetAmount || '',
					address: item.fromAccount || '',
					transactionId: item.id || '',
					state: item.state || '',
				}))
			);
		} catch (error) {
			console.log('getTransactions', error);
		}
	};
	const handleClose = () => {
		dispatch(setModal(null));
	};

	const handleCopy = (text: string | undefined) => {
		if (!text) return;
		navigator.clipboard.writeText(text);
		toast({
			title: 'Copied!',
			status: 'info',
			duration: 2000,
			isClosable: true,
			position: 'top',
		});
	};

	return (
		<Modal isOpen={modal === 'deposit'} onClose={handleClose} isCentered>
			<ModalOverlay />
			<StyledModalContent>
				<StyledModalHeader>Deposit</StyledModalHeader>
				<ModalCloseButton />
				<StyledModalBody>
					<StepPanel>
						<Steps steps={DEPOSIT_STEPS} />
					</StepPanel>
					<DepositContent>
						<LeftColumn>
							<Label>Tokens</Label>
							<SelectCustom
								list={supportedAssetsOptions}
								value={selectedToken}
								onSelect={(item) => setSelectedToken(item)}
							/>
							<Row>
								<div>Balances</div>
								<div>
									{
										+new BigNumber(assetBalance?.totalAssetAmount || 0).toFixed(
											2
										)
									}
								</div>
							</Row>
							<Row>
								<div>Available</div>
								<div>
									{
										+new BigNumber(
											assetBalance?.availableAssetAmount || 0
										).toFixed(2)
									}
								</div>
							</Row>
							<Row>
								<div>Freeze</div>
								<div>
									{
										+new BigNumber(
											assetBalance?.lockedAssetAmount || 0
										).toFixed(2)
									}
								</div>
							</Row>
							<Row>Tips</Row>
							<Row>
								<Label>Minimum deposit:</Label>
								<div>0.01 {selectedToken?.value}</div>
							</Row>
							<Row>
								<Label>Deposit arrival:</Label>
								<div>After 10 network confirmations</div>
							</Row>
							<Warning>
								<WarningIcon />
								<WarningContent>
									Do not deposit any of the above mentioned addresses in non
									local currency assets, otherwise the assets will not be
									retrievable.
								</WarningContent>
							</Warning>
						</LeftColumn>
						<RightColumn>
							<Label>
								Chain name <QuestionIcon />
							</Label>
							<ChainListWrapper>
								{supportedChainOptions.map((chain) => (
									<ChainItem
										selected={selectedChain?.key === chain.key}
										key={chain.key}
										onClick={() => setSelectedChain(chain)}
									>
										{chain.value}
									</ChainItem>
								))}
							</ChainListWrapper>
							<Label>Deposit address</Label>
							<DepositAddressWrapper>
								<DepositAddressInput readOnly value={depositAddress} />
								<StyledCopyIcon onClick={() => handleCopy(depositAddress)} />
							</DepositAddressWrapper>
							<Label>USDT Deposit address</Label>
							{depositAddress && (
								<QRCodeWrapper>
									<QRCodeSVG value={depositAddress} />
								</QRCodeWrapper>
							)}
						</RightColumn>
					</DepositContent>
					<DepositHistory>
						<Header>
							<Title>Recent</Title>
							<TextGradient>All</TextGradient>
						</Header>
						<Retangle />
						<StyledDepositHistoryDesktop list={transactions} />
						<StyledDepositHistoryMobile list={transactions} />
					</DepositHistory>
				</StyledModalBody>
			</StyledModalContent>
		</Modal>
	);
};

export default DepositModal;
