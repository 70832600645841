import BigNumber from 'bignumber.js';
import { AdOrderType } from 'skychain-p2p-trading-js/lib/dto/ad-order.dto';
import { AdOrderEntity } from 'skychain-p2p-trading-js/lib/entities/ad-order.entity';
import { DataBuySellTable } from '../components/P2PBuySell/interface';
import { UserEntity } from 'user-identity-js/lib/entities';
import { PaymentMethodEntity } from 'skychain-p2p-trading-js/lib/entities/payment-method.entity';
export const convertOrderType = (type: AdOrderType | undefined): string => {
	switch (type) {
		case AdOrderType.BUY:
			return 'Buy';
		case AdOrderType.SELL:
			return 'Sell';
		default:
			return 'Unknown';
	}
};
export const convertToDataTableAds = (
	data: AdOrderEntity,
	users: UserEntity[] | undefined,
	paymentMethods: PaymentMethodEntity[] | undefined
): DataBuySellTable => {
	const user =
		users !== undefined
			? users.find((user) => user.id === data.user)
			: undefined;
	const paymentMethod =
		paymentMethods !== undefined
			? paymentMethods.find(
					(paymentMethod) => paymentMethod.id === data.paymentMethod
			  )
			: undefined;
	return {
		...data,
		key: data.id,
		name: user?.firstName || '' + ' ' + user?.lastName || '',
		price: data.priceValue
			? new BigNumber(data.priceValue || 0).toString()
			: '',
		available: new BigNumber(data?.totalAmount || 0)
			.minus(data.executedAmount)
			.minus(data.pendingAmount)
			.toString(),
		limit: `$${+new BigNumber(data.minAmount || 0).toFormat(
			2
		)} - $${+new BigNumber(data.maxAmount || 0).toFormat(2)}`,
		payment: 'Bank transfer',
		paymentType: 'Bank transfer',
		avatar: user?.avatar || '/images/avatar.svg',
		assetSymbol: data.assetSymbol || '',
		fiatSymbol: data.fiatSymbol || '',
		paymentTimeLimit: `${data.timeLimit?.toString() || '-'} minutes`,
		paymentMethod: paymentMethod?.bankName || '',
		termsAndConditions: [data?.term || ''],
		type: data.type,
	};
};
