import styled from '@emotion/styled';
import BigNumber from 'bignumber.js';
import { format } from 'date-fns';
import { HTMLAttributes } from 'react';
import { shortenAddress } from '../../utils/address';
import NotFound from '../NotFound/NotFound';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
`;
const DetailItem = styled.div`
	display: grid;
	grid-template-columns: 2fr 3fr;
	column-gap: 10px;
	row-gap: 16px;
	padding: 16px 0;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	&:last-child {
		border-bottom: none;
	}
`;
const DetailBlock = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 6px;
`;
const Label = styled.div`
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #737283;
`;
const Value = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	text-align: right;
	color: #ffffff;
	white-space: nowrap;
`;
const Status = styled.div<{ status: TransactionState }>(
	(props) => `
    color: ${
			props.status === 'COMPLETED'
				? '#21FF0E'
				: props.status === 'FAILED' ||
				  props.status === 'REJECTED' ||
				  props.status === 'INVALID'
				? '#FF0000'
				: props.status === 'PROCESSING'
				? '#FFA500'
				: '#737283'
		}
`
);
interface DepositHistoryDesktopProps extends HTMLAttributes<HTMLDivElement> {
	list: TransactionHistory[];
}
const TransactionHistoryMobile: React.FC<DepositHistoryDesktopProps> = ({
	list,
	...props
}: DepositHistoryDesktopProps) => {
	return (
		<Wrapper {...props}>
			{list.length === 0 ? (
				<div style={{ width: '100%' }}>
					<NotFound />
				</div>
			) : (
				<>
					{list.map((item: TransactionHistory, index: number) => (
						<DetailItem key={index}>
							<DetailBlock>
								<Label>Currency:</Label>
								<Value>{item.currency}</Value>
							</DetailBlock>
							<DetailBlock>
								<Label>Time:</Label>
								<Value>{format(new Date(item.time), 'yyyy-MM-dd')}</Value>
							</DetailBlock>
							<DetailBlock>
								<Label>Amount:</Label>
								<Value>{new BigNumber(item.amount).toFixed(2)}</Value>
							</DetailBlock>
							<DetailBlock>
								<Label>Deposit Adrress:</Label>
								<Value>{shortenAddress(item.address)}</Value>
							</DetailBlock>
							<DetailBlock>
								<Label>Blockchain Transaction ID:</Label>
								<Value>{shortenAddress(item.address)}</Value>
							</DetailBlock>
							<DetailBlock>
								<Label>Status</Label>
								<Value>
									<Status status={item.state as TransactionState}>
										{item.state}
									</Status>
								</Value>
							</DetailBlock>
						</DetailItem>
					))}
				</>
			)}
		</Wrapper>
	);
};

export default TransactionHistoryMobile;
