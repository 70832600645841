import {
	Box,
	ListItem,
	Step,
	StepDescription,
	StepIndicator,
	Stepper,
	StepSeparator,
	StepStatus,
	StepTitle,
	Text,
	UnorderedList,
	useSteps,
} from '@chakra-ui/react';
import React from 'react';
import TickIcon from './icons/TickIcon';

// Required
// Proof of address

const steps = [
	{
		title: 'Verified',
		description: {
			title: 'Fiat Limit of 50.000 USD Daily',
			listName: 'Verified:',
			listDescription: [
				'Personal information',
				'Government issued ID',
				'Facial recognition',
			],
		},
	},
	{
		title: 'Verified Plus',
		description: {
			title: 'Fiat Limit of 2M USD Daily',
			listName: 'Required',
			listDescription: ['Proof of address'],
		},
	},
	{
		title: 'Verified Plus',
		description: {
			title: 'Unlimited Fiat Transaction',
			listName: 'Required',
			listDescription: ['Enhanced due dilligence'],
		},
	},
];

const VerificationLevels = () => {
	const { activeStep } = useSteps({
		index: 1,
		count: steps.length,
	});

	return (
		<Box>
			<Text fontSize={'18px'} my={'30px'} fontWeight={'500'}>
				Verification Levels
			</Text>
			<Stepper index={activeStep} orientation="vertical" height="400px" gap="0">
				{steps.map((step, index) => (
					<Step key={index}>
						<StepIndicator>
							<StepStatus
								complete={<TickIcon active={true} />}
								incomplete={<TickIcon active={false} />}
								active={<TickIcon active={false} />}
							/>
						</StepIndicator>

						<Box flexShrink="0">
							<StepTitle>
								<Text color={activeStep === index + 1 ? '#03A66D' : '#737283'}>
									{step.title}
								</Text>
							</StepTitle>
							<StepDescription>
								<Text color={'white'}>{step.description.title}</Text>
								<Text color={'#737283'}>{step.description.listName}</Text>
								<UnorderedList color={'#737283'}>
									{step.description.listDescription.map((desc) => (
										<ListItem key={desc}>{desc}</ListItem>
									))}
								</UnorderedList>
							</StepDescription>
						</Box>
						<StepSeparator />
					</Step>
				))}
			</Stepper>
		</Box>
	);
};

export default VerificationLevels;
