import styled from '@emotion/styled';

export const Wrapper = styled.div`
	border: 1px solid rgba(255, 255, 255, 0.08);
	border-radius: 4px;
	height: 72px;
	box-sizing: border-box;
	width: 100%;
	padding: 16px 20px;
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

export const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
export const Label = styled.div`
	font-size: 16px;
	line-height: 16px;
	color: #737283;
`;

export const Value = styled.div`
	font-size: 16px;
	line-height: 16px;
	color: #ffffff;
`;
export const Content = styled(Header)``;

export const Action = styled(Label)`
	cursor: pointer;
	&:hover {
		color: #fff;
	}
`;
