import { Box, Flex } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import CryptoLimit from '../../components/Account/PersonalVerification/CryptoLimit';
import CurrenFeature from '../../components/Account/PersonalVerification/CurrenFeature';
import NotificationDoc from '../../components/Account/PersonalVerification/NotificationDoc';
import VerificationLevels from '../../components/Account/PersonalVerification/VerificationLevels';
import useMediaQuery from '../../hooks/userMediaQuery';
import { useAppSelector } from '../../redux/store';
import { UserIdentityClient } from 'user-identity-js';
import {
	KYCState,
	UserKYCEntity,
} from 'user-identity-js/lib/entities/kyc.entity';

const PersonalVerificationPage = () => {
	const userIdentity = useAppSelector((state) => state.system.userIdentity);
	const [kycProfile, setKycProfile] = useState<UserKYCEntity | null>(null);
	const { isExtraSmall } = useMediaQuery();

	useEffect(() => {
		if (userIdentity) {
			getKycProfile(userIdentity);
		}
	}, [userIdentity]);

	const getKycProfile = async (userIdentity: UserIdentityClient) => {
		try {
			const kycProfile = await userIdentity.getKYCProfile();
			setKycProfile(kycProfile);
		} catch (error) {
			console.log('getKycProfile', error);
		}
	};
	return (
		<Box p={{ base: '1rem', md: '0' }}>
			<Box w={{ base: '100%', md: '90%' }}>
				<CurrenFeature isVerified={kycProfile?.state === KYCState.VERIFIED} />
			</Box>
			<Flex
				flexDir={{ base: 'column', md: 'row' }}
				justifyContent={'space-between'}
			>
				<Box w={{ base: '100%', md: '70%' }}>
					<NotificationDoc status={kycProfile?.state} />
					<Box display={isExtraSmall ? 'none' : 'block'}>
						<CryptoLimit />
					</Box>
				</Box>
				<Box w={{ base: '100%', md: '25%' }}>
					<VerificationLevels />
				</Box>
				<Box display={!isExtraSmall ? 'none' : 'block'}>
					<CryptoLimit />
				</Box>
			</Flex>
		</Box>
	);
};

export default PersonalVerificationPage;
