import {
	Box,
	chakra,
	Flex,
	Text,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import { differenceInSeconds } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import Container from '../../layouts/Container';
import CopyIcon from '../Icons/CopyIcon';
import BuySellButton from '../UI/BuySellButton';
import ChatIcon from '../Icons/ChatIcon';
import { format } from 'date-fns';
import { ExecuteOrderType } from 'skychain-p2p-trading-js/lib/dto/execute-order.dto';
import { useSelector } from 'react-redux';
import { ExecuteOrderStatus } from 'skychain-p2p-trading-js/lib/dto/execute-order.dto';
import { RootState } from '../../redux/store';
import BigNumber from 'bignumber.js';
import CheckedIcon from '../Icons/CheckedIcon';

const Wrapper = chakra(Box, {
	baseStyle: {
		w: '100%',
		bg: 'rgba(9, 18, 41, 0.5)',
	},
});

const BoxTime = chakra(Box, {
	baseStyle: {
		bg: 'linear-gradient(184deg, rgba(255, 255, 255, 0) -21.23%, rgba(255, 255, 255, 0.256) 78.13%)',
		h: { base: '22px', md: '48px' },
		w: { base: '22px', md: '48px' },
		mr: { base: '4px', md: '8px' },
		p: { base: '2px', md: '10px' },
		fontSize: { base: '12px', md: '20px' },
		textAlign: 'center',
		borderRadius: '4px',
	},
});

interface Props {
	id: string;
	createdAt: number;
	assetSymbol: string | undefined;
	counterpart: string;
	type: ExecuteOrderType;
	user: string;
	timeLimit: number | undefined;
	status: ExecuteOrderStatus;
	amount: number;
	onOpenChat: any;
}
const StatusBar = ({
	id,
	createdAt,
	assetSymbol,
	counterpart,
	type,
	user,
	timeLimit,
	status,
	amount,
	onOpenChat,
}: Props) => {
	const toast = useToast();
	const userProfile = useSelector((state: RootState) => state.user.userProfile);

	const handleCopy = (text: string | undefined) => {
		if (!text) return;
		navigator.clipboard.writeText(text);
		toast({
			title: 'Copied!',
			status: 'info',
			duration: 2000,
			isClosable: true,
			position: 'top',
		});
	};
	return (
		<Wrapper>
			<Container
				display={'flex'}
				flexDir={{ base: 'column', md: 'row' }}
				py={'27px'}
				justifyContent={'space-between'}
			>
				<Flex flexDir={{ base: 'column', md: 'row' }}>
					<Flex justifyContent={'space-between'}>
						<Flex alignItems={{ base: 'center', md: 'flex-start' }}>
							<BuySellButton
								isBuy={
									(userProfile?.id !== user && type === ExecuteOrderType.BUY) ||
									(userProfile?.id === user && type === ExecuteOrderType.SELL)
								}
								w={'85px'}
								h={'40px'}
							>
								{status !== ExecuteOrderStatus.CONFIRM_SUCCESS ? (
									userProfile?.id !== user ? (
										type === ExecuteOrderType.BUY ? (
											'Buy'
										) : (
											'Sell'
										)
									) : type === ExecuteOrderType.BUY ? (
										'Sell'
									) : (
										'Buy'
									)
								) : (
									<CheckedIcon />
								)}
							</BuySellButton>
							<Box mx={'15px'}>
								{status !== ExecuteOrderStatus.CANCEL &&
								status !== ExecuteOrderStatus.CONFIRM_SUCCESS ? (
									<>
										<Text fontSize={'14px'}>
											{userProfile?.id !== user
												? type === ExecuteOrderType.BUY
													? 'Buy'
													: 'Sell'
												: type === ExecuteOrderType.BUY
												? 'Sell'
												: 'Buy'}{' '}
											{assetSymbol}{' '}
											{type === ExecuteOrderType.BUY ? 'from' : 'to'}{' '}
											{counterpart}
										</Text>
									</>
								) : status === ExecuteOrderStatus.CANCEL ? (
									'Order Cancelled'
								) : (
									'Order Completed'
								)}
								{type === ExecuteOrderType.BUY ? (
									<Text
										display={{ base: 'none', md: 'block' }}
										fontSize={'14px'}
										color={'#737283'}
									>
										{status === ExecuteOrderStatus.WAITING
											? 'This order is created, please wait for system confirmation'
											: status === ExecuteOrderStatus.CONFIRM_PAYMENT
											? 'Waiting for payment confirmation from the seller to release order'
											: status === ExecuteOrderStatus.CONFIRM_SUCCESS
											? `You have successfully purchased ${new BigNumber(
													amount || 0
											  ).toFixed(4)} ${assetSymbol || 'USDT'}`
											: ''}
									</Text>
								) : (
									<Text
										display={{ base: 'none', md: 'block' }}
										fontSize={'14px'}
										color={'#737283'}
									>
										{status === ExecuteOrderStatus.PENDING
											? 'Pending buyer’s payment. Time remaining'
											: status === ExecuteOrderStatus.CONFIRM_PAYMENT
											? 'Please confirm that you have received payment from the buyer'
											: status === ExecuteOrderStatus.CONFIRM_SUCCESS
											? `Successfully sold ${new BigNumber(amount || 0).toFixed(
													4
											  )} ${assetSymbol || 'USDT'}`
											: ''}
									</Text>
								)}
							</Box>
						</Flex>

						<Box
							display={{ base: 'block', md: 'none' }}
							cursor={'pointer'}
							onClick={onOpenChat}
						>
							<ChatIcon />
						</Box>
					</Flex>

					{timeLimit && status === ExecuteOrderStatus.WAITING && (
						<Box>
							<Countdown
								deadline={new Date(createdAt * 1000 + timeLimit * 60 * 1000)}
							/>
						</Box>
					)}
				</Flex>

				<Flex
					fontSize={'14px'}
					flexDir={'column'}
					alignItems={'flex-end'}
					w={{ base: '100%', md: 'auto' }}
					mt={{ base: '10px', md: 0 }}
				>
					<Flex
						justifyContent={'space-between'}
						w={{ base: '100%', md: 'auto' }}
					>
						<Text color={'#737283'}>Order Number: </Text>
						<Flex>
							<Text mx={'4px'}>{id || ''}</Text>
							<CopyIcon
								style={{ cursor: 'pointer' }}
								onClick={() => handleCopy(id)}
							/>
						</Flex>
					</Flex>
					<Flex
						justifyContent={'space-between'}
						w={{ base: '100%', md: 'auto' }}
					>
						<Text color={'#737283'}>Time Created: </Text>
						<Text mx={'4px'}>
							{format(new Date(createdAt * 1000), 'MM/dd/yyyy hh:mm a')}
						</Text>
					</Flex>
				</Flex>
			</Container>
		</Wrapper>
	);
};

const Countdown = ({ deadline }: { deadline: Date }) => {
	const ONE_DAY = 60 * 60 * 24;
	const ONE_HOUR = 60 * 60;
	const ONE_MINUTE = 60;
	const [currentTime, setCurrentTime] = useState(new Date().getTime());

	const diffInSeconds = differenceInSeconds(deadline, currentTime);

	const getCountdown = () => {
		if (diffInSeconds <= 1) {
			return {
				days: 0,
				hours: 0,
				minutes: 0,
				seconds: 0,
			};
		}
		const days = Math.floor(diffInSeconds / ONE_DAY);
		const hours = Math.floor((diffInSeconds - days * ONE_DAY) / ONE_HOUR);
		const minutes = Math.floor(
			(diffInSeconds - days * ONE_DAY - hours * ONE_HOUR) / ONE_MINUTE
		);
		const seconds =
			diffInSeconds - days * ONE_DAY - hours * ONE_HOUR - minutes * ONE_MINUTE;
		return {
			days,
			hours,
			minutes,
			seconds,
		};
	};

	const countdown = useMemo(getCountdown, [currentTime]);

	useEffect(() => {
		setInterval(() => {
			const now = new Date().getTime();
			setCurrentTime(now);
		}, 1000);
	}, []);

	return (
		<Flex alignContent={'center'}>
			<BoxTime>
				{(countdown.minutes.toString()[1] && countdown.minutes.toString()[0]) ||
					0}
			</BoxTime>
			<BoxTime>
				{countdown.minutes.toString()[1] ||
					countdown.minutes.toString()[0] ||
					0}
			</BoxTime>
			<BoxTime bg={'transparent'}>:</BoxTime>
			<BoxTime>
				{(countdown.seconds.toString()[1] && countdown.seconds.toString()[0]) ||
					0}
			</BoxTime>
			<BoxTime>
				{countdown.seconds.toString()[1] ||
					countdown.seconds.toString()[0] ||
					0}
			</BoxTime>
		</Flex>
	);
};

export default StatusBar;
