import { createBrowserRouter } from 'react-router-dom';
import AccountLayout from '../layouts/AccountLayout';
import BuySellLayout from '../layouts/BuySellLayout';
import MainLayout from '../layouts/MainLayout';
import WalletLayout from '../layouts/WalletLayout';
import AccountList from '../pages/account/accountList';
import PersonalVerificationPage from '../pages/account/personalVerification';
import P2PBuySellPage from '../pages/buy&sell/p2pBuySell';
import HomePage from '../pages/home';
import LoginPage from '../pages/login';
import RegisterPage from '../pages/register';
import VerificationPage from '../pages/sercurity-verification';
import AssetOverview from '../pages/wallets/assetOverview';
import SpotWalletPage from '../pages/wallets/spot';
import { ROUTES } from './config';
import P2PAdsPage from '../pages/buy&sell/p2pAds';
import P2PSetting from '../pages/account/P2PSetting';
import AdManagementPage from '../pages/account/adManagenment';
import BlacklistPage from '../pages/account/blacklist';
import GoogleAuthenticationPage from '../pages/account/googleAuthentication';
import PhoneAuthenticationPage from '../pages/account/phoneAuthentication';
import P2PAccountPage from '../pages/wallets/p2pAccount';
import IdVerificationPage from '../pages/account/idVerification';
import AddPaymentMethodPage from '../pages/account/add-payment-method';
import AboutUsPage from '../pages/about-us';
import PrivacyPolicyPage from '../pages/privacy-policy';
import UserAgreementPage from '../pages/user-agreement';
import RishDisclaimer from '../pages/rish-disclaimer';
import BuySellDetailPage from '../pages/buySellDetail';
import P2POrdersPages from '../pages/p2p/orders';
import ForgotPasswordPage from '../pages/forgot-password';
import ResetPasswordPage from '../pages/reset-password';

export const router = createBrowserRouter([
	{
		path: '',
		element: <MainLayout />,
		children: [
			{
				path: ROUTES.HOME,
				element: <HomePage />,
			},
			{
				path: ROUTES.LOGIN,
				element: <LoginPage />,
			},
			{
				path: ROUTES.FORGOT_PASSWORD,
				element: <ForgotPasswordPage />,
			},
			{
				path: ROUTES.RESET_PASSWORD,
				element: <ResetPasswordPage />,
			},
			{
				path: ROUTES.SECURITY_SETTING,
				element: <VerificationPage />,
			},
			{
				path: ROUTES.REGISTER,
				element: <RegisterPage />,
			},
			{
				path: ROUTES.ACCOUNT.MAIN,
				element: <AccountLayout />,
				children: [
					{ path: ROUTES.ACCOUNT.ACCOUNT_LIST, element: <AccountList /> },
					{ path: ROUTES.ACCOUNT.SAFETY_RECORD },
					{
						path: ROUTES.ACCOUNT.PERSONAL_VERIFICATION,
						element: <PersonalVerificationPage />,
					},
					{ path: ROUTES.ACCOUNT.P2P_SETTINGS, element: <P2PSetting /> },
					{
						path: ROUTES.ACCOUNT.ADD_PAYMENT_METHOD,
						element: <AddPaymentMethodPage />,
					},
					{ path: ROUTES.ACCOUNT.AD_MANAGEMENT, element: <AdManagementPage /> },
					{ path: ROUTES.ACCOUNT.BLACKLIST, element: <BlacklistPage /> },
					{ path: ROUTES.ACCOUNT.API_MANAGEMENT },
					{
						path: ROUTES.ACCOUNT.GOOGLE_AUTHENTICATION,
						element: <GoogleAuthenticationPage />,
					},
					{
						path: ROUTES.ACCOUNT.PHONE_AUTHENTICATION,
						element: <PhoneAuthenticationPage />,
					},
					{
						path: ROUTES.ACCOUNT.ID_VERIFICATION,
						element: <IdVerificationPage />,
					},
				],
			},
			{
				path: ROUTES.WALLET.MAIN,
				element: <WalletLayout />,
				children: [
					{ path: ROUTES.WALLET.SPOT, element: <SpotWalletPage /> },
					{ path: ROUTES.WALLET.ASSET_OVERVIEW, element: <AssetOverview /> },
					{ path: ROUTES.WALLET.P2P, element: <P2PAccountPage /> },
					{ path: ROUTES.WALLET.FUTURES_ACCOUNT },
					{ path: ROUTES.WALLET.MARGIN_ACCOUNT },
					{ path: ROUTES.WALLET.ASSET_AUTDIT },
				],
			},
			{
				element: <BuySellLayout />,
				children: [
					{
						path: ROUTES.P2P_BUY_SELL,
						element: <P2PBuySellPage />,
					},
					{
						path: ROUTES.P2P_ADS,
						element: <P2PAdsPage />,
					},
					{
						path: ROUTES.BUY_SELL_DETAIL,
						element: <BuySellDetailPage />,
					},
					{
						path: ROUTES.P2P_ORDERS,
						element: <P2POrdersPages />,
					},
				],
			},
			{
				path: ROUTES.ABOUT_US,
				element: <AboutUsPage />,
			},
			{
				path: ROUTES.PRIVACY_POLICY,
				element: <PrivacyPolicyPage />,
			},
			{
				path: ROUTES.USER_AGREEMENT,
				element: <UserAgreementPage />,
			},
			{
				path: ROUTES.RISH_DISCLAIMER,
				element: <RishDisclaimer />,
			},
		],
	},
]);
