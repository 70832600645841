import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import TextGradient from '../../UI/TextGradient';

interface FundTableMobileProps {
	list: any;
}
const TokenWrapper = styled(Box)`
	display: flex;
	align-items: center;
	gap: 8px;
`;
const RowWrapper = styled(Box)`
	border-bottom: 1px solid #737283;
	padding: 16px 0;
`;
const Wrapper = styled(Box)``;
const TokenImage = styled.img`
	width: 20px;
	height: 20px;
	object-fit: contain;
	border-radius: 100%;
`;
const TokenSymbol = styled(Box)`
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	color: #ffffff;
`;
const TokenName = styled(Box)`
	font-size: 12px;
	line-height: 16px;
	color: #737283;
`;

const RowDetail = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-top: 12px;
`;
const DetailBlock = styled(Box)`
	width: 40%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const Label = styled(Box)`
	font-size: 12px;
	line-height: 16px;
	color: #737283;
	text-align: left;
`;
const Value = styled(Box)`
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	color: #ffffff;
	text-align: right;
`;

const Actions = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 8px;
	margin-top: 10px;
`;
const StyledTextGradient = styled(TextGradient)`
	cursor: pointer;
	&:hover {
		filter: brightness(1.2);
	}
`;
const FundTableMobile: React.FC<FundTableMobileProps> = ({
	list,
	...props
}: FundTableMobileProps) => {
	return (
		<Wrapper {...props}>
			{list.map((item: any, index: number) => (
				<RowWrapper key={index}>
					<TokenWrapper>
						<TokenImage src={item.currency.icon} />
						<TokenSymbol>{item.currency.symbol}</TokenSymbol>
						<TokenName>{item.currency.name}</TokenName>
					</TokenWrapper>
					<RowDetail>
						<DetailBlock>
							<Label>Total:</Label>
							<Value>{item.total}</Value>
						</DetailBlock>
						<DetailBlock>
							<Label>Available:</Label>
							<Value>{item.available}</Value>
						</DetailBlock>
						<DetailBlock>
							<Label>In Order:</Label>
							<Value>{item.inOrder}</Value>
						</DetailBlock>
						<DetailBlock>
							<Label>Valuation(USD):</Label>
							<Value>{item.valuation}</Value>
						</DetailBlock>
					</RowDetail>
					<Actions>
						<StyledTextGradient>Deposit</StyledTextGradient>
						<StyledTextGradient>Withdraw</StyledTextGradient>
						<StyledTextGradient>Trade</StyledTextGradient>
					</Actions>
				</RowWrapper>
			))}
		</Wrapper>
	);
};

export default FundTableMobile;
