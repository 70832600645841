import { Box, Flex, Show, Text, chakra } from '@chakra-ui/react';
import React, { useEffect, useMemo } from 'react';
import { ColumnsAdsTable, DataAdsTable } from './interface';
import BuySellTable from '../UI/BuySellTable';
import { CURRENCY } from '../../constants/cryptos';
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import { P2PTradingClient } from 'skychain-p2p-trading-js';
import { AdOrderEntity } from 'skychain-p2p-trading-js/lib/entities/ad-order.entity';
import { convertOrderType } from '../../utils/ads';
export const PrimaryText = chakra(Text, {
	baseStyle: {
		fontSize: {
			base: '14px',
			lg: '16px',
		},
		color: '#ffffff',
		lineHeight: '16px',
	},
});

export const SecondaryText = chakra(Text, {
	baseStyle: {
		fontSize: {
			base: '12px',
			lg: '14px',
		},
		color: '#737283',
		lineHeight: '14px',
	},
});

export default function AdsData() {
	const columns: ColumnsAdsTable[] = [
		{
			key: 'cryptos',
			title: 'Cryptos',
			isNumeric: false,
			align: 'start',
			render: (data: any) => (
				<Flex gap="10px" alignItems={'center'}>
					<img src={CURRENCY[data['currency']]?.icon} />
					<Flex direction={'column'} gap={'5px'}>
						<PrimaryText>{CURRENCY[data['currency']]?.symbol}</PrimaryText>
						<SecondaryText>{CURRENCY[data['currency']]?.name}</SecondaryText>
					</Flex>
				</Flex>
			),
		},
		{
			key: 'amount',
			title: 'Amount',
			isNumeric: false,
			align: 'start',
		},
		{
			key: 'limit',
			title: 'Limit',
			isNumeric: false,
			align: 'start',
		},
		{
			key: 'types',
			title: 'Types',
			isNumeric: false,
			align: 'start',
			render: (data: any) => (
				<Text
					color={data['type']?.toLowerCase() === 'buy' ? '#03A66D' : '#CF304A'}
					fontSize={{ base: '14px' }}
					textTransform={'capitalize'}
				>
					{data['type']}
				</Text>
			),
		},
		{
			key: 'status',
			title: 'Status',
			isNumeric: true,
			align: 'center',
		},
	];

	const [ads, setAds] = React.useState<AdOrderEntity[]>([]);
	const p2pTradingClient = useSelector(
		(state: RootState) => state.system.p2pTradingClient
	);
	const dataTable: DataAdsTable[] = useMemo(() => {
		const data = ads.map((item: AdOrderEntity) => ({
			key: item.id,
			currency: item.assetSymbol || '',
			amount: `${item.totalAmount} ${item.assetSymbol}`,
			limit: `${item.minAmount} - ${item.maxAmount}`,
			type: convertOrderType(item.type) as any,
			status: item.status
				? item.status.charAt(0).toUpperCase() + item.status?.slice(1)
				: '',
			bank: 'bank',
		}));
		return data;
	}, [ads]);

	useEffect(() => {
		if (p2pTradingClient) {
			getAds(p2pTradingClient);
		}
	}, [p2pTradingClient]);

	const getAds = async (p2pTradingClient: P2PTradingClient) => {
		try {
			const res = await p2pTradingClient.getAdOrders();
			setAds(res.data);
		} catch (error) {
			console.log('getAds', error);
		}
	};
	return (
		<Box py={{ base: '10px', lg: '40px' }}>
			<Show above="sm">
				<BuySellTable
					dataTable={dataTable}
					columns={columns}
					isBorder
					isCollapse
				/>
			</Show>

			<Show below="sm">
				{dataTable.map((data) => (
					<AdsBlock key={data.key} data={data} />
				))}
			</Show>
		</Box>
	);
}

export const AdsBlock = ({ data }: { data: DataAdsTable }) => {
	return (
		<Flex
			direction={'column'}
			gap={'20px'}
			py="20px"
			borderBottom={'1px solid #FFFFFF1E'}
			w={'100%'}
		>
			<Flex justifyContent={'space-between'} alignItems={'center'}>
				<Flex gap="10px" alignItems={'center'}>
					<img src={CURRENCY[data['currency']]?.icon} />
					<Flex direction={'column'} gap={'5px'}>
						<PrimaryText>{CURRENCY[data['currency']]?.symbol}</PrimaryText>
						<SecondaryText>{CURRENCY[data['currency']]?.name}</SecondaryText>
					</Flex>
				</Flex>
			</Flex>

			<Flex justifyContent={'space-between'} alignItems={'center'} w={'100%'}>
				<Flex
					gap="10px"
					alignItems={'center'}
					justifyContent={'space-between'}
					w={'35%'}
				>
					<SecondaryText>Types:</SecondaryText>
					<Text
						color={data['type'] === 'buy' ? '#03A66D' : '#CF304A'}
						fontSize={{ base: '14px' }}
						textTransform={'capitalize'}
					>
						{data['type']}
					</Text>
				</Flex>
				<Flex
					gap="10px"
					alignItems={'center'}
					justifyContent={'space-between'}
					w={'50%'}
				>
					<SecondaryText>Amount:</SecondaryText>
					<PrimaryText>{data['amount']}</PrimaryText>
				</Flex>
			</Flex>

			<Flex justifyContent={'space-between'} alignItems={'center'} w={'100%'}>
				<Flex gap="5px" alignItems={'center'} w={'35%'}>
					<Box w={'6px'} h={'6px'} borderRadius={'100px'} bg={'#737283'} />
					<SecondaryText>{data['bank']}</SecondaryText>
				</Flex>
				<Flex
					gap="10px"
					alignItems={'center'}
					justifyContent={'space-between'}
					w={'50%'}
				>
					<SecondaryText>Limit:</SecondaryText>
					<PrimaryText>{data['limit']}</PrimaryText>
				</Flex>
			</Flex>
		</Flex>
	);
};
