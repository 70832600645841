import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Authentication from '.';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { ROUTES } from '../../routes/config';
import { useToast } from '@chakra-ui/react';
const formFields = [
	{
		key: 1,
		label: 'New password',
		name: 'password',
		type: 'password',
		placeholder: 'Enter your password...',
		required: 'This field is required',
	},
	{
		key: 1,
		label: 'Confirm password',
		name: 'confimPassword',
		type: 'password',
		placeholder: 'Confirm your new password...',
		required: 'This field is required',
		validate: (value: any) => {
			if (value !== 'password') {
				return 'Password does not match';
			}
			return true;
		},
	},
];

const ResetPassword = () => {
	const { register, handleSubmit, reset } = useForm();
	const navigate = useNavigate();
	const toast = useToast();
	const [searchParams] = useSearchParams();
	const code = searchParams.get('code');
	const userIdentity = useSelector(
		(state: RootState) => state.system.userIdentity
	);
	const onSubmit = async (value: any) => {
		if (!userIdentity) return;
		if (!code) {
			return toast({
				title: 'Error',
				description: 'Invalid code',
				status: 'error',
			});
		}
		try {
			const res = await userIdentity?.resetPassword({
				token: code,
				newPassword: value.password,
			});
			if (res) {
				toast({
					title: 'Success',
					description: 'Reset password successfully',
					status: 'success',
				});
				reset();
				navigate(ROUTES.LOGIN);
			}
		} catch (error: any) {
			toast({
				title: 'Error',
				description: error?.response?.data?.message || 'Unknown error',
				status: 'error',
			});
		}
	};
	return (
		<Authentication
			title={'Reset password'}
			titleButton={'Confirm'}
			subtitle={
				<div>Enter a new password to reset the password on your account</div>
			}
			formFields={formFields}
			register={register}
			onSubmit={handleSubmit(onSubmit)}
		/>
	);
};

export default ResetPassword;
