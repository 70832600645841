import styled from '@emotion/styled';

export const NotFoundWrapper = styled.div`
	margin: auto;
	width: fit-content;
`;
export const NotFoundTitle = styled.div`
	font-weight: 500;
	font-size: 16px;
	line-height: 28px;
	text-align: center;
	color: #737283;
	margin-top: 20px;
`;
