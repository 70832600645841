import React from 'react';
import Authentication from '.';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useToast } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const formFields = [
	{
		key: 1,
		label: 'Email',
		name: 'email',
		type: 'text',
		placeholder: 'Enter your email...',
		required: true,
	},
];

const ForgotPassword = () => {
	const { register, handleSubmit, reset } = useForm();
	const toast = useToast();
	const userIdentity = useSelector(
		(state: RootState) => state.system.userIdentity
	);
	const onSubmit = async (value: any) => {
		if (!userIdentity) return;
		try {
			const res = await userIdentity.forgotPassword(value.email);
			if (res) {
				toast({
					title: 'Success',
					description: 'Please check your email to reset password',
					status: 'success',
				});
				reset();
			}
		} catch (error: any) {
			toast({
				title: 'Error',
				description: error?.response?.data?.message || 'Unknown error',
				status: 'error',
			});
		}
	};
	return (
		<Authentication
			title={'Forgot password'}
			titleButton={'Submit'}
			subtitle={
				<>
					Please enter the email you use to sign in to{' '}
					<Link style={{ color: '#FFBF1A', marginLeft: '4px' }} to="/">
						Metaniex
					</Link>
				</>
			}
			formFields={formFields}
			register={register}
			onSubmit={handleSubmit(onSubmit)}
		/>
	);
};

export default ForgotPassword;
