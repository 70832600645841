import React from 'react';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogCloseButton,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Box,
	Button,
	chakra,
	Flex,
	Image,
	Text,
} from '@chakra-ui/react';
import { useAppDispatch } from '../../redux/store';
import ArrowRightIcon from '../Icons/ArrowRightIcon';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes/config';
import { changeStatusSecurityDialog } from '../../redux/reducers/system.slice';

const LineBox = chakra(Box, {
	baseStyle: {
		h: '1px',
		bg: 'linear-gradient(100.78deg, rgba(255, 255, 255, 0.116) 0.27%, rgba(255, 255, 255, 0.024) 102.68%)',
	},
});

export default function SecurityVerificationDialog({
	isOpen,
}: {
	isOpen: boolean;
}) {
	const dispatch = useAppDispatch();
	const cancelRef = React.useRef(null);
	const navigate = useNavigate();

	const onClose = () => {
		dispatch(changeStatusSecurityDialog(false));
	};

	return (
		<>
			<AlertDialog
				motionPreset="slideInBottom"
				leastDestructiveRef={cancelRef}
				onClose={onClose}
				isOpen={isOpen}
				isCentered
			>
				<AlertDialogOverlay />

				<AlertDialogContent minW={{ base: 'auto', md: '518px' }}>
					<AlertDialogHeader
						bg={'#091229'}
						textAlign={'center'}
						fontSize={'18px'}
					>
						Security Verification
					</AlertDialogHeader>
					<AlertDialogCloseButton />
					<AlertDialogBody bg={'#070C1E'}>
						<Box>
							<Box py={'16px'} textAlign={'center'} color={'#737283'}>
								Enable 2FA to increase your account security
							</Box>
							<LineBox />
						</Box>
						<Box>
							<Box
								onClick={() => {
									navigate(ROUTES.ACCOUNT.GOOGLE_AUTHENTICATION);
									onClose();
								}}
								border={'1px solid transparent'}
								_hover={{ border: '1px solid yellow' }}
								p={'16px'}
								cursor={'pointer'}
							>
								<Flex justifyContent={'space-between'} alignItems={'center'}>
									<Flex alignItems={'center'}>
										<Image
											w={'40px'}
											h={'40px'}
											mr={'15.8px'}
											src={'/images/icons/google-authenticator2.svg'}
										/>
										<Text fontSize={'14px'} lineHeight={'16px'} mr={'8.5px'}>
											Google Authenticator
										</Text>
										<Text
											fontSize={'12px'}
											borderRadius={'4px'}
											px={'4px'}
											py={'2px'}
											lineHeight={'16px'}
											color={'black'}
											bg={'linear-gradient(90deg, #FFBF1A 0%, #FCD535 100%)'}
										>
											Recommended
										</Text>
									</Flex>
									<ArrowRightIcon />
								</Flex>
							</Box>
							<LineBox />
						</Box>
						{/* <Box>
							<Box
								onClick={() => {
									navigate(ROUTES.ACCOUNT.PHONE_AUTHENTICATION);
									onClose();
								}}
								border={'1px solid transparent'}
								_hover={{ border: '1px solid yellow' }}
								p={'16px'}
								cursor={'pointer'}
							>
								<Flex justifyContent={'space-between'} alignItems={'center'}>
									<Flex alignItems={'center'}>
										<Image
											w={'40px'}
											h={'40px'}
											mr={'15.8px'}
											src={'/images/icons/mobileAuth-icon.svg'}
										/>
										<Text fontSize={'14px'} lineHeight={'16px'}>
											Phone Number Verification
										</Text>
									</Flex>
									<ArrowRightIcon />
								</Flex>
							</Box>
							<LineBox />
						</Box> */}
					</AlertDialogBody>
					<AlertDialogFooter bg={'#070C1E'}>
						<Button
							bg={'linear-gradient(90deg, #FFBF1A 0%, #FCD535 100%)'}
							w={'100%'}
							py={'15.5px'}
							color={'black'}
							borderRadius={'36px'}
						>
							Next
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</>
	);
}
