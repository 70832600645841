import {
	Box,
	Flex,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import DropdownIcon from '../../components/Icons/DropdownIcon';
import QuestionIcon from '../../components/Icons/QuestionIcon';
import Container from '../Container';
import { ROUTES } from '../../routes/config';
import { useLocation, useNavigate } from 'react-router-dom';

const types = [
	// { label: 'Express', link: ROUTES.P2P_BUY_SELL },
	{ label: 'P2P', link: ROUTES.P2P_BUY_SELL },
	{ label: 'Ads', link: ROUTES.P2P_ADS },
	{ label: 'Orders', link: ROUTES.P2P_ORDERS },
	// { label: 'Block', link: ROUTES.P2P_BUY_SELL },
];

const TabActive = styled(Box)`
	background: linear-gradient(90deg, #ffbf1a 0%, #fcd535 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;

	font-weight: 500;
`;

export default function Type() {
	const navigate = useNavigate();
	const location = useLocation();

	return (
		<Box
			background={'#091229'}
			height={{ base: '56px' }}
			padding={{ base: '12px 0px 0px', lg: '0px' }}
		>
			<Container>
				<Flex
					height={'100%'}
					alignItems={{ base: 'center' }}
					justifyContent={'space-between'}
				>
					<Flex gap={{ lg: '50px', base: '30px' }}>
						{types.map((type) => {
							if (type.link === location.pathname) {
								return (
									<TabActive
										key={type.label}
										fontSize={{ base: '14px' }}
										cursor={'pointer'}
									>
										{type.label}
									</TabActive>
								);
							}
							return (
								<Box
									key={type.label}
									fontSize={{ base: '14px' }}
									cursor={'pointer'}
									onClick={() => {
										navigate(type.link);
									}}
								>
									{type.label}
								</Box>
							);
						})}
					</Flex>

					<Menu>
						<MenuButton>
							<Flex alignItems={'center'} gap={{ base: '8px ' }}>
								<QuestionIcon />
								<Text color={'#737283'} fontSize={{ base: '14px' }}>
									User guide
								</Text>
								<DropdownIcon />
							</Flex>
						</MenuButton>
						<MenuList>
							<MenuItem>Download</MenuItem>
							<MenuItem onClick={() => alert('Kagebunshin')}>
								Create a Copy
							</MenuItem>
						</MenuList>
					</Menu>
				</Flex>
			</Container>
		</Box>
	);
}
