import {
	Box,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerOverlay,
	Flex,
	useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import MenuIcon from '../../components/Icons/MenuIcon';
import { ROUTES } from '../../routes/config';
import { LinkActive, LinkItem } from './styles';
import { useNavigate } from 'react-router-dom';
import GameIcon from '../../components/Icons/GameIcon';
const links = [{ title: 'Home', link: ROUTES.HOME }];
export default function MobileMenu() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const btnRef = React.useRef<any>();
	const navigate = useNavigate();

	return (
		<>
			<Box
				px="0px"
				width={'max-content'}
				cursor={'pointer'}
				display={'flex'}
				alignItems={'center'}
				gap={'10px'}
			>
				{/* <a
					href="https://games.metaniex.com/"
					target="_blank"
					style={{ width: 'fit-content' }}
				>
					<Box
						border={'1px solid #F7931E'}
						color={'#F7931E'}
						padding={'6px 12px'}
						display={'flex'}
						alignItems={'center'}
						height={'28px'}
						gap={'6px'}
						borderRadius={'27px'}
					>
						<GameIcon />
						<Box>Metagames</Box>
					</Box>
				</a> */}
				<Box ref={btnRef} onClick={onOpen}>
					<MenuIcon />
				</Box>
			</Box>
			<Drawer
				isOpen={isOpen}
				placement="right"
				onClose={onClose}
				finalFocusRef={btnRef}
			>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					{/* <DrawerHeader>Create your account</DrawerHeader> */}
					<DrawerBody mt={30}>
						<Flex alignItems={'center'} gap={'20px'} flexDirection={'column'}>
							{links.map((link) => {
								if (link.link === location.pathname)
									return (
										<LinkActive onClick={onClose} key={link.title}>
											{link.title}
										</LinkActive>
									);
								return (
									<LinkItem
										key={link.title}
										onClick={() => {
											navigate(link.link);
											onClose();
										}}
									>
										{link.title}
									</LinkItem>
								);
							})}
						</Flex>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	);
}
