import {
	Box,
	Flex,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverTrigger,
	Show,
	useColorMode,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import MoonDarkIcon from '../../components/Icons/MoonDarkIcon';
import PrimaryButton from '../../components/UI/PrimaryButton';
import TransparentButton from '../../components/UI/TransparentButton';
import MobileMenu from './MobileMenu';
import {
	LinkActive,
	LinkItem,
	LogoutBtn,
	NavCenterWrapper,
	NavWrapper,
	Profile,
} from './styles';
import { ROUTES } from '../../routes/config';
import { useLocation, useNavigate } from 'react-router-dom';
import { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AccountIcon from '../../components/Icons/AccountIcon';
import { logout } from '../../redux/reducers/auth.slice';

const links = [
	{ title: 'Home', link: ROUTES.HOME },
	// { title: 'Buy Sell Crypto', link: ROUTES.P2P_BUY_SELL },
	// {
	// 	title: 'Metagames',
	// 	link: 'https://games.metaniex.com/',
	// 	externalLink: true,
	// },
	// { title: 'Telegram Support', link: '' },
	// { title: 'FAQs', link: '' },
];
function TopNav(props: any, ref: React.LegacyRef<HTMLDivElement>) {
	const { toggleColorMode } = useColorMode();
	const userAccount = useSelector((state: any) => state.auth.account);
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const handleLogout = () => {
		navigate(ROUTES.LOGIN);
		dispatch(logout());
	};

	return (
		<Box
			position={{ base: 'fixed' }}
			zIndex={100}
			background={'#050713'}
			width={'100%'}
			top={{ base: 0 }}
			ref={ref}
		>
			<NavWrapper padding={{ base: '16px 18px', lg: '16px 20px' }}>
				<Flex gap={10} alignItems={'center'} justifyContent={'space-between'}>
					<Link to={ROUTES.HOME}>
						<Box w={{ base: 'fit-content', lg: '210px' }}>
							<img src="/images/logo.svg" alt="logo" />
						</Box>
					</Link>
					<Show above="lg">
						<NavCenterWrapper
							alignItems={'center'}
							justifyContent={'space-between'}
						>
							<Flex alignItems={'center'} gap={'40px'}>
								{links.map((link) => {
									if (link.link === location.pathname)
										return (
											<LinkActive key={link.title}>{link.title}</LinkActive>
										);
									return (
										<>
											{/* {link.externalLink ? (
												<a href={link.link} target="_blank" rel="noreferer">
													{link.title}
												</a>
											) : (
												<LinkItem
													key={link.title}
													onClick={() => {
														navigate(link.link);
														// setLinkActive(link.title);
													}}
												>
													{link.title}
												</LinkItem>
											)} */}
										</>
									);
								})}
							</Flex>
							{/* 
							<Flex gap={5}>
								{!userAccount ? (
									<>
										<Link to={'/login'}>
											<TransparentButton>Log In</TransparentButton>
										</Link>
										<Link to={'/register'}>
											<PrimaryButton>Sign up</PrimaryButton>
										</Link>
									</>
								) : (
									<>
										<LinkItem
											active={location.pathname.includes(ROUTES.WALLET.MAIN)}
											onClick={() => {
												navigate(ROUTES.WALLET.ASSET_OVERVIEW);
											}}
										>
											Balances
										</LinkItem>
										<LinkItem
											onClick={() => {
												navigate('');
											}}
										>
											Order
										</LinkItem>
										<Popover trigger="hover">
											<PopoverTrigger>
												<Profile>
													<AccountIcon />
												</Profile>
											</PopoverTrigger>
											<PopoverContent>
												<PopoverArrow />
												<PopoverCloseButton />
												<PopoverBody>
													<LogoutBtn
														onClick={() =>
															navigate(ROUTES.ACCOUNT.ACCOUNT_LIST)
														}
													>
														Profile
													</LogoutBtn>
													<LogoutBtn onClick={handleLogout}>Logout</LogoutBtn>
												</PopoverBody>
											</PopoverContent>
										</Popover>
									</>
								)}
							</Flex> */}
						</NavCenterWrapper>

						<Flex alignItems={'center'} gap={5}>
							{/* <p>Download</p> */}
							<Box
							// onClick={() => toggleColorMode()}
							>
								<MoonDarkIcon />
							</Box>
							<Flex alignItems={'center'} gap={2}>
								<p>English</p>|<p>USD</p>
							</Flex>
						</Flex>
					</Show>

					<Show below="lg">
						<MobileMenu />
					</Show>
				</Flex>
			</NavWrapper>
		</Box>
	);
}

export default forwardRef(TopNav);
