import { Box, Flex, Show, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import Container from '../../layouts/Container';
import SelectCustom from '../UI/SelectCustom';
import AmountInput from '../UI/AmountInput';
import {
	AdOrderFiatSymbol,
	QueryAdOrder,
} from 'skychain-p2p-trading-js/lib/dto';
import { debounce, omit } from 'lodash';
import Refresh from './Refresh';

const TextExpend = styled(Text)`
	word-spacing: 10px;
`;

const Label = styled(Text)`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #737283;
	margin-bottom: 10px;
`;

// const fiats = [
// 	{ key: 'USDT', value: 'USDT', icon: 'money-icon' },
// 	{ key: 'ETH', value: 'ETH', icon: 'money-icon' },
// ];
const fiats = Object.values(AdOrderFiatSymbol).map((fiat) => ({
	key: fiat,
	value: fiat,
}));

// const payments = [
// 	{ key: 'all', value: 'All payment' },
// 	{ key: 'cash', value: 'Cash in Person' },
// 	{ key: 'bank', value: 'Bank Tranfer' },
// ];
// const regions = [{ key: 'all', value: 'All Regions', icon: 'global-icon' }];

// const fiatCurrent = fiats[0];
// const paymentCurrent = payments[0];
// const regionCurrent = regions[0];

export default function FilterAndRefresh({
	handleUpdateFilter,
	filterParams,
}: {
	handleUpdateFilter: (params: QueryAdOrder) => void;
	filterParams: QueryAdOrder;
}) {
	const selectFiat = (option: Option) => {
		if (option.key === fiats[0].key) {
			handleUpdateFilter(omit({ ...filterParams }, ['fiatSymbol']));
		} else if (
			filterParams.fiatSymbol !== option.key &&
			typeof option.key === 'string'
		) {
			handleUpdateFilter({ ...filterParams, fiatSymbol: option.key });
		}
	};

	const changeAmount = (event: any) => {
		const value: string = event.target?.value;
		if (value.length === 0) {
			handleUpdateFilter(omit({ ...filterParams }, ['amount']));
		} else if (typeof Number(value) === 'number') {
			handleUpdateFilter({ ...filterParams, amount: Number(value) });
		}
	};

	return (
		<Container>
			<Flex
				justifyContent={'space-between'}
				alignItems={'flex-end'}
				py={{ base: '25px', lg: '0px' }}
				borderBottom={{ base: '1px solid #FFFFFF1E', lg: 'none' }}
			>
				<Flex gap={'20px'}>
					<Box>
						<Label>Amount</Label>
						<AmountInput
							placeHolder={'Enter amount...'}
							expand={
								<TextExpend color={'#737283'} fontSize={{ base: '14px' }}>
									{filterParams.fiatSymbol} | Search
								</TextExpend>
							}
							expandW={'8rem'}
							onChange={debounce(changeAmount, 1000)}
							type="number"
						/>
					</Box>

					<Box>
						<Label>Fiat</Label>
						<SelectCustom
							value={
								filterParams.fiatSymbol
									? fiats.find(
											(fiat) => fiat.key === filterParams.fiatSymbol
									  ) || undefined
									: fiats[0]
							}
							list={fiats}
							onSelect={selectFiat}
						/>
					</Box>
					{/* 
					<Box>
						<Label>Payment</Label>
						<SelectCustom
							value={paymentCurrent}
							list={payments}
							onSelect={onSelect}
						/>
					</Box>

					<Box>
						<Label>Available Region(s)</Label>
						<SelectCustom
							value={regionCurrent}
							list={regions}
							onSelect={onSelect}
						/>
					</Box> */}
				</Flex>

				<Show above="sm">
					<Refresh />
				</Show>
			</Flex>
		</Container>
	);
}
