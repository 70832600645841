import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import Checkbox from '../../UI/Checkbox';
import SearchIcon from '../../Icons/SearchIcon';
import FundTableDesktop from './FundTable';
import FundTableMobile from './FundTableMobile';

const Wrapper = styled(Box)`
	margin-top: 18px;
`;
const Header = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const Title = styled(Box)`
	font-weight: 500;
	font-size: 20px;
	line-height: 48px;
	color: #ffffff;
	@media screen and (max-width: 567px) {
		font-size: 16px;
	}
`;
const HideSmallBalancesWrapper = styled(Box)``;
const LeftColumn = styled(Box)`
	display: flex;
	align-items: center;
	gap: 20px;
	@media screen and (max-width: 567px) {
		flex: 1;
		justify-content: space-between;
		margin-right: 10px;
	}
`;

const SearchWrapper = styled(Box)`
	position: relative;
`;
const Search = styled.input`
	font-size: 16px;
	line-height: 24px;
	border: 1px solid #3d3c52;
	border-radius: 5px;
	padding: 5px 10px;
	background: transparent;
	color: #fff;
	width: 277px;
	&:focus {
		outline: none;
	}
	&::placeholder {
		color: #737283;
	}
	@media screen and (max-width: 567px) {
		border: none;
		margin-left: 10px;
		width: 70px;
	}
`;
const Retangle = styled(Box)`
	background: linear-gradient(
		100.78deg,
		rgba(255, 255, 255, 0.116) 0.27%,
		rgba(255, 255, 255, 0.024) 102.68%
	);
	height: 1px;
	width: 100%;
`;

const StyledSearchIcon = styled(SearchIcon)`
	display: none;
	@media screen and (max-width: 567px) {
		display: block;
		position: absolute;
		left: 0;
		bottom: 0;
		top: 10px;
	}
`;
const StyledFundTableDesktop = styled(FundTableDesktop)`
	display: table;
	@media screen and (max-width: 567px) {
		display: none;
	}
`;
const StyledFundTableMobile = styled(FundTableMobile)`
	display: none;
	@media screen and (max-width: 567px) {
		display: block;
	}
`;
const FundListDataTable = [
	{
		currency: {
			symbol: 'USDT',
			name: 'Tether USD',
			icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/825.png',
		},
		total: 0.0,
		available: 0.0,
		inOrder: 0.0,
		valuation: 0.0,
	},
	{
		currency: {
			symbol: 'BTC',
			name: 'Bitcoin',
			icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1.png',
		},
		total: 0.0,
		available: 0.0,
		inOrder: 0.0,
		valuation: 0.0,
	},
	{
		currency: {
			symbol: 'ETH',
			name: 'Ethereum',
			icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
		},
		total: 0.0,
		available: 0.0,
		inOrder: 0.0,
		valuation: 0.0,
	},
	{
		currency: {
			symbol: 'BNB',
			name: 'Binance Token',
			icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png',
		},
		total: 0.0,
		available: 0.0,
		inOrder: 0.0,
		valuation: 0.0,
	},
];
const FundList = () => {
	return (
		<Wrapper>
			<Header>
				<LeftColumn>
					<Title>Fund list</Title>
					<HideSmallBalancesWrapper>
						<Checkbox
							defaultChecked={false}
							onChange={(event) => console.log(event.target.checked)}
						/>{' '}
						Hide Small Balances
					</HideSmallBalancesWrapper>
				</LeftColumn>
				<SearchWrapper>
					<Search placeholder="Search" />
					<StyledSearchIcon />
				</SearchWrapper>
			</Header>
			<Retangle />
			<StyledFundTableDesktop list={FundListDataTable} />
			<StyledFundTableMobile list={FundListDataTable} />
		</Wrapper>
	);
};

export default FundList;
