import React from 'react';

const DepositIcon = () => {
	return (
		<svg
			width={32}
			height={32}
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M23.8 5H8.2C5.34 5 3 7.2 3 9.88889C3 11.9667 4.3 13.6778 6.25 14.4111V9.88889C6.25 9.4 6.51 8.91111 6.77 8.54444C7.03 8.17778 7.68 8.05556 8.2 8.05556H23.8C24.32 8.05556 24.84 8.3 25.23 8.54444C25.62 8.78889 25.75 9.4 25.75 9.88889V14.4111C27.7 13.6778 29 11.9667 29 9.88889C29 7.2 26.66 5 23.8 5ZM23.8 24.5556V9.88889H8.2V24.5556C8.2 25.9 9.37 27 10.8 27H21.2C22.63 27 23.8 25.9 23.8 24.5556ZM14.96 16.4889L14.05 17.3444C13.66 17.7111 13.01 17.7111 12.62 17.3444C12.23 16.9778 12.23 16.3667 12.62 16L14.31 14.4111C15.22 13.5556 16.65 13.5556 17.56 14.4111L19.25 16C19.64 16.3667 19.64 16.9778 19.25 17.3444C18.86 17.7111 18.21 17.7111 17.82 17.3444L16.91 16.4889V21.6222C16.91 22.1111 16.52 22.6 15.87 22.6C15.22 22.6 14.83 22.2333 14.83 21.6222V16.4889H14.96Z"
				fill="url(#paint0_linear_243_5670)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_243_5670"
					x1={3}
					y1={27}
					x2={29}
					y2={27}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FFBF1A" />
					<stop offset={1} stopColor="#FCD535" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default DepositIcon;
