import React, { useState } from 'react';
import TabsUI from '../../UI/TabsUI';

const TabsAdManagement = () => {
	const tabs = ['Buy AD', 'Sell AD'];
	const [tabSelected, setTabSelected] = useState<string>(tabs[0]);
	const handleSelectTab = (tab: string) => {
		setTabSelected(tab);
	};
	return (
		<TabsUI
			selectedTab={tabSelected}
			tabs={tabs}
			onSelectedTab={handleSelectTab}
		/>
	);
};

export default TabsAdManagement;
