import { Box, Flex, Text } from '@chakra-ui/react';
import { fromUnixTime } from 'date-fns';
import format from 'date-fns/format';
import { Transaction } from 'metaverse-js/lib/proto/model/wallet';
import { memo, useEffect, useState } from 'react';
import { STATE_TRANSACTION } from '../../../lib/constants';
import { shortenAddress } from '../../../lib/shortenAddress';
import TableUI from '../../UI/TableUI';

const columns = [
	{
		key: 'currency',
		title: 'Currency',
	},
	{
		key: 'time',
		title: 'Time',
	},
	{
		key: 'amount',
		title: 'Amount',
	},
	{
		key: 'deposit_address',
		title: 'Deposit Address',
	},
	{
		key: 'blockchain_transaction_id',
		title: 'Blockchain Transaction ID',
	},
	{
		key: 'status',
		title: 'Status',
		render: (status: string) => (
			<Text color={`${STATE_TRANSACTION[status].color}`}>
				{STATE_TRANSACTION[status].title}
			</Text>
		),
	},
] as Columns[];

interface DataTable {
	[key: string]: any;
	currency: string;
	time: string;
	amount: string;
	deposit_address: string;
	blockchain_transaction_id: string;
	status: string;
}

const DepositHistory = ({ transactions }: { transactions: Transaction[] }) => {
	const [dataTable, setDataTable] = useState<DataTable[]>([]);
	useEffect(() => {
		const newData = transactions.map((item) => ({
			currency: item.assetSymbol || '',
			time: item.createdAt
				? format(fromUnixTime(item.createdAt), 'yyyy-MM-dd')
				: '',
			amount: item.assetAmount || '',
			deposit_address: shortenAddress(item.fromAccount) || '',
			blockchain_transaction_id: shortenAddress(item.id) || '',
			status: item.state || '',
		}));
		setDataTable(newData);
	}, [transactions]);
	return (
		<>
			<Box display={{ base: 'none', md: 'block' }}>
				<TableUI isBorder={true} columns={columns} dataTable={dataTable} />
			</Box>

			<Box display={{ base: 'block', md: 'none' }} w={'100%'} overflow={'auto'}>
				{dataTable.map((data, index) => {
					return (
						<Flex
							justifyContent={'space-between'}
							key={index}
							borderTop={'1px solid #FFFFFF1E'}
							p={'20px'}
						>
							<Box>
								{columns.slice(0, 4).map((col) => (
									<Flex key={col.key} alignItems={'center'} mb={'10px'}>
										<>
											<Box w={'76px'} color={'#737283'} fontSize={'12px'}>
												{col.title}:{' '}
											</Box>
											<Box fontSize={'14px'} w={'90px'}>
												{data[col.key]}
											</Box>
										</>
									</Flex>
								))}
							</Box>
							<Box>
								{columns.slice(4, columns.length).map((col) => (
									<Flex key={col.key} mb={'10px'}>
										<Box w={'90px'} color={'#737283'} fontSize={'12px'}>
											{col.title}:
										</Box>
										<Box fontSize={'14px'} w={'90px'}>
											{col.key === 'status' ? (
												<Text
													color={`${STATE_TRANSACTION[data[col.key]].color}`}
												>
													{STATE_TRANSACTION[data[col.key]].title}
												</Text>
											) : (
												data[col.key]
											)}
										</Box>
									</Flex>
								))}
							</Box>
						</Flex>
					);
				})}
			</Box>
		</>
	);
};

export default memo(DepositHistory);
