import { Flex, useDisclosure } from '@chakra-ui/react';
import { PaymentMethodEntity } from 'skychain-p2p-trading-js/lib/entities/payment-method.entity';
import ConfirmDeleteDialog from '../../Dialog/CofimDeleteDialog';
import { Action, Content, Header, Label, Value, Wrapper } from './styles';
import { useState } from 'react';

interface PaymentMethodProps extends React.HTMLAttributes<HTMLDivElement> {
	paymentMethod: PaymentMethodEntity;
	handleDelete: (id: string) => void;
}
const PaymentMethod: React.FC<PaymentMethodProps> = ({
	paymentMethod,
	handleDelete,
	...props
}: PaymentMethodProps) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [idPayment, setIdPayment] = useState<string>();
	const onOpenDeleteDialog = (id: string) => {
		onOpen();
		setIdPayment(id);
	};
	const handleSubmitDelete = () => {
		idPayment && handleDelete(idPayment);
		onClose();
	};
	return (
		<>
			<ConfirmDeleteDialog
				isOpen={isOpen}
				onSubmit={handleSubmitDelete}
				onClose={onClose}
				title={'Delete Payment?'}
				description={"Are you sure? You can't undo this action afterwards."}
			/>
			<Wrapper {...props}>
				<Header>
					<Label style={{ width: '20%' }}>Full name</Label>
					<Label style={{ flex: 1 }}>Bank account number</Label>
					<Label style={{ width: '20%' }}>Bank name</Label>
					<Flex
						style={{ width: '20%', justifyContent: 'flex-end' }}
						gap={'20px'}
					>
						<Action onClick={() => onOpenDeleteDialog(paymentMethod.id)}>
							Delete
						</Action>
					</Flex>
				</Header>
				<Content>
					<Value style={{ width: '20%' }}>{paymentMethod.fullName}</Value>
					<Value style={{ flex: 1 }}>{paymentMethod.bankAccountNumber}</Value>
					<Value style={{ width: '20%' }}>{paymentMethod.bankName}</Value>
					<Value style={{ width: '20%' }}></Value>
				</Content>
			</Wrapper>
		</>
	);
};

export default PaymentMethod;
