import { Box, Flex, Hide, Image, Text, chakra } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

const ProfileWrapper = chakra(Flex, {
	baseStyle: {
		background: {
			base: 'url(/images/account-list/profile-mobile-bg.png)',
			lg: 'url(/images/account-list/profile-bg.png)',
		},
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: '100% 100%',
		border: '1px solid #FFFFFF1E',
		borderRadius: '10px',
	},
});

const SecondaryText = chakra(Text, {
	baseStyle: {
		fontSize: 14,
		color: '#737283',
	},
});

const PrimaryText = chakra(Text, {
	baseStyle: {
		fontSize: 16,
		color: '#FFFFFF',
	},
});

const Avatar = chakra(Image, {
	baseStyle: {
		width: {
			base: '76px',
			lg: '110px',
		},
	},
});

export default function Profile() {
	const account = useSelector((state: RootState) => state.auth.account);

	return (
		<Flex alignItems={'center'} w={{ base: '100%' }} position={'relative'}>
			<ProfileWrapper
				w={{ base: '100%' }}
				gap={'10px'}
				alignItems={'center'}
				justifyContent={{ base: 'center', lg: 'flex-start' }}
				p={{ lg: '50px 100px', base: '16px 10px' }}
			>
				<Box borderRadius={'100px'} overflow={'hidden'}>
					<Avatar
						src="/images/account-list/personal-avatar.png"
						alt="personal avatar"
					/>
				</Box>
				<Flex direction={'column'} gap={'10px'}>
					<Flex alignItems={'center'} gap="10px">
						<SecondaryText>
							{account?.firstName || '' + ' ' + account?.lastName || ''}
						</SecondaryText>
						<Box cursor={'pointer'}>
							<Image src="/images/icons/edit-icon.svg" alt="edit icon" />
						</Box>
					</Flex>
					<PrimaryText>{account?.email}</PrimaryText>
					<SecondaryText>ID: {account?.id} </SecondaryText>
				</Flex>
			</ProfileWrapper>

			<Hide below="lg">
				<Box position={'absolute'} right={{ base: '5%' }}>
					<img alt="profile" src="/images/account-list/profile-img.png" />
				</Box>
			</Hide>
		</Flex>
	);
}
