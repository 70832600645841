import { Box, chakra, Flex, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import useMediaQuery from '../../hooks/userMediaQuery';
import StepArrow from '../Icons/StepArrow';
import { ExecuteOrderType } from 'skychain-p2p-trading-js/lib/dto/execute-order.dto';

const steps: Step[] = [
	{
		title: 'Transfer Payment to Seller',
		stepNumber: 1,
		description: '',
	},
	{
		title: 'Pending Seller to Release Cryptos',
		stepNumber: 2,
		description: '',
	},
	{
		title: 'Completed',
		stepNumber: 3,
		description: '',
	},
];

const stepsSell: Step[] = [
	{
		title: 'Pending Payment',
		stepNumber: 1,
		description: '',
	},
	{
		title: 'Release crypto to the buyer?',
		stepNumber: 2,
		description: '',
	},
	{
		title: 'Completed',
		stepNumber: 3,
		description: '',
	},
];
const StepNumber = chakra(Box, {
	baseStyle: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		w: '31px',
		h: '31px',
		fontSize: '14px',
		borderRadius: '100%',
		border: '1px solid',
		mr: '10px',
	},
});

const StepBuySells = ({
	stepNumberActive,
	type,
}: {
	stepNumberActive: number;
	type: ExecuteOrderType;
}) => {
	const { isExtraSmall } = useMediaQuery();
	const [stepsUpdate, setStepsUpdate] = useState(steps);

	useEffect(() => {
		if (isExtraSmall) {
			setStepsUpdate((state: Step[]) => {
				return state.filter((step) => step.stepNumber === stepNumberActive);
			});
		} else {
			if (type === ExecuteOrderType.BUY) {
				setStepsUpdate(steps);
			} else {
				setStepsUpdate(stepsSell);
			}
		}
	}, [isExtraSmall]);
	return (
		<Flex justifyContent={'space-between'}>
			{stepsUpdate.map((step, index) => {
				return (
					<Flex key={step.stepNumber}>
						<Flex alignItems={'center'}>
							<StepNumber
								color={
									stepNumberActive === step.stepNumber ? 'white' : '#737283'
								}
								borderColor={
									stepNumberActive === step.stepNumber ? 'white' : '#737283'
								}
							>
								{step.stepNumber}
							</StepNumber>
							<Text
								fontSize={'14px'}
								color={
									stepNumberActive === step.stepNumber ? 'white' : '#737283'
								}
								lineHeight={'28px'}
							>
								{step.title}
							</Text>
						</Flex>
						<Box mx={'11px'}>{index !== steps.length - 1 && <StepArrow />}</Box>
					</Flex>
				);
			})}
		</Flex>
	);
};

export default StepBuySells;
