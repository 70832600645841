import {
	Box,
	Flex,
	Grid,
	GridItem,
	Image,
	ListItem,
	OrderedList,
	Text,
} from '@chakra-ui/react';
import React, { useRef, useState, ChangeEvent } from 'react';
import { ConnectButton } from './Body';
import { useAppSelector } from '../../../redux/store';
import OutlineButton from '../../UI/OutlineButton';

export default function UploadIdPhoto({
	handleNextStep,
}: {
	handleNextStep: (newData: any, type?: 'next' | 'prev') => void;
}) {
	const [idFront, setIdFront] = useState<any>();
	const [idBack, setIdBack] = useState<any>();
	const [signaturePhoto, setSignaturePhoto] = useState<any>();
	const userIdentity = useAppSelector((state) => state.system.userIdentity);

	const handleFileSelect = async (
		file: File,
		type: 'font' | 'back' | 'signature'
	) => {
		try {
			const res = await userIdentity?.uploadKYCDocument(file, type);
			if (res?.bucketId) {
				if (type === 'font') setIdFront(res);
				if (type === 'back') setIdBack(res);
				if (type === 'signature') setSignaturePhoto(res);
			}
		} catch (error) {}
	};

	return (
		<Flex direction={'column'} gap="20px" alignItems={'center'}>
			<Grid
				templateColumns="repeat(2, 1fr)"
				gap={'20px'}
				w={'100%'}
				maxW={{ base: '460px' }}
			>
				<GridItem colSpan={1}>
					<UploadFile
						description="Upload the front cover of the ID Document"
						handleFileSelect={(file: File) => handleFileSelect(file, 'font')}
					/>
				</GridItem>
				<GridItem colSpan={1}>
					<ImageUpload
						src={idFront?.url || '/images/account-list/id-front-default.png'}
					/>
				</GridItem>
				<GridItem colSpan={1}>
					<UploadFile
						description="Upload the ID Document personal information page"
						handleFileSelect={(file: File) => handleFileSelect(file, 'back')}
					/>
				</GridItem>
				<GridItem colSpan={1}>
					<ImageUpload
						src={idBack?.url || '/images/account-list/id-back-default.png'}
					/>
				</GridItem>
				<GridItem colSpan={1}>
					<UploadFile
						description="Upload the hand-held documents & personal signature photos"
						handleFileSelect={(file: File) =>
							handleFileSelect(file, 'signature')
						}
					/>
				</GridItem>
				<GridItem colSpan={1}>
					<ImageUpload
						src={
							signaturePhoto?.url ||
							'/images/account-list/signature-photo-default.png'
						}
					/>
				</GridItem>
			</Grid>

			<Box maxW={'787px'}>
				<OrderedList
					color={'#737283'}
					fontSize={{ base: '14px' }}
					spacing={'10px'}
				>
					<ListItem>
						Please upload all photo in jpg/png/jpeg file format in size no more
						than 8MB.
					</ListItem>
					<ListItem>
						All the uploaded photos must be visibly clear and must not be
						altered or modified in any way
					</ListItem>
					<ListItem>
						Hats, sunglass or any other accessories that may cover your face is
						not allowed. Make-up is also highly not recommended.
					</ListItem>
					<ListItem>
						{`For the 3rd photo upload, you are required to hold the FRONT of your
						ID/passport/driving license together with a handwritten paper. The
						handwritten paper must include: 1) your full name, 2) submission
						date, 3) our web domain name and 4) a statement to declare your
						personal responsibility; "All behaviors on this website is performed
						by myself and I am aware of the relevant risks; and is willing to
						take all legal consequences.`}
					</ListItem>
				</OrderedList>
			</Box>
			<Flex gap={'20px'}>
				{' '}
				<OutlineButton
					style={{ borderRadius: 36, width: '300px', height: '46px' }}
					onClick={() => handleNextStep({}, 'prev')}
				>
					Back
				</OutlineButton>
				<ConnectButton
					isDisabled={!idFront || !idBack || !signaturePhoto}
					onClick={() => {
						handleNextStep({
							idDocumentBack: idFront?.objectName || '',
							idDocumentFront: idBack?.objectName || '',
							idDocumentSelfie: signaturePhoto?.objectName || '',
						});
					}}
				>
					Next
				</ConnectButton>
			</Flex>
		</Flex>
	);
}

const UploadFile = ({
	description,
	handleFileSelect,
}: {
	description: string;
	handleFileSelect: (file: File) => void;
}) => {
	const inputRef = useRef<HTMLInputElement | null>(null);
	const handleUpload = () => {
		inputRef.current?.click();
	};
	return (
		<Flex
			direction={'column'}
			alignItems={'center'}
			gap={{ base: '10px' }}
			bg="rgba(255, 255, 255, 0.05)"
			border={'1px solid rgba(255, 255, 255, 0.08)'}
			borderRadius={'4px'}
			py={{ base: '20px' }}
			px={{ base: '5px' }}
			cursor={'pointer'}
			onClick={handleUpload}
			h={'100%'}
		>
			<input
				type="file"
				ref={inputRef}
				accept="image/png, image/jpeg"
				onChange={(e: ChangeEvent<HTMLInputElement>) => {
					if (!e.target.files) {
						return;
					}
					handleFileSelect(e.target.files[0]);
				}}
				hidden
			/>
			<Image
				src="/images/icons/photo-icon.svg"
				alt="photo icon"
				w={{ base: '38px', lg: '45px' }}
			/>
			<Text
				fontSize={{ base: '12px', lg: '14px' }}
				color={'#737283'}
				textAlign={'center'}
				// whiteSpace={'pre'}
			>
				{description}
			</Text>
		</Flex>
	);
};

const ImageUpload = ({ src }: { src: string }) => {
	return (
		<Flex
			direction={'column'}
			alignItems={'center'}
			justifyContent={'center'}
			gap={{ base: '10px' }}
			bg="rgba(255, 255, 255, 0.05)"
			border={'1px solid rgba(255, 255, 255, 0.08)'}
			borderRadius={'4px'}
			py={{ base: '10px' }}
			px={{ base: '20px' }}
			cursor={'pointer'}
			h={'100%'}
		>
			<Image
				src={src}
				alt={'image upload'}
				w={'100%'}
				h={'100%'}
				objectFit={'contain'}
			/>
		</Flex>
	);
};
