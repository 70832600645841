import { Box, Flex, Show, Text } from '@chakra-ui/react';
import { CURRENCY } from '../../constants/cryptos';
import { PrimaryText, SecondaryText } from './styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { P2PTradingClient } from 'skychain-p2p-trading-js';
import { useEffect, useMemo, useState } from 'react';
import { ExecuteOrderEntity } from 'skychain-p2p-trading-js/lib/entities/execute-order.entity';
import BigNumber from 'bignumber.js';
import {
	convertExecuteOrderStatus,
	convertExecuteOrderType,
} from '../../utils/order';
import { format } from 'date-fns';
import BuySellTable from '../UI/BuySellTable';
import { ExecuteOrderStatus } from 'skychain-p2p-trading-js/lib/dto/execute-order.dto';
import Container from '../../layouts/Container';
import { UserIdentityClient } from 'user-identity-js';
import { UserEntity } from 'user-identity-js/lib/entities';
import { AdOrderEntity } from 'skychain-p2p-trading-js/lib/entities/ad-order.entity';
import { getUserName } from '../../utils/user';
import { ROUTES } from '../../routes/config';

interface Order extends Omit<ExecuteOrderEntity, 'user'> {
	user: UserEntity | undefined;
	adOrder: AdOrderEntity | undefined;
}
const P2POrders = () => {
	const p2pTradingClient = useSelector(
		(state: RootState) => state.system.p2pTradingClient
	);
	const userIdentity = useSelector(
		(state: RootState) => state.system.userIdentity
	);
	const [orders, setOrders] = useState<Order[]>([]);
	const columns: ColumnsOrdersTable[] = [
		{
			key: 'cryptos',
			title: 'Cryptos',
			isNumeric: false,
			align: 'start',
			render: (data: any) => (
				<Flex gap="10px" alignItems={'center'}>
					<img src={CURRENCY[data['currency']]?.icon} />
					<Flex direction={'column'} gap={'5px'}>
						<PrimaryText>{CURRENCY[data['currency']]?.symbol}</PrimaryText>
						<SecondaryText>{CURRENCY[data['currency']]?.name}</SecondaryText>
					</Flex>
				</Flex>
			),
		},
		{
			key: 'fiatAmount',
			title: 'Fiat Amount',
			isNumeric: false,
			align: 'center',
		},
		{
			key: 'price',
			title: 'Price',
			isNumeric: true,
			align: 'start',
		},
		{
			key: 'cryptoAmount',
			title: 'Crypto Amount',
			isNumeric: false,
			align: 'center',
		},
		{
			key: 'date',
			title: 'Date',
			isNumeric: false,
			align: 'center',
		},
		{
			key: 'counterparty',
			title: 'Counterparty',
			isNumeric: false,
			align: 'start',
		},
		{
			key: 'types',
			title: 'Types',
			isNumeric: false,
			align: 'start',
			render: (data: any) => (
				<Text
					color={data['type']?.toLowerCase() === 'buy' ? '#03A66D' : '#CF304A'}
					fontSize={{ base: '14px' }}
					textTransform={'capitalize'}
				>
					{data['type']}
				</Text>
			),
		},
		{
			key: 'status',
			title: 'Status',
			isNumeric: true,
			align: 'center',
			render: (data: any) => (
				<Text
					color={
						data['status'] === ExecuteOrderStatus.CONFIRM_SUCCESS
							? '#03A66D'
							: data['status'] === ExecuteOrderStatus.CANCEL
							? '#CF304A'
							: 'yellow'
					}
					fontSize={{ base: '14px' }}
					textTransform={'capitalize'}
				>
					{convertExecuteOrderStatus(data['status'])}
				</Text>
			),
		},
	];
	const dataTable: DataOrdersTable[] = useMemo(() => {
		const data = orders.map((item) => ({
			key: item.id,
			currency: item.adOrder?.assetSymbol || '',
			fiatAmount: `${+new BigNumber(item.amount)
				.multipliedBy(item.price)
				.toFixed(4)}`,
			type: convertExecuteOrderType(item.type) as any,
			cryptoAmount: `${+new BigNumber(item.amount).toFixed(4)} `,
			status: item.status,
			date: format(new Date(item.createdAt * 1000), 'dd/MM/yyyy'),
			counterparty: getUserName(item.user),
			price: `${+new BigNumber(item.price).toFixed(4)}  ${
				item.adOrder?.fiatSymbol
			} `,
		}));
		return data;
	}, [orders]);

	useEffect(() => {
		if (p2pTradingClient && userIdentity) {
			getExecuteOrders(p2pTradingClient, userIdentity);
		}
	}, [p2pTradingClient, userIdentity]);

	const getExecuteOrders = async (
		p2pTradingClient: P2PTradingClient,
		userIdenty: UserIdentityClient
	) => {
		try {
			const res = await p2pTradingClient.getExecutedOrders();
			const users = await userIdenty.getUsers({
				ids: res.data.map(
					(item: ExecuteOrderEntity) => item.targetUser
				) as string[],
			});
			const adOrders = await p2pTradingClient.getAllAdOrders({
				ids: res.data.map(
					(item: ExecuteOrderEntity) => item.adOrderId
				) as string[],
			});
			setOrders(
				res.data.map((item: ExecuteOrderEntity) => ({
					...item,
					user: users.data.find(
						(user: UserEntity) => user.id === item.targetUser
					),
					adOrder: adOrders.data.find(
						(adOrder: AdOrderEntity) => adOrder.id === item.adOrderId
					),
				}))
			);
		} catch (error) {
			console.log('getExecuteOrders', error);
		}
	};
	return (
		<Container>
			<Box py={{ base: '10px', lg: '40px' }}>
				<Show above="sm">
					<BuySellTable
						dataTable={dataTable}
						columns={columns}
						isBorder
						isCollapse
						redirectUrl={ROUTES.BUY_SELL_DETAIL}
					/>
				</Show>
			</Box>
		</Container>
	);
};

export default P2POrders;
