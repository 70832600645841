import { Box, Button, Flex, Text, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import Container from '../../layouts/Container';
import CloseAdsModal from './CloseAdsModal';
import ClockIcon from '../Icons/ClockIcon';
import PostAdModal from './PostAd/PostAdModal';

export default function PostAndCloseAds() {
	const {
		isOpen: isOpenModalCloseAds,
		onOpen: onOpenModalCloseAds,
		onClose: onCloseModalCloseAds,
	} = useDisclosure();

	const {
		isOpen: isOpenModalPostAd,
		onOpen: onOpenModalPostAd,
		onClose: onCloseModalPostAd,
	} = useDisclosure();
	return (
		<>
			<Box bg={'#09122980'} py={{ base: '12px' }}>
				<Container>
					<Flex justifyContent={'space-between'} alignItems={'center'}>
						<Button
							borderRadius={{ base: '5px' }}
							bg={'#ffffff'}
							p={{ base: '5px' }}
							w={{ base: '107px' }}
							_hover={{
								opacity: 0.8,
								bg: '#ffffff',
							}}
							onClick={onOpenModalPostAd}
						>
							<Flex
								w={'100%'}
								h={'100%'}
								borderRadius={{ base: '4px' }}
								bg={'linear-gradient(90deg, #FFBF1A 0%, #FCD535 100%)'}
								alignItems={'center'}
								justifyContent={'center'}
							>
								<Text
									color={'#050713'}
									textAlign={'center'}
									fontSize={{ base: '14px' }}
								>
									Post Ad +
								</Text>
							</Flex>
						</Button>

						<Flex
							alignItems={'center'}
							gap={'5px'}
							cursor={'pointer'}
							onClick={onOpenModalCloseAds}
						>
							<ClockIcon />
							<Text color={'#737283'} fontSize={{ base: '14px' }}>
								Close Ads
							</Text>
						</Flex>
					</Flex>
				</Container>
			</Box>

			<CloseAdsModal
				isOpen={isOpenModalCloseAds}
				onClose={onCloseModalCloseAds}
			/>
			<PostAdModal isOpen={isOpenModalPostAd} onClose={onCloseModalPostAd} />
		</>
	);
}
