import React from 'react';

export default function MoonDarkIcon() {
	return (
		<svg
			width={16}
			height={16}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M16 8.66923C14.9958 9.36141 13.7787 9.76658 12.4668 9.76658C9.02419 9.76658 6.23341 6.97579 6.23341 3.5332C6.23341 2.22137 6.63867 1.00419 7.33075 0C3.22452 0.346863 0 3.78969 0 7.98561C0 12.4119 3.58816 16 8.01438 16C12.2104 16 15.6532 12.7755 16 8.66923Z"
				fill="white"
			/>
		</svg>
	);
}
