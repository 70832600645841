import React from 'react';

export default function AssetAuditIcon() {
	return (
		<svg
			width={15}
			height={17}
			viewBox="0 0 15 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.59287 0.408379C8.56347 0.289222 8.49413 0.183731 8.39642 0.109469C8.29871 0.035207 8.17851 -0.00334628 8.05583 0.000227926H2.68542C2.56275 -0.00334628 2.44254 0.035207 2.34483 0.109469C2.24712 0.183731 2.17779 0.289222 2.14838 0.408379L1.72949 2.14839H9.01176L8.59287 0.408379Z"
				fill="#737283"
			/>
			<path
				d="M5.55837 12.8894H2.14816V11.8153H5.0804C4.91656 11.294 4.83326 10.7507 4.83336 10.2042C4.83336 10.0216 4.83336 9.84436 4.86022 9.66714H2.14816V8.59306H5.0804C5.42326 7.50284 6.10497 6.55042 7.0264 5.87433C7.94782 5.19823 9.06091 4.83371 10.2038 4.83378C10.3864 4.83378 10.5636 4.83378 10.7408 4.86063V4.02822C10.7408 3.81457 10.6559 3.60967 10.5049 3.4586C10.3538 3.30753 10.1489 3.22266 9.93525 3.22266H0.805561C0.591913 3.22266 0.387015 3.30753 0.235943 3.4586C0.0848713 3.60967 0 3.81457 0 4.02822V15.3061C0 15.5197 0.0848713 15.7246 0.235943 15.8757C0.387015 16.0268 0.591913 16.1116 0.805561 16.1116H10.7408V15.5746C10.5636 15.5746 10.3864 15.6014 10.2038 15.6014C9.25936 15.5978 8.33263 15.3451 7.51704 14.8689C6.70145 14.3928 6.02584 13.71 5.55837 12.8894ZM2.14816 5.37082H5.3704V6.4449H2.14816V5.37082Z"
				fill="#737283"
			/>
			<path
				d="M10.2036 5.90771C9.35382 5.90771 8.52317 6.15969 7.81664 6.63178C7.11012 7.10386 6.55945 7.77486 6.23427 8.55991C5.90909 9.34496 5.82401 10.2088 5.98978 11.0422C6.15556 11.8756 6.56474 12.6411 7.16559 13.242C7.76644 13.8428 8.53198 14.252 9.36538 14.4178C10.1988 14.5836 11.0626 14.4985 11.8477 14.1733C12.6327 13.8481 13.3037 13.2975 13.7758 12.5909C14.2479 11.8844 14.4999 11.0538 14.4999 10.204C14.4999 9.06458 14.0472 7.9718 13.2415 7.16608C12.4358 6.36036 11.343 5.90771 10.2036 5.90771ZM10.123 11.4822C10.023 11.5814 9.88795 11.6373 9.74707 11.6379C9.67639 11.6383 9.60633 11.6248 9.54089 11.5981C9.47546 11.5713 9.41595 11.532 9.36577 11.4822L8.10372 10.204L8.86095 9.44144L9.74707 10.3276L11.5462 8.53384L12.3034 9.29644L10.123 11.4822Z"
				fill="#737283"
			/>
		</svg>
	);
}
