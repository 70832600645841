import {
	Box,
	Flex,
	Modal,
	ModalCloseButton,
	ModalOverlay,
	Show,
	Text,
} from '@chakra-ui/react';
import React from 'react';
import {
	StyledModalBody,
	StyledModalContent,
	StyledModalHeader,
} from '../Wallets/DepositModal/styles';
import Filter from './Filter';
import { ColumnsAdsTable } from './interface';
import { DataAdsTable } from './interface';
import BuySellTable from '../UI/BuySellTable';
import { AdsBlock, PrimaryText, SecondaryText } from './AdsData';
import { CURRENCY } from '../../constants/cryptos';

export default function CloseAdsModal({
	isOpen,
	onClose,
}: {
	isOpen: boolean;
	onClose: () => void;
}) {
	const columns: ColumnsAdsTable[] = [
		{
			key: 'cryptos',
			title: 'Cryptos',
			isNumeric: false,
			align: 'start',
			render: (data: any) => (
				<Flex gap="10px" alignItems={'center'}>
					<img src={CURRENCY[data['currency']]?.icon} />
					<Flex direction={'column'} gap={'5px'}>
						<PrimaryText>{CURRENCY[data['currency']]?.symbol}</PrimaryText>
						<SecondaryText>{CURRENCY[data['currency']]?.name}</SecondaryText>
					</Flex>
				</Flex>
			),
		},
		{
			key: 'amount',
			title: 'Amount',
			isNumeric: false,
			align: 'start',
		},
		{
			key: 'limit',
			title: 'Limit',
			isNumeric: false,
			align: 'start',
		},
		{
			key: 'types',
			title: 'Types',
			isNumeric: false,
			align: 'start',
			render: (data: any) => (
				<Text
					color={data['type'] === 'buy' ? '#03A66D' : '#CF304A'}
					fontSize={{ base: '14px' }}
					textTransform={'capitalize'}
				>
					{data['type']}
				</Text>
			),
		},
		{
			key: 'status',
			title: 'Status',
			isNumeric: true,
			align: 'center',
		},
		{
			key: 'operation',
			title: 'Operation',
			isNumeric: true,
			align: 'center',
			render: (data: any) => (
				<Text
					color={'#21FF0E'}
					fontSize={{ base: '16px' }}
					textTransform={'capitalize'}
				>
					{data['operation']}
				</Text>
			),
		},
	];

	const dataTable: DataAdsTable[] = [
		{
			key: 1,
			currency: 'USDT',
			amount: '100,00 USDT',
			limit: '$50.00 - $100.00',
			type: 'buy',
			status: 'Online',
			bank: 'Funny Bank',
			operation: 'Successful',
		},
		{
			key: 2,
			currency: 'USDT',
			amount: '100,00 USDT',
			limit: '$50.00 - $100.00',
			type: 'sell',
			status: 'Online',
			bank: 'Funny Bank',
			operation: 'Successful',
		},
	];

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<StyledModalContent>
					<StyledModalHeader>Close Ads</StyledModalHeader>
					<ModalCloseButton />
					<StyledModalBody maxW={'1212px'} w={'90vw'}>
						<Filter />
						<Box>
							<Show above="sm">
								<BuySellTable
									dataTable={dataTable}
									columns={columns}
									isBorder
									isCollapse
								/>
							</Show>

							<Show below="sm">
								{dataTable.map((data) => (
									<AdsBlock key={data.key} data={data} />
								))}
							</Show>
						</Box>
					</StyledModalBody>
				</StyledModalContent>
			</Modal>
		</>
	);
}
