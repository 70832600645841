import { RouterProvider } from 'react-router-dom';
import './App.css';
import { router } from './routes';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import { USER } from './constants';
import { useSelector } from 'react-redux';
import { setAccount } from './redux/reducers/auth.slice';
import {
	setSupportedAssets,
	setSupportedChains,
	setTokenId,
} from './redux/reducers/system.slice';
import { UserAuthEntity } from 'user-identity-js/lib/entities/user.entity';
import MetaverseClient from 'metaverse-js';
import { RootState, useAppDispatch } from './redux/store';
import {
	fetchPaymentMethodsRequest,
	fetchUserAssetBalancesRequest,
	fetchUserProfileRequest,
} from './redux/actions/user.action';

function getLibrary(provider: any): Web3Provider {
	const library = new Web3Provider(provider);
	library.pollingInterval = 12_000;
	return library;
}

function App() {
	const dispatch = useAppDispatch();
	const account = useSelector((state: RootState) => state.auth.account);
	const metaverseJs = useSelector(
		(state: RootState) => state.system.metaverseJs
	);
	const userIdentity = useSelector(
		(state: RootState) => state.system.userIdentity
	);
	const p2pTradingClient = useSelector(
		(state: RootState) => state.system.p2pTradingClient
	);
	useEffect(() => {
		AOS.init();
		const user = localStorage.getItem(USER);
		const jsonUser: UserAuthEntity | null = user ? JSON.parse(user) : null;
		if (jsonUser) {
			dispatch(setAccount(jsonUser));
		}
		jsonUser?.idToken && dispatch(setTokenId(jsonUser.idToken));
	}, []);

	useEffect(() => {
		if (metaverseJs) {
			getSupportedChains(metaverseJs);
			getSupportedToken(metaverseJs);
			dispatch(fetchUserAssetBalancesRequest({ payload: undefined }));
		}
	}, [metaverseJs]);

	useEffect(() => {
		if (userIdentity && account?.id) {
			dispatch(fetchUserProfileRequest({ userId: account.id }));
		}
	}, [userIdentity, account?.id]);

	useEffect(() => {
		if (p2pTradingClient) {
			dispatch(fetchPaymentMethodsRequest({ payload: undefined }));
		}
	}, [p2pTradingClient]);
	const getSupportedToken = async (metaverseJs: MetaverseClient) => {
		try {
			const res = await metaverseJs.getDefaultSupportedAssets();
			dispatch(setSupportedAssets(res));
		} catch (error) {
			console.log('getSupportedToken', error);
		}
	};

	const getSupportedChains = async (metaverseJs: MetaverseClient) => {
		try {
			const res = await metaverseJs.getSupportChain();
			dispatch(setSupportedChains(res));
		} catch (error) {
			console.log('getSupportChain', error);
		}
	};

	return (
		<Web3ReactProvider getLibrary={getLibrary}>
			<RouterProvider router={router} />
		</Web3ReactProvider>
	);
}

export default App;
