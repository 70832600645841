import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getMetaverseJs, getP2pTradingClient, getUserIdentity } from '../store';
import MetaverseClient from 'metaverse-js';
import {
	setPaymentMethods,
	setUserAssetBalances,
	setUserProfile,
} from '../reducers/user.slice';
import {
	fetchPaymentMethodsRequest,
	fetchUserAssetBalancesRequest,
	fetchUserProfileRequest,
} from '../actions/user.action';
import { UserIdentityClient } from 'user-identity-js';
import { P2PTradingClient } from 'skychain-p2p-trading-js';

const getMyAssetBalances = async (metaverseJs: MetaverseClient) => {
	try {
		const res = await metaverseJs.getMyAssetBalances();
		return res;
	} catch (error) {
		console.log('getMyAssetBalances', error);
	}
};
const getUserProfile = async (
	userIdentity: UserIdentityClient,
	userId: string
) => {
	try {
		const userProfile = await userIdentity.getUser(userId);
		return userProfile;
	} catch (error) {
		console.log('getUserProfile', error);
	}
};

const getMyPaymentMethods = async (p2pTradingClient: P2PTradingClient) => {
	try {
		const res = await p2pTradingClient.getPaymentMethods();
		return res;
	} catch (error) {
		console.log('getMyPaymentMethods', error);
	}
};
function* fechUserAssetBalances({
	payload: { onSuccess = () => null, onError = () => null },
}: any): any {
	try {
		const metaverseJs: MetaverseClient = yield select(getMetaverseJs);
		if (!metaverseJs) return;
		const res: any = yield call(getMyAssetBalances, metaverseJs);
		yield put(setUserAssetBalances(res));
		onSuccess(true);
	} catch (error) {
		onError(error);
	}
}

function* fetchUserProfile({
	payload: { userId },
}: {
	payload: { userId: string };
}): any {
	try {
		const userIdentity: UserIdentityClient = yield select(getUserIdentity);
		if (!userIdentity) return;
		const res: any = yield call(getUserProfile, userIdentity, userId);
		yield put(setUserProfile(res));
	} catch (error) {
		console.log(error);
	}
}

function* fetchPaymentMethods({ payload }: any): any {
	try {
		const p2pTradingClient: P2PTradingClient = yield select(
			getP2pTradingClient
		);
		if (!p2pTradingClient) return;
		const res: any = yield call(getMyPaymentMethods, p2pTradingClient);
		yield put(setPaymentMethods(res));
	} catch (error) {
		console.log(error);
	}
}
export function* userSaga() {
	yield takeLatest(fetchUserAssetBalancesRequest, fechUserAssetBalances);
	yield takeLatest(fetchUserProfileRequest, fetchUserProfile);
	yield takeLatest(fetchPaymentMethodsRequest, fetchPaymentMethods);
}
