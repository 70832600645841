import React from 'react';

const TickIcon = ({ active = true }: { active: boolean }) => {
	return (
		<svg
			width={20}
			height={20}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_243_5717)">
				<path
					d="M14.9937 6.71242C14.6859 6.38781 14.1726 6.37352 13.8475 6.68215L8.6713 11.5911L6.20239 9.05621C5.8897 8.73539 5.377 8.72836 5.05591 9.04109C4.73509 9.35352 4.72833 9.86676 5.04075 10.1876L8.06778 13.2954C8.22641 13.4584 8.43723 13.5405 8.6486 13.5405C8.84915 13.5405 9.0497 13.4665 9.20673 13.3181L14.9635 7.85867C15.2883 7.55055 15.3021 7.03731 14.9937 6.71242Z"
					fill={active ? '#03A66D' : '#737283'}
				/>
				<path
					d="M10 0C4.48594 0 0 4.48594 0 10C0 15.5141 4.48594 20 10 20C15.5141 20 20 15.5141 20 10C20 4.48594 15.5141 0 10 0ZM10 18.3784C5.38027 18.3784 1.6216 14.62 1.6216 10C1.6216 5.38027 5.38023 1.6216 10 1.6216C14.62 1.6216 18.3784 5.38023 18.3784 10C18.3784 14.62 14.62 18.3784 10 18.3784Z"
					fill={active ? '#03A66D' : '#737283'}
				/>
			</g>
			<defs>
				<clipPath id="clip0_243_5717">
					<rect width={20} height={20} fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default TickIcon;
