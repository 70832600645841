import React from 'react';
import Container from '../../layouts/Container';
import { Box, Flex, Image, Img, List, ListItem } from '@chakra-ui/react';
import styled from '@emotion/styled';

const Wrapper = styled(Box)`
	margin-top: 118px;
`;

const listDesc = [
	{
		key: 'computer',
		title: 'Trading Volume: $1B USD',
	},
	{
		key: 'global',
		title: 'Supported Country Worldwide',
	},
	{
		key: 'cup',
		title: '03 years of experience',
	},
];

const SafeAndSecure = () => {
	return (
		<Wrapper>
			<Container>
				<Flex
					flexDirection={{ base: 'column', md: 'row-reverse' }}
					justifyContent={'space-between'}
				>
					<Box data-aos="fade-left" data-aos-duration={'1500'}>
						<Img src="/images/homepage/safeAndsecure.png" />
					</Box>
					<Box
						maxW={'548px'}
						textAlign={{ base: 'center', md: 'left' }}
						data-aos="fade-right"
						data-aos-duration={'1500'}
					>
						<Box fontSize={'30px'} lineHeight={'48px'} mb={'8px'}>
							Safe & Secure
						</Box>
						<Box color="#737283">
							Metaniex is one the safest cryptocurrency exchanges where you can
							buy, sell, store, and accrue staking rewards on cypto and other
							cryptocurrencies
						</Box>

						<List data-aos="fade-right" data-aos-duration={'1500'}>
							{listDesc.map((desc) => (
								<ListItem
									key={desc.key}
									display={'flex'}
									alignItems={'center'}
									my={'34px'}
									textAlign={'left'}
								>
									<Box mr={'30px'}>
										<Image src={`/images/homepage/${desc.key}-icon.svg`} />
									</Box>
									<Box fontSize={{ base: '18px', md: '24px' }} maxW={'412px'}>
										{desc.title}
									</Box>
								</ListItem>
							))}
						</List>
					</Box>
				</Flex>
			</Container>
		</Wrapper>
	);
};

export default SafeAndSecure;
