import React from 'react';
import DepositFunds, { Retangle } from '../DepositFunds';
import { Box, Flex, Show, Text, chakra } from '@chakra-ui/react';
import OutlineButton from '../../UI/OutlineButton';
import styled from '@emotion/styled';
import { breakpoints } from '../../../config/theme';
import { useDispatch } from 'react-redux';
import { setModal } from '../../../redux/reducers/system.slice';

const MethodLabel = chakra(Text, {
	baseStyle: {
		color: '#737283',
		fontSize: {
			base: '12px',
			lg: '18px',
		},
	},
});

const PrimaryText = chakra(Text, {
	baseStyle: {
		color: '#FFFFFF',
		fontSize: {
			base: '14px',
			lg: '20px',
		},
		fontWeight: 500,
	},
});

const SecondaryText = chakra(Text, {
	baseStyle: {
		color: '#737283',
		fontSize: {
			base: '14px',
			lg: '16px',
		},
	},
});

const StyledOutlineButton = styled(OutlineButton)`
	text-transform: capitalize;
	@media screen and (max-width: ${breakpoints.md}) {
		font-size: 14px;
		width: 92px;
		height: 30px;
		font-size: 14px;
	}
`;

interface Account {
	title: string;
	balance: string;
	currency: string;
	actions: Modal[];
}

export default function AssetAccount() {
	const accounts = [
		{
			title: 'Spot Account',
			balance: '0.00000000',
			currency: 'BTC',
			actions: ['deposit', 'withdraw', 'transfer'],
		},
		{
			title: 'P2P Account',
			balance: '0.00000000',
			currency: 'BTC',
			actions: ['deposit', 'withdraw', 'transfer'],
		},
		// {
		// 	title: 'Futures Account',
		// 	balance: '0.00000000',
		// 	currency: 'BTC',
		// 	actions: [],
		// },
		// {
		// 	title: 'Margin Account (Isolated)',
		// 	balance: '0.00000000',
		// 	currency: 'BTC',
		// 	actions: ['transfer'],
		// },
		// {
		// 	title: 'Margin Account (Cross)',
		// 	balance: '0.00000000',
		// 	currency: 'BTC',
		// 	actions: ['transfer'],
		// },
	] as Account[];
	const dispatch = useDispatch();

	const openModal = (modal: Modal) => {
		dispatch(setModal(modal));
	};

	return (
		<Box px={{ base: '18px', lg: '0px' }} pb={{ base: '0px', lg: '20px' }}>
			<DepositFunds />

			{accounts.map((account, index) => (
				<Box key={account.title} position={'relative'}>
					<Flex
						pt={{ base: '11px' }}
						pb={{ base: '20px' }}
						pr={{ base: 0, lg: '65px' }}
						direction={{ base: 'column', lg: 'row' }}
						gap={{ base: '10px' }}
						justifyContent={'space-between'}
					>
						<Box>
							<Flex alignItems={'center'} justifyContent={'space-between'}>
								<MethodLabel>{account.title}</MethodLabel>
								<Show below="md">
									{account.actions.length === 1 && (
										<StyledOutlineButton
											isGradient={false}
											onClick={() => {
												openModal(account.actions[0]);
											}}
										>
											{account.actions[0]}
										</StyledOutlineButton>
									)}
								</Show>
							</Flex>
							<Flex alignItems={'flex-end'} gap={'15px'}>
								<PrimaryText>
									{account.balance} {account.currency}
								</PrimaryText>
								<SecondaryText> ≈ $0.00 USD</SecondaryText>
							</Flex>
						</Box>

						<Box
							display={{
								base: account.actions.length === 1 ? 'none' : 'block',
								lg: 'block',
							}}
						>
							<Flex gap={'20px'} wrap={{ base: 'wrap' }}>
								{account.actions.map((action) => (
									<StyledOutlineButton
										key={action}
										isGradient={false}
										onClick={() => {
											openModal(action);
										}}
									>
										{action}
									</StyledOutlineButton>
								))}
							</Flex>
						</Box>
					</Flex>
					{index < accounts.length - 1 && <Retangle />}
				</Box>
			))}
		</Box>
	);
}
