import { Box, Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import React from 'react';
import SelectCustom from '../UI/SelectCustom';

const Label = styled(Text)`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #737283;
	margin-bottom: 10px;
`;

const cryptos = [{ key: 'all', value: 'All Cryptos' }];
const types = [{ key: 'all', value: 'All Types' }];
const statuses = [{ key: 'all', value: 'All status' }];

export default function Filter() {
	const cryptoCurrent = cryptos[0];
	const typeCurrent = types[0];
	const statusCurrent = statuses[0];

	const onSelect = (option: Option) => {
		console.log(option);
	};
	return (
		<Flex
			w={{ base: '100%' }}
			justifyContent={{ base: 'flex-start', lg: 'space-between' }}
			gap={{ base: '10px', lg: '30px' }}
			py={{ base: '20px' }}
			wrap={'wrap'}
		>
			<Box
				w={{ base: 'calc(100% / 3 - 10px)', lg: 'calc(100% / 3 - 20px)' }}
				minW={{ base: '100px', lg: 'auto' }}
			>
				<Label>Cryptos</Label>
				<SelectCustom
					value={cryptoCurrent}
					list={cryptos}
					onSelect={onSelect}
				/>
			</Box>

			<Box
				w={{ base: 'calc(100% / 3 - 10px)', lg: 'calc(100% / 3 - 20px)' }}
				minW={{ base: '100px', lg: 'auto' }}
			>
				<Label>Types</Label>
				<SelectCustom value={typeCurrent} list={types} onSelect={onSelect} />
			</Box>

			<Box
				w={{ base: 'calc(100% / 3 - 10px)', lg: 'calc(100% / 3 - 20px)' }}
				minW={{ base: '100px', lg: 'auto' }}
			>
				<Label>Status</Label>
				<SelectCustom
					value={statusCurrent}
					list={statuses}
					onSelect={onSelect}
				/>
			</Box>
		</Flex>
	);
}
