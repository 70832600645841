import { Box, BoxProps, Flex, Text, useToast } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { Label, NextButton, PreviousButton } from './PostAdModal';
import QuestionIcon from '../../Icons/QuestionIcon';
import AmountInput from '../../UI/AmountInput';
import { useForm } from 'react-hook-form';
import TextGradient from '../../UI/TextGradient';
import SelectCustom from '../../UI/SelectCustom';
import { AdOrder } from 'skychain-p2p-trading-js/lib/dto/ad-order.dto';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import BigNumber from 'bignumber.js';
import { ErrorMessage } from '../../Wallets/TransferModal/styles';
import { P2PTradingClient } from 'skychain-p2p-trading-js';
import { PaymentMethodEntity } from 'skychain-p2p-trading-js/lib/entities/payment-method.entity';
import { ROUTES } from '../../../routes/config';
import { useNavigate } from 'react-router-dom';

type FormData = {
	minAmount: number;
	maxAmount: number;
	totalAmount: number;
	paymentMethod: string;
	timeLimit: number;
};

const timeLimitList = [
	{ key: 3, value: '3 Mins' },
	{ key: 15, value: '15 Mins' },
	{ key: 30, value: '30 Mins' },
	{ key: 60, value: '1 Hours' },
];

interface SetAmountAndPaymentViewProps {
	onPrevStep: () => void;
	onNextStep: (data: FormData) => void;
	data: AdOrder;
}
export default function SetAmountAndPaymentView({
	onPrevStep,
	onNextStep,
	data,
}: SetAmountAndPaymentViewProps) {
	const toast = useToast();
	const {
		register,
		handleSubmit,
		watch,
		setValue,
		clearErrors,
		formState: { errors },
	} = useForm<FormData>({
		defaultValues: {
			timeLimit: timeLimitList[0].key,
		},
	});
	const navigate = useNavigate();
	const [selectedTimeLimit, setSelectedTimeLimit] = useState<Option>(
		timeLimitList[0]
	);
	const [paymentMethods, setPaymentMethods] = useState<PaymentMethodEntity[]>(
		[]
	);
	const p2pTradingClient = useSelector(
		(state: RootState) => state.system.p2pTradingClient
	);
	const myAssetBalances = useSelector(
		(state: RootState) => state.user.userAssetBalances
	);
	const assetBalance = useMemo(() => {
		if (!data?.assetSymbol || !data) return;
		const res = myAssetBalances.find(
			(asset) =>
				asset.assetSymbol === data.assetSymbol && asset.chain === 'FUNDING'
		);
		return res;
	}, [myAssetBalances, data]);

	const onSubmit = (data: FormData) => {
		onNextStep(data);
	};

	const onSelect = (option: Option) => {
		setSelectedTimeLimit(option);
		setValue('timeLimit', option.key as number);
	};
	useEffect(() => {
		if (data) {
			setValue('minAmount', data.minAmount || 0);
			setValue('maxAmount', data.maxAmount || 0);
			setValue('totalAmount', data.totalAmount || 0);
			setValue('paymentMethod', data.paymentMethod || '');
		}
	}, [data]);

	useEffect(() => {
		if (p2pTradingClient) {
			getPaymentMethods(p2pTradingClient);
		}
	}, [p2pTradingClient]);
	const getPaymentMethods = async (p2pTradingClient: P2PTradingClient) => {
		if (!p2pTradingClient) return;
		try {
			const res = await p2pTradingClient.getPaymentMethods();
			setPaymentMethods(res);
		} catch (error: any) {
			console.log('getPaymentMethods', error);
			toast({
				title: 'Error',
				description: error?.response?.data?.message || 'Something went wrong',
			});
		}
	};
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Flex direction={'column'} gap={{ base: '20px' }}>
				<Box>
					<Label mb={{ base: '10px' }}>Total Amount</Label>
					<AmountInput
						register={register('totalAmount', {
							required: 'This field is required',
							validate: (value) => {
								if (
									new BigNumber(value).gt(
										assetBalance?.availableAssetAmount || 0
									)
								) {
									return 'Insufficient balance';
								}
								if (new BigNumber(value).lte(0)) {
									return 'Amount must be greater than 0';
								}
								return true;
							},
						})}
						expandW={'7.5rem'}
						type="number"
						expand={
							<Flex
								alignItems={'center'}
								gap={'10px'}
								justifyContent={'flex-end'}
								w={'100%'}
								px={{ base: '16px' }}
							>
								<Box
									cursor={'pointer'}
									onClick={() => {
										setValue(
											'totalAmount',
											new BigNumber(
												assetBalance?.availableAssetAmount || 0
											).toNumber()
										);
										clearErrors('totalAmount');
									}}
								>
									<TextGradient>All</TextGradient>
								</Box>
								<Label>{data?.assetSymbol || 'USDT'}</Label>
							</Flex>
						}
						textColor="#ffffff"
					/>
					<Flex
						w={'100%'}
						justifyContent={'space-between'}
						alignItems={'center'}
						mt={{ base: '10px' }}
					>
						<Label>~ 100,00 USD</Label>
						<Label>
							~ Avbl{' '}
							{new BigNumber(assetBalance?.availableAssetAmount || 0).toFixed(
								2
							)}{' '}
							USDT
						</Label>
					</Flex>
					{errors.totalAmount?.message && (
						<ErrorMessage style={{ marginTop: '10px' }}>
							{errors.totalAmount?.message}
						</ErrorMessage>
					)}
				</Box>

				<Box>
					<Flex w={'100%'} alignItems={'center'}>
						<Flex alignItems={'center'} gap="5px" mb={{ base: '10px' }}>
							<Label>Order Limit</Label>
							<QuestionIcon width={13} height={13} />
						</Flex>
					</Flex>
					<Flex alignItems={'center'}>
						<Box>
							<AmountInput
								type="number"
								expand={
									<Text fontSize={{ base: '14px' }} pr={{ base: '10px' }}>
										{data?.fiatSymbol || 'USD'}
									</Text>
								}
								expandW={'auto'}
								textColor="#ffffff"
								register={register('minAmount', {
									required: 'This field is required',
									validate: (value) => {
										if (new BigNumber(value).gt(watch('maxAmount'))) {
											return 'Min amount must be smaller than max amount';
										}
										if (new BigNumber(value).lte(0)) {
											return 'Min amount must be greater than 0';
										}
										return true;
									},
								})}
							/>

							<ErrorMessage style={{ marginTop: '10px', height: '12px' }}>
								{errors.minAmount?.message}
							</ErrorMessage>
						</Box>
						<Text px={{ base: '10px' }} style={{ marginBottom: '22px' }}>
							~
						</Text>
						<Box>
							<AmountInput
								type="number"
								expand={
									<Text fontSize={{ base: '14px' }} pr={{ base: '10px' }}>
										{data?.fiatSymbol || 'USD'}
									</Text>
								}
								expandW={'auto'}
								textColor="#ffffff"
								register={register('maxAmount', {
									required: 'This field is required',
									validate: (value) => {
										if (new BigNumber(value).lt(watch('minAmount'))) {
											return 'Min amount must be smaller than max amount';
										}
										if (new BigNumber(value).lt(0)) {
											return 'Min amount must not be less than 0';
										}
										return true;
									},
								})}
							/>

							<ErrorMessage style={{ marginTop: '10px', height: '12px' }}>
								{errors.maxAmount?.message}
							</ErrorMessage>
						</Box>
					</Flex>
				</Box>

				<Flex alignItems={'center'} justifyContent={'space-between'}>
					<Box>
						<Label>Pament Method</Label>
						{/* <Label mt={{ base: '10px' }}>Select up to 5 methods</Label> */}
					</Box>
					{/* <Button
						bg={'rgba(255, 255, 255, 0.05)'}
						border="1px solid"
						borderColor={'rgba(255, 255, 255, 0.08)'}
						borderRadius={'4px'}
					>
						<Flex alignItems={'center'} gap="5px">
							<PlusIcon width={16} height={16} />
							<Text fontSize={{ base: '16px' }} fontWeight={400}>
								Add
							</Text>
						</Flex>
					</Button> */}
				</Flex>

				<Flex
					direction={'column'}
					gap={'20px'}
					{...register('paymentMethod', {
						required: 'This field is required',
					})}
				>
					{paymentMethods.length === 0 ? (
						<Box onClick={() => navigate(ROUTES.ACCOUNT.ADD_PAYMENT_METHOD)}>
							Add payment method
						</Box>
					) : (
						paymentMethods.map((item) => (
							<PaymentBlock
								onClick={() => {
									setValue('paymentMethod', item.id);
									clearErrors('paymentMethod');
								}}
								key={item.id}
								paymentMethod={item}
								isSelected={item.id === watch('paymentMethod')}
							/>
						))
					)}
				</Flex>
				{errors.paymentMethod?.message && (
					<ErrorMessage>{errors.paymentMethod?.message}</ErrorMessage>
				)}
				<Box>
					<Flex alignItems={'center'} justifyContent={'space-between'}>
						<Flex alignItems={'center'} gap="5px" mb={{ base: '10px' }}>
							<Label>Payment Time Limit</Label>
							<QuestionIcon width={13} height={13} />
						</Flex>
						<Box w={'90px'}>
							<SelectCustom
								{...register('timeLimit', {
									required: 'This field is required',
								})}
								value={selectedTimeLimit}
								list={timeLimitList}
								onSelect={onSelect}
								isBorder={false}
							/>
						</Box>
					</Flex>
					{errors.timeLimit?.message && (
						<ErrorMessage>{errors.timeLimit?.message}</ErrorMessage>
					)}
				</Box>

				<Flex w={'100%'} justifyContent={'space-between'} alignItems={'center'}>
					<Box w={'calc(35% - 10px)'}>
						<PreviousButton onClick={onPrevStep}>Previous</PreviousButton>
					</Box>
					<Box w={'calc(65% - 10px)'}>
						<NextButton type="submit">Next</NextButton>
					</Box>
				</Flex>
			</Flex>
		</form>
	);
}

interface PaymentBlockProps extends BoxProps {
	paymentMethod: PaymentMethodEntity;
	isSelected?: boolean;
}
const PaymentBlock = ({
	paymentMethod,
	isSelected,
	...props
}: PaymentBlockProps) => {
	return (
		<Box
			border={
				isSelected ? '1px solid #FFBF1A' : '1px solid rgba(255, 255, 255, 0.08)'
			}
			borderRadius={'4px'}
			position={'relative'}
			p={{ base: '20px' }}
			style={{ cursor: 'pointer' }}
			{...props}
		>
			<Label>{paymentMethod.bankName}</Label>
			<Text my={{ base: '5px' }}>{paymentMethod.fullName}</Text>
			<Label>{paymentMethod.bankAccountNumber}</Label>

			{/* <Box position={'absolute'} top={'20px'} right={'20px'} cursor={'pointer'}>
				<CloseIcon />
			</Box> */}
		</Box>
	);
};
