import {
	Modal,
	ModalCloseButton,
	ModalOverlay,
	useToast,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { setModal } from '../../../redux/reducers/system.slice';
import Steps from '../Steps';
import QuestionIcon from '../../Icons/QuestionIcon';
import SelectCustom from '../../UI/SelectCustom';
import { useEffect, useMemo, useState } from 'react';
import WarningIcon from '../../Icons/WarningIcon';
import TextGradient from '../../UI/TextGradient';
import MetaverseClient from 'metaverse-js';
import { Transaction } from 'metaverse-js/lib/proto/model/wallet';
import { Responsive } from 'metaverse-js/lib/index';
import { format } from 'date-fns';

import {
	ChainItem,
	ChainListWrapper,
	WrapperInput,
	DepositContent,
	DepositHistory,
	Header,
	Label,
	LeftColumn,
	Retangle,
	RightColumn,
	Row,
	StepPanel,
	StyledDepositHistoryDesktop,
	StyledDepositHistoryMobile,
	StyledInput,
	StyledModalBody,
	StyledModalContent,
	StyledModalHeader,
	Title,
	Warning,
	WarningContent,
	WithDrawAddressHeader,
	MaxBtn,
	CurrencyLabel,
	WithdrawButton,
	StyledForm,
	ErrorMessage,
} from './styles';
import { WITHDRAW_STEPS } from '../../../constants/deposit';
import BigNumber from 'bignumber.js';
import { useForm } from 'react-hook-form';
import { isAddress } from 'ethers';
import { mapAssetsToOptions, mapChainsToOptions } from '../../../utils/asset';
import useWalletAddress from '../../../hooks/useWalletAddress';
import { Asset } from 'metaverse-js/lib/proto/model/asset';
import { fetchUserAssetBalancesRequest } from '../../../redux/actions/user.action';

interface WithdrawForm {
	chain: string;
	withdrawAddress: string;
	amount: number;
	selectedToken: string;
}
const WithdrawModal: React.FC = () => {
	const toast = useToast();
	const {
		register,
		handleSubmit,
		setValue,
		reset,
		formState: { errors },
		clearErrors,
	} = useForm<WithdrawForm>();
	const modal = useSelector((state: RootState) => state.system.modal);
	const chain = useSelector((state: RootState) => state.system.chain);
	const userAccount = useSelector((state: RootState) => state.auth.account);
	const myAssetBalances = useSelector(
		(state: RootState) => state.user.userAssetBalances
	);
	const dispatch = useDispatch();
	const metaverseJs = useSelector(
		(state: RootState) => state.system.metaverseJs
	);
	const supportedChains = useSelector(
		(state: RootState) => state.system.supportedChains
	);
	const supportedAssets = useSelector(
		(state: RootState) => state.system.supportedAssets
	);

	const [selectedToken, setSelectedToken] = useState<Option | undefined>();
	const [selectedChain, setSelectedChain] = useState<Option | undefined>();
	const [transactions, setTransactions] = useState<TransactionHistory[]>([]);
	const { address: userWalletAddress } = useWalletAddress('SPOT');

	const assetBalance = useMemo(() => {
		if (!selectedToken || !myAssetBalances || !chain) return;
		const res = myAssetBalances.find(
			(asset) =>
				asset.assetSymbol === selectedToken.value && asset.chain === chain
		);
		return res;
	}, [myAssetBalances, selectedToken, chain]);

	const withdrawFee = useMemo(() => {
		if (!selectedToken || !supportedAssets) return '-';
		const asset = supportedAssets.find(
			(asset: Asset) => asset.symbol === selectedToken.value
		);
		if (!asset) return '-';
		return asset.transactionFeePercent;
	}, [supportedAssets, selectedToken]);

	const supportedAssetsOptions = useMemo(
		() => mapAssetsToOptions(supportedAssets),
		[supportedAssets]
	);

	const supportedChainOptions = useMemo(
		() => mapChainsToOptions(supportedChains),
		[supportedChains]
	);

	useEffect(() => {
		if (supportedAssetsOptions.length > 0 && modal === 'withdraw') {
			setSelectedToken(supportedAssetsOptions[0]);
			setValue('selectedToken', supportedAssetsOptions[0].value);
		}
	}, [supportedAssetsOptions, modal]);

	useEffect(() => {
		if (supportedChainOptions.length > 0 && modal === 'withdraw') {
			setSelectedChain(supportedChainOptions[0]);
			setValue('chain', supportedChainOptions[0].value);
		}
	}, [supportedChainOptions, modal]);

	useEffect(() => {
		if (metaverseJs && modal === 'withdraw') {
			getTransactions(metaverseJs);
		}
	}, [selectedChain, metaverseJs, modal]);

	const getTransactions = async (metaverseJs: MetaverseClient) => {
		try {
			const res: Responsive<Transaction[]> =
				await metaverseJs.getMyTransactions({
					type: 'WITHDRAW',
				});
			const transactions: Transaction[] = res.data;
			setTransactions(
				transactions.map((item) => ({
					currency: item.assetSymbol || '',
					time: item.createdAt
						? format(new Date(item.createdAt * 1000), 'yyyy-MM-dd')
						: '',
					amount: item.assetAmount || '',
					address: item.toAccount || '',
					transactionId: item.id || '',
					state: item.state || '',
				}))
			);
		} catch (error) {
			console.log('getTransactions', error);
		}
	};

	const onSubmit = async (values: WithdrawForm) => {
		if (!chain || !metaverseJs || !userWalletAddress) return;
		try {
			const txBody: Transaction = {
				assetSymbol: values.selectedToken,
				toAccount: values.withdrawAddress,
				fromChain: chain,
				fromAccount: userWalletAddress || '',
				fromUser: userAccount?.id || '',
				toChain: values.chain,
				assetAmount: values.amount.toString(),
				assetType: 'ERC20',
				nonce: (userAccount?.id || '') + Date.now(),
			};
			const res = await metaverseJs.withdraw(txBody);
			if (res?.nonce) {
				toast({
					title: 'Success',
					status: 'success',
					description: 'Withdraw successfully',
				});
				await new Promise((resolve) => setTimeout(resolve, 1000));
				dispatch(fetchUserAssetBalancesRequest({ payload: undefined }));
				dispatch(setModal(null));
				handleClose();
			}
		} catch (error: any) {
			toast({
				title: 'Error',
				status: 'error',
				description: error?.response?.data?.message || 'Withdraw failed',
			});
		}
	};

	const handleClose = () => {
		dispatch(setModal(null));
		reset();
	};

	return (
		<Modal isOpen={modal === 'withdraw'} onClose={handleClose} isCentered>
			<ModalOverlay />
			<StyledModalContent>
				<StyledModalHeader>Withdraw</StyledModalHeader>
				<ModalCloseButton />
				<StyledModalBody>
					<StepPanel>
						<Steps steps={WITHDRAW_STEPS} />
					</StepPanel>
					<StyledForm onSubmit={handleSubmit(onSubmit)}>
						<DepositContent>
							<LeftColumn>
								<Label>Tokens</Label>
								<SelectCustom
									{...register('selectedToken', {
										required: 'Please select token',
									})}
									list={supportedAssetsOptions}
									value={selectedToken}
									onSelect={(item) => {
										setValue('selectedToken', item.value);
										setSelectedToken(item);
									}}
								/>
								{errors.selectedToken && (
									<ErrorMessage>{errors.selectedToken?.message}</ErrorMessage>
								)}
								<Row>
									<div>Balances</div>
									<div>
										{
											+new BigNumber(
												assetBalance?.totalAssetAmount || 0
											).toFixed(2)
										}
									</div>
								</Row>
								<Row>
									<div>Available</div>
									<div>
										{
											+new BigNumber(
												assetBalance?.availableAssetAmount || 0
											).toFixed(2)
										}
									</div>
								</Row>
								<Row>
									<div>Freeze</div>
									<div>
										{
											+new BigNumber(
												assetBalance?.lockedAssetAmount || 0
											).toFixed(2)
										}
									</div>
								</Row>
								<Row>Tips</Row>
								<Row>
									<Label>Withdrawable:</Label>
									<div>
										{
											+new BigNumber(
												assetBalance?.availableAssetAmount || 0
											).toFixed(4)
										}{' '}
										{selectedToken?.value || 'USDT'}
									</div>
								</Row>
								<Row>
									<Label>24h Withdrawal Limit:</Label>
									<div>50000.00/50000.00 {selectedToken?.value || 'USDT'}</div>
								</Row>
								<Warning>
									<WarningIcon />
									<WarningContent>
										Min Limit 10.00000000， Max Limit 30000000.0000
									</WarningContent>
								</Warning>
							</LeftColumn>
							<RightColumn>
								<Label>
									Chain name <QuestionIcon />
								</Label>
								<ChainListWrapper
									{...register('chain', {
										required: 'Please select chain',
									})}
								>
									{supportedChainOptions.map((chain) => (
										<ChainItem
											selected={selectedChain?.key === chain.key}
											key={chain.key}
											onClick={() => {
												setSelectedChain(chain);
												setValue('chain', chain.value);
											}}
										>
											{chain.value}
										</ChainItem>
									))}
								</ChainListWrapper>
								{errors.chain && (
									<ErrorMessage>{errors.chain?.message}</ErrorMessage>
								)}
								<WithDrawAddressHeader>
									<Label>Withdraw Address</Label>
									{/* <StyledTextGradient>Address List</StyledTextGradient> */}
								</WithDrawAddressHeader>
								<StyledInput
									{...register('withdrawAddress', {
										required: 'Please enter withdraw address',
										validate: (value) => {
											if (!isAddress(value)) {
												return 'Invalid address';
											}
											return true;
										},
									})}
									placeholder="Please enter withdraw address"
								/>
								{errors.withdrawAddress && (
									<ErrorMessage>{errors.withdrawAddress?.message}</ErrorMessage>
								)}
								<WithDrawAddressHeader>
									<Label>Amount</Label>
								</WithDrawAddressHeader>
								<WrapperInput>
									<StyledInput
										{...register('amount', {
											required: 'Please enter amount',
											validate: (value) => {
												if (new BigNumber(value).lte(0)) {
													return 'Amount must be greater than 0';
												}
												if (
													new BigNumber(value).gt(
														assetBalance?.availableAssetAmount || 0
													)
												) {
													return 'Insufficient balance';
												}
												return true;
											},
										})}
										type="number"
										placeholder="Amount"
									/>
									<MaxBtn
										onClick={() => {
											setValue(
												'amount',
												new BigNumber(
													assetBalance?.availableAssetAmount || 0
												).toNumber()
											);
											clearErrors('amount');
										}}
									>
										All
									</MaxBtn>
									<CurrencyLabel>
										{selectedToken?.value || 'USDT'}
									</CurrencyLabel>
								</WrapperInput>
								{errors.amount && (
									<ErrorMessage>{errors.amount?.message}</ErrorMessage>
								)}
								<Label>Fee: {withdrawFee}</Label>
								<WithdrawButton type="submit">Withdraw</WithdrawButton>
							</RightColumn>
						</DepositContent>
					</StyledForm>
					<DepositHistory>
						<Header>
							<Title>Recent</Title>
							<TextGradient>All</TextGradient>
						</Header>
						<Retangle />
						<StyledDepositHistoryDesktop list={transactions} />
						<StyledDepositHistoryMobile list={transactions} />
					</DepositHistory>
				</StyledModalBody>
			</StyledModalContent>
		</Modal>
	);
};

export default WithdrawModal;
