import {
	Box,
	Flex,
	Modal,
	ModalCloseButton,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import React from 'react';
import {
	StyledModalBody,
	StyledModalContent,
	StyledModalHeader,
} from '../Wallets/DepositModal/styles';
import { NextButton } from '../P2PAds/PostAd/PostAdModal';
import AmountInput from '../UI/AmountInput';
import { useForm } from 'react-hook-form';

export default function SercurityVerificationModal({
	isOpen,
	onClose,
	onSubmit,
}: {
	isOpen: boolean;
	onClose: () => void;
	onSubmit: (otp: string) => void;
}) {
	const { register, handleSubmit, watch, reset } = useForm<{ otp: string }>();
	const handleSubmitForm = (data: any) => {
		onSubmit(data.otp);
		reset();
	};
	return (
		<Modal isCentered isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<StyledModalContent>
				<StyledModalHeader>Security Verification</StyledModalHeader>
				<ModalCloseButton />
				<form onSubmit={handleSubmit(handleSubmitForm)}>
					<StyledModalBody maxW={'548px'} w={'90vw'}>
						<Flex direction={'column'} gap={'30px'}>
							<Text fontSize={'14px'} lineHeight={'24px'} color={'#737283'}>
								To secure your account, please complete the following
								verification. You can also use Metaniex/Google Authenticator.
							</Text>
							<Flex direction={'column'}>
								<Text
									fontSize={'14px'}
									lineHeight={'24px'}
									color={'#737283'}
								></Text>
								<Text
									fontSize={'14px'}
									lineHeight={'24px'}
									color={'#737283'}
									mb={'12px'}
								>
									Phone Number Verification Code
								</Text>
								<AmountInput
									placeHolder="Enter your code"
									register={register('otp', {
										required: true,
									})}
								/>
							</Flex>
							<Flex
								w={'100%'}
								justifyContent={'space-between'}
								alignItems={'center'}
							>
								<Box w={'100%'}>
									<NextButton isDisabled={!watch('otp')} type="submit">
										Submit
									</NextButton>
								</Box>
							</Flex>
						</Flex>
					</StyledModalBody>
				</form>
			</StyledModalContent>
		</Modal>
	);
}
