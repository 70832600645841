import React from 'react';

const ShieldIcon = () => {
	return (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M26.9989 10.1203C26.9718 9.46658 26.7606 8.833 26.3888 8.28982C26.0169 7.74664 25.4989 7.31509 24.8922 7.04304L18.5517 4.49597C16.9174 3.83466 15.0826 3.83466 13.4483 4.49597L7.10781 7.04193C6.50087 7.31411 5.98265 7.74581 5.6106 8.28917C5.23855 8.83254 5.02721 9.46633 5 10.1203V15.0157C5.06313 17.9509 6.08828 20.788 7.92368 23.1073C9.75909 25.4265 12.3072 27.1045 15.1908 27.8928C15.7208 28.0357 16.2803 28.0357 16.8103 27.8928C19.6937 27.1043 22.2416 25.4262 24.0768 23.107C25.912 20.7878 26.9369 17.9507 27 15.0157L26.9989 10.1203ZM16.3562 26.2789C16.1234 26.3414 15.8777 26.3414 15.6449 26.2789C13.1192 25.5919 10.8862 24.1256 9.27648 22.0971C7.66676 20.0685 6.76587 17.5855 6.70719 15.0157V10.1203C6.73227 9.79859 6.84176 9.48872 7.02504 9.22078C7.20831 8.95284 7.45913 8.73594 7.75313 8.59115L14.0959 6.0452C15.3153 5.55108 16.6847 5.55108 17.9041 6.0452L24.2457 8.59227C24.5398 8.7367 24.7907 8.95335 24.974 9.22114C25.1573 9.48892 25.2667 9.79869 25.2917 10.1203V15.0157C25.2331 17.5853 24.3326 20.068 22.7233 22.0965C21.114 24.125 18.8815 25.5915 16.3562 26.2789Z"
				fill="#737283"
			/>
			<path
				d="M19 14.2454C19.0026 13.6438 18.8491 13.0534 18.5568 12.5406C18.2646 12.0279 17.8452 11.6132 17.3459 11.3433C16.8466 11.0734 16.2872 10.959 15.7308 11.013C15.1743 11.067 14.643 11.2872 14.1965 11.6489C13.75 12.0106 13.4062 12.4993 13.2038 13.06C13.0014 13.6208 12.9484 14.2312 13.0508 14.8226C13.1533 15.4141 13.407 15.9629 13.7835 16.4074C14.16 16.8519 14.6443 17.1743 15.1818 17.3384V20.119C15.1818 20.3526 15.268 20.5767 15.4214 20.7419C15.5749 20.9072 15.783 21 16 21C16.217 21 16.4251 20.9072 16.5785 20.7419C16.7319 20.5767 16.8181 20.3526 16.8181 20.119V17.3384C17.4446 17.1482 17.9964 16.7434 18.3901 16.1853C18.7837 15.6272 18.9978 14.9462 19 14.2454ZM16 15.7138C15.7303 15.7138 15.4666 15.6277 15.2424 15.4663C15.0181 15.305 14.8433 15.0756 14.7401 14.8073C14.6369 14.539 14.6099 14.2438 14.6625 13.9589C14.7151 13.6741 14.845 13.4125 15.0357 13.2071C15.2264 13.0017 15.4694 12.8619 15.7339 12.8052C15.9985 12.7486 16.2726 12.7777 16.5218 12.8888C16.771 12.9999 16.984 13.1881 17.1338 13.4296C17.2836 13.6711 17.3636 13.955 17.3636 14.2454C17.363 14.6347 17.2192 15.0078 16.9636 15.283C16.708 15.5583 16.3614 15.7132 16 15.7138Z"
				fill="#737283"
			/>
		</svg>
	);
};

export default ShieldIcon;
