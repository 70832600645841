import {
	Box,
	Flex,
	Radio,
	RadioGroup,
	Stack,
	Textarea,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { Label, NextButton, PreviousButton } from './PostAdModal';
import QuestionIcon from '../../Icons/QuestionIcon';
import SelectCustom from '../../UI/SelectCustom';
import {
	AdOrderRegion,
	AdOrderStatus,
} from 'skychain-p2p-trading-js/lib/dto/ad-order.dto';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '../../Wallets/TransferModal/styles';

const regions = [{ key: AdOrderRegion.ALL, value: 'All Region(s)' }];

interface FormData {
	term: string;
	autoReply: string;
	regions: AdOrderRegion;
	status: AdOrderStatus;
}
interface SetRemarksAndAutoResponseViewProps {
	onPrevStep: () => void;
	onNextStep: (data: FormData) => void;
}
export default function SetRemarksAndAutoResponseView({
	onPrevStep,
	onNextStep,
}: SetRemarksAndAutoResponseViewProps) {
	const [selectedRegion, setSelectedRegion] = useState<Option>(regions[0]);
	const [status, setStatus] = useState<AdOrderStatus>(AdOrderStatus.ONLINE);
	const {
		register,
		handleSubmit,
		setValue,
		clearErrors,
		formState: { errors },
	} = useForm<FormData>({
		defaultValues: {
			regions: regions[0].key,
		},
	});
	const onSelect = (option: Option) => {
		setSelectedRegion(option);
		setValue('regions', option.key as AdOrderRegion);
		clearErrors('regions');
		console.log(option);
	};
	const onSubmit = (data: FormData) => {
		onNextStep(data);
	};
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Flex direction={'column'} gap={{ base: '20px' }}>
				<Box>
					<Label mb={{ base: '10px' }} fontSize={{ lg: '16px' }}>
						Terms
					</Label>
					<Textarea
						{...register('term', {
							required: 'This is required',
						})}
						placeholder="Terms will be displayed to the counterparty"
					/>
				</Box>
				{errors.term?.message && (
					<ErrorMessage>{errors.term?.message}</ErrorMessage>
				)}
				<Box>
					<Label mb={{ base: '10px' }} fontSize={{ lg: '16px' }}>
						Auto - Reply
					</Label>
					<Textarea
						{...register('autoReply', {
							required: 'This is required',
						})}
						placeholder="The auto-reply message will be sent to thhe counterparty once the order is created"
					/>
					{errors.autoReply?.message && (
						<ErrorMessage style={{ marginTop: '10px' }}>
							{errors.autoReply?.message}
						</ErrorMessage>
					)}
				</Box>

				{/* <Box>
				<Label mb={{ base: '10px' }} fontSize={{ lg: '16px' }}>
					Counterparty Conditions
				</Label>
				<Label mb={{ base: '10px' }}>
					Adding counterparty requirements will reduce the exposure of your Ad
				</Label>
				<Flex alignItems={'center'} gap="10px">
					<Radio value="2">
						<Label>Registered</Label>
					</Radio>
					<Box p={{ base: '2px 10px' }} bg={'#141829'} borderRadius={'3px'}>
						<Label>00</Label>
					</Box>
					<Label>day(s) ago</Label>
				</Flex>
				<Flex alignItems={'center'} gap="10px" mt={{ base: '10px' }}>
					<Radio value="2">
						<Label>Holding more than</Label>
					</Radio>
					<Box p={{ base: '2px 10px' }} bg={'#141829'} borderRadius={'3px'}>
						<Label>00</Label>
					</Box>
					<Label>BTC</Label>
				</Flex>
			</Box> */}

				<Flex alignItems={'center'} justifyContent={'space-between'}>
					<Flex alignItems={'center'} gap="5px">
						<Label>Reserved Fee</Label>
						<QuestionIcon width={13} height={13} />
					</Flex>
					<Box w={'120px'}>
						<SelectCustom
							{...register('regions', {
								required: 'This field is required',
							})}
							value={selectedRegion}
							list={regions}
							onSelect={onSelect}
							isBorder={false}
						/>
					</Box>
				</Flex>
				{errors.regions?.message && (
					<ErrorMessage>{errors.regions?.message}</ErrorMessage>
				)}

				<Flex
					{...register('status', {
						required: 'This field is required',
					})}
					alignItems={'center'}
					gap={{ base: '30px' }}
				>
					<RadioGroup
						value={status}
						onChange={(nextValue: string) =>
							setStatus(nextValue as AdOrderStatus)
						}
					>
						<Stack direction="row">
							<Radio value={AdOrderStatus.ONLINE}>
								<Label>Online</Label>
							</Radio>
							<Radio value={AdOrderStatus.OFFLINE}>
								<Label>Offline</Label>
							</Radio>
						</Stack>
					</RadioGroup>
				</Flex>

				<Flex w={'100%'} justifyContent={'space-between'} alignItems={'center'}>
					<Box w={'calc(35% - 10px)'}>
						<PreviousButton onClick={onPrevStep}>Previous</PreviousButton>
					</Box>
					<Box w={'calc(65% - 10px)'}>
						<NextButton type="submit">Next</NextButton>
					</Box>
				</Flex>
			</Flex>
		</form>
	);
}
