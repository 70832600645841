import { Box, Flex, Img, Text } from '@chakra-ui/react';
import React from 'react';
import TableUI from '../../UI/TableUI';

const columns = [
	{
		key: 'coin',
		title: 'Coin',
		render: (value: any, data: any) => (
			<Flex>
				<Img src={`/images/currency/${data.iconCoin}.svg`} mr={'10px'} />
				<Box>
					<Text mb={'5px'}>{data.titleCoin}</Text>
					<Text color={'#737283'} fontSize={'14px'}>
						{data.descriptionCoin}
					</Text>
				</Box>
			</Flex>
		),
	},
	{
		key: 'available',
		title: 'Available',
	},
	{
		key: 'freeze',
		title: 'Freeze',
	},
	{
		key: 'operation',
		title: 'Operation',
		render: (value: string) => <Box color="yellow">{value}</Box>,
		isNumeric: true,
	},
] as Columns[];

const dataTable: { [key: string]: any }[] = [
	{
		iconCoin: 'usdt',
		titleCoin: 'USDT',
		descriptionCoin: 'Tether USD',
		available: '0.00',
		freeze: '0.00',
		operation: 'Capital Transfer',
	},
	{
		iconCoin: 'btc',
		titleCoin: 'USDT',
		descriptionCoin: 'Tether USD',
		available: '0.00',
		freeze: '0.00',
		operation: 'Capital Transfer',
	},
	{
		iconCoin: 'usdt',
		titleCoin: 'USDT',
		descriptionCoin: 'Tether USD',
		available: '0.00',
		freeze: '0.00',
		operation: 'Capital Transfer',
	},
	{
		iconCoin: 'usdt',
		titleCoin: 'USDT',
		descriptionCoin: 'Tether USD',
		available: '0.00',
		freeze: '0.00',
		operation: 'Capital Transfer',
	},
];

const P2PAccountTable = () => {
	return (
		<>
			<Box display={{ base: 'none', md: 'block' }}>
				<TableUI isBorder={true} columns={columns} dataTable={dataTable} />
			</Box>

			<Box display={{ base: 'block', md: 'none' }}>
				{dataTable.map((data, index) => {
					return (
						<Flex
							justifyContent={'space-between'}
							key={index}
							borderTop={'1px solid #FFFFFF1E'}
							p={'20px'}
						>
							<Box>
								{columns.slice(0, 2).map((col) => (
									<Flex key={col.key} alignItems={'center'} mb={'10px'}>
										{col.key === 'coin' ? (
											<>
												<Box mr={'6px'}>
													<Img
														w={'20px'}
														h={'20px'}
														src={`/images/currency/${data.iconCoin}.svg`}
													/>
												</Box>

												<Text mr={'6px'} lineHeight={'16px'} fontSize={'12px'}>
													{data.titleCoin}
												</Text>
												<Text
													mr={'6px'}
													lineHeight={'16px'}
													fontSize={'12px'}
													color={'#737283'}
												>
													{data.descriptionCoin}
												</Text>
											</>
										) : (
											<>
												<Box w={'76px'} color={'#737283'} fontSize={'12px'}>
													{col.title}:{' '}
												</Box>
												<Box fontSize={'14px'}>{data[col.key]}</Box>
											</>
										)}
									</Flex>
								))}
							</Box>
							<Box>
								<Flex opacity={0} mb={'10px'}>
									<Box w={'60px'} color={'#737283'} fontSize={'12px'}>
										hidden
									</Box>
									<Box fontSize={'14px'}>hidden</Box>
								</Flex>
								{columns.slice(2, columns.length).map((col) =>
									col.key === 'operation' ? (
										<Text key={col.key} fontSize={'14px'} color={'yellow'}>
											{data[col.key]}
										</Text>
									) : (
										<Flex key={col.key} mb={'10px'}>
											<Box w={'60px'} color={'#737283'} fontSize={'12px'}>
												{col.title}:
											</Box>
											<Box fontSize={'14px'}>
												{data[col.key] === true ? (
													<Text color={'#21FF0E'}>Successful</Text>
												) : data[col.key] === false ? (
													<Text color={'#FF0E39'}>Failed</Text>
												) : (
													data[col.key]
												)}
											</Box>
										</Flex>
									)
								)}
							</Box>
						</Flex>
					);
				})}
			</Box>
		</>
	);
};

export default P2PAccountTable;
