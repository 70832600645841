export default function RefreshIcon() {
	return (
		<svg
			width={17}
			height={18}
			viewBox="0 0 17 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_113_5514)">
				<path
					d="M12.9924 6.03438L10.688 8.30125C10.4238 8.56125 10.6055 9 10.9771 9H12.4555C12.4555 11.6894 10.2338 13.875 7.49999 13.875C6.84751 13.875 6.21155 13.7531 5.64169 13.5175C5.34435 13.3956 5.00573 13.485 4.78273 13.7044C4.36151 14.1188 4.51017 14.8175 5.06354 15.0369C5.81513 15.3375 6.64103 15.5 7.49999 15.5C11.1506 15.5 14.1074 12.5913 14.1074 9H15.5858C15.9574 9 16.1391 8.56125 15.8748 8.30937L13.5705 6.0425C13.4136 5.88 13.1493 5.88 12.9924 6.03438ZM2.54448 9C2.54448 6.31063 4.76621 4.125 7.49999 4.125C8.15247 4.125 8.78843 4.24688 9.35831 4.4825C9.65565 4.60438 9.99427 4.515 10.2173 4.29562C10.6385 3.88125 10.4898 3.1825 9.93646 2.96313C9.18487 2.6625 8.35895 2.5 7.49999 2.5C3.84943 2.5 0.89263 5.40875 0.89263 9H-0.585771C-0.957437 9 -1.13914 9.43875 -0.874844 9.69063L1.42948 11.9575C1.59467 12.12 1.8507 12.12 2.01589 11.9575L4.32021 9.69063C4.57625 9.43875 4.39454 9 4.02288 9H2.54448Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_113_5514">
					<rect
						width={17}
						height={17}
						fill="white"
						transform="translate(0 0.5)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
}
