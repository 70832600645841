import React from 'react';
import Banner from './Banner';
import { Outlet } from 'react-router-dom';
import Type from './Type';

export default function BuySellLayout() {
	return (
		<div>
			<Banner />
			<Type />
			<Outlet />
		</div>
	);
}
