import {
	Input,
	InputGroup,
	InputProps,
	InputRightElement,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import React from 'react';

const InputCustom = styled(Input)<any>`
	border: 1px solid #ffffff1e;
	color: ${(props) => props.textColor};
	::placeholder,
	::-webkit-input-placeholder {
		color: ${(props) => props.textColor};
	}
	:-ms-input-placeholder {
		color: ${(props) => props.textColor};
	}
`;

interface PropsType extends InputProps {
	placeHolder?: string;
	register?: any;
	expand?: React.ReactNode;
	isReadOnly?: boolean;
	expandW?: string | number;
	value?: string | number;
	defaultValue?: string | number;
	textColor?: string;
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
	type?: React.HTMLInputTypeAttribute;
}

export default function AmountInput({
	placeHolder = '',
	register,
	expand,
	isReadOnly = false,
	expandW = '7.5rem',
	value,
	defaultValue,
	textColor = '#737283',
	...props
}: PropsType) {
	return (
		<InputGroup size="md">
			<InputCustom
				pr="7.5rem"
				placeholder={placeHolder}
				focusBorderColor="#FFFFFF1E"
				size={'md'}
				{...register}
				isReadOnly={isReadOnly}
				defaultValue={defaultValue}
				value={value}
				textColor={textColor}
				{...props}
			/>
			{expand && (
				<InputRightElement width={expandW}>{expand}</InputRightElement>
			)}
		</InputGroup>
	);
}
