import Banner from '../Banner';

export default function SpotBanner() {
	const actions = [
		'deposit',
		'withdraw',
		'transfer',
		'trade-history',
	] as BannerAction[];

	const valuation = {
		balance: '0.00000000',
		currency: 'BTC',
	} as Valuation;

	return (
		<Banner
			title={'Spot Account'}
			valuation={valuation}
			yesterdayPNL={'000 00'}
			actions={actions}
			isPayment
		/>
	);
}
