export default function DescreaseSortIcon(props: any) {
	return (
		<svg
			{...props}
			width={6}
			height={3}
			viewBox="0 0 6 3"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4.79289 0C5.23835 0 5.46143 0.538571 5.14645 0.853553L3.35355 2.64645C3.15829 2.84171 2.84171 2.84171 2.64645 2.64645L0.853554 0.853554C0.538571 0.538571 0.761654 0 1.20711 0H4.79289Z"
				fill="#737283"
			/>
		</svg>
	);
}
