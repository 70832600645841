import { Box, Flex, Img, Text } from '@chakra-ui/react';
import React from 'react';
import Container from '../../layouts/Container';

const listBeginners = [
	{
		id: 1,
		description: 'Quickly understand fiat currency transactions',
	},
	{
		id: 2,
		description: 'How to buy digital currency',
	},
	{
		id: 3,
		description: 'How to sell numerical currency transactions',
	},
];

const BeginnersGuide = () => {
	return (
		<Box mt={'118px'} w={'100%'}>
			<Container>
				<Flex
					justifyContent={'space-between'}
					flexDir={{ base: 'column', md: 'row' }}
					w={'100%'}
					mb={'30px'}
				>
					<Box
						fontSize={'30px'}
						lineHeight={'48px'}
						mb={{ base: '1rem', md: 0 }}
						data-aos="fade-right"
						data-aos-duration="1000"
					>
						Beginner&lsquo;s guide
					</Box>
					<Box color="#737283" data-aos="fade-left" data-aos-duration="1000">
						You can start learning about digital currency trading
					</Box>
				</Flex>
				<Flex
					justifyContent={'space-between'}
					flexDir={{ base: 'column', md: 'row' }}
				>
					{listBeginners.map((item, index) => (
						<Box
							key={item.id}
							bg={'#091229'}
							py={'50px'}
							borderRadius={'20px'}
							mb={{ base: '30px', md: 0 }}
							data-aos="fade-up"
							data-aos-duration={`1${index + 1}00`}
						>
							<Img
								src={`/images/homepage/beginners-${item.id}.png`}
								alt={`beginners-{${item.id}}`}
								w={{ base: '320px', lg: '100%' }}
								margin={'auto'}
							/>
							<Text
								maxW={'328px'}
								mx={'auto'}
								textAlign={'center'}
								fontSize={'24px'}
								mt={'30px'}
							>
								{item.description}
							</Text>
						</Box>
					))}
				</Flex>
			</Container>
		</Box>
	);
};

export default BeginnersGuide;
