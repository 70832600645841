import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Authentication from '.';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useToast } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { ROUTES } from '../../routes/config';

const formFields = [
	{
		key: 1,
		label: 'Email or Phone number',
		name: 'emailOrPhone',
		type: 'email',
		placeholder: 'Enter your Email or Phone number...',
		required: true,
	},
	{
		key: 2,
		label: 'Password',
		name: 'password',
		type: 'password',
		placeholder: 'Enter your password...',
		required: true,
	},
	{
		key: 3,
		label: 'Invitation code (Optional)',
		name: 'invitationCode',
		type: 'text',
		placeholder: 'Enter your code...',
	},
	{
		key: 4,
		label: (
			<>
				I have read & agreed Metaniex,
				<Link style={{ color: '#FFBF1A', marginLeft: '4px' }} to="/">
					Terms of Service
				</Link>
			</>
		),
		name: 'agreeTerms',
		type: 'checkbox',
		required: true,
	},
];

const Register = () => {
	const toast = useToast();
	const navigate = useNavigate();
	const { register, handleSubmit, reset } = useForm();
	const userIdentity = useSelector(
		(state: RootState) => state.system.userIdentity
	);
	const onSubmit = async (value: any) => {
		if (!userIdentity) return;
		try {
			const res = await userIdentity.registerByEmail(
				value.emailOrPhone,
				value.password
			);
			if (res && !!res.id) {
				toast({
					title: 'Register successfully',
					status: 'success',
				});
				reset();
				navigate(
					//TO DO remove code after having email send to user
					//@ts-ignore
					`${ROUTES.SECURITY_SETTING}?email=${res.email}`
				);
			}
		} catch (error: any) {
			toast({
				title: 'Error',
				description: error?.response?.data?.message || 'Register failed',
				status: 'error',
			});
		}
	};
	return (
		<Authentication
			title={'Welcome to register'}
			titleButton={'Sign up'}
			subtitle={
				<>
					Already have an account?
					<Link style={{ color: '#FFBF1A', marginLeft: '4px' }} to="/login">
						Log in
					</Link>
				</>
			}
			onSubmit={handleSubmit(onSubmit)}
			register={register}
			formFields={formFields}
		/>
	);
};

export default Register;
