export default function ShowIcon(props: any) {
	return (
		<svg
			{...props}
			width={20}
			height={20}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18.6498 9.66062C18.4936 9.41925 14.7726 3.75 9.99992 3.75C5.22728 3.75 1.50627 9.41925 1.35002 9.66062C1.28473 9.76177 1.25 9.8796 1.25 9.99998C1.25 10.1204 1.28473 10.2382 1.35002 10.3393C1.50627 10.5807 5.22728 16.25 9.99992 16.25C14.7726 16.25 18.4936 10.5807 18.6498 10.3394C18.7151 10.2382 18.7499 10.1204 18.7499 10C18.7499 9.87961 18.7151 9.76177 18.6498 9.66062ZM9.99992 13.75C9.25824 13.75 8.53322 13.5301 7.91653 13.118C7.29985 12.706 6.8192 12.1203 6.53537 11.4351C6.25155 10.7498 6.17728 9.99584 6.32198 9.26841C6.46667 8.54098 6.82382 7.8728 7.34827 7.34835C7.87272 6.8239 8.5409 6.46675 9.26833 6.32206C9.99576 6.17736 10.7498 6.25162 11.435 6.53545C12.1202 6.81928 12.7059 7.29993 13.1179 7.91661C13.53 8.5333 13.7499 9.25832 13.7499 10C13.7499 10.9946 13.3548 11.9484 12.6516 12.6517C11.9483 13.3549 10.9945 13.75 9.99992 13.75Z"
				fill="#737283"
			/>
			<path
				d="M10.9829 7.70061C10.8788 7.78253 10.7932 7.88559 10.7317 8.00301C10.6703 8.12043 10.6345 8.24954 10.6266 8.38181C10.6187 8.51409 10.639 8.64654 10.686 8.77041C10.7331 8.89429 10.8059 9.00679 10.8996 9.10049C10.9933 9.19419 11.1058 9.26696 11.2296 9.314C11.3535 9.36105 11.486 9.3813 11.6182 9.37342C11.7505 9.36554 11.8796 9.32971 11.997 9.26829C12.1145 9.20688 12.2175 9.12126 12.2994 9.0171C12.5273 9.55074 12.5622 10.147 12.3983 10.7036C12.2344 11.2602 11.8818 11.7424 11.4011 12.0674C10.9204 12.3924 10.3416 12.5398 9.76401 12.4845C9.18642 12.4292 8.6461 12.1746 8.23581 11.7643C7.82551 11.354 7.57085 10.8137 7.51553 10.2361C7.46022 9.65851 7.6077 9.07968 7.93266 8.59898C8.25762 8.11827 8.73979 7.76569 9.29638 7.60177C9.85298 7.43784 10.4493 7.47278 10.9829 7.70061Z"
				fill="#737283"
			/>
		</svg>
	);
}
