import React from 'react';
import { Box, Button, chakra, Image } from '@chakra-ui/react';
import Container from '../../layouts/Container';

const ButtonAuth = chakra(Button, {
	baseStyle: {
		background: 'linear-gradient(90deg, #FFBF1A 0%, #FCD535 100%)',
		borderRadius: '36px',
		py: '30px',
		px: '40px',
		fontSize: { base: '20px', md: '26px' },
		color: 'black',
		fontWeight: 500,
		_hover: {
			background: 'linear-gradient(90deg, #FFBF1A 0%, #FCD535 100%)',
		},
	},
});

const RegisterNow = () => {
	return (
		<Box my={'218px'}>
			{/* <Container>
				<Box textAlign={'center'}>
					<Box
						fontSize={{ base: '20px', md: '30px' }}
						color={'#FFBF1A'}
						mb={'10px'}
						data-aos="fade-up"
						data-aos-duration={'1500'}
					>
						Want to Start trading right away?
					</Box>
					<Box
						fontSize={{ base: '16px', md: '26px' }}
						color="#BDBCC7"
						mb={'10px'}
						data-aos="fade-up"
						data-aos-duration={'1500'}
					>
						Register your account immediately and start trading.
					</Box>

					<ButtonAuth data-aos="fade-up" data-aos-duration={'1500'}>
						<Box>Register Now</Box>
						<Image src="/images/homepage/next-icon.svg" ml={'16px'} />
					</ButtonAuth>
				</Box>
			</Container> */}
		</Box>
	);
};

export default RegisterNow;
