import React, { useEffect, useState } from 'react';
import { Flex, Image, Box, Button, Text } from '@chakra-ui/react';
import Container from '../../layouts/Container';
import TableUI, { Columns } from '../UI/TableUI';
import BigNumber from 'bignumber.js';

const columns = [
	{
		key: 'name',
		title: 'Name',
		isNumeric: false,
		render: (name: string, data: any) => (
			<Flex alignItems={'center'}>
				<Image mr={'12px'} src={data['image']} w={{ base: '32px' }} />
				<Box mr={'12px'} textTransform={'uppercase'}>
					{name}
				</Box>
				<Text color={'#737283'} fontSize={'14px'} textTransform={'uppercase'}>
					{data['symbol']}
				</Text>
			</Flex>
		),
	},
	{
		key: 'lastPrice',
		title: 'Last Price',
		isNumeric: false,
		align: 'end',
		render: (value: any) => <Text textAlign={'right'}>${value}</Text>,
	},
	{
		key: '_24hChange',
		title: '24h Change',
		isNumeric: false,
		align: 'end',
		render: (value: any) => (
			<Text
				textAlign={'right'}
				color={new BigNumber(value).isGreaterThan(0) ? '#03A66D' : '#CF304A'}
			>
				{new BigNumber(value).toFixed(2)}%
			</Text>
		),
	},
	{
		key: '_24hVol',
		title: '24h Vol',
		isNumeric: false,
		align: 'end',
		render: (value: any) => (
			<Text textAlign={'right'}>${new BigNumber(value).toFormat(2)}</Text>
		),
	},
	// {
	// 	key: 'trade',
	// 	title: 'Trade',
	// 	isNumeric: true,
	// 	render: () => (
	// 		<Button
	// 			fontSize={'16px'}
	// 			py={'5px'}
	// 			px={'20px'}
	// 			lineHeight={'24px'}
	// 			bg={'#03A66D'}
	// 			fontWeight={500}
	// 			color={'white'}
	// 		>
	// 			Trade
	// 		</Button>
	// 	),
	// },
] as Columns[];

const PopularCryptocurrencies = ({
	cryptoMarkets,
}: {
	cryptoMarkets: any[];
}) => {
	const [dataTable, setDataTable] = useState<any[]>([]);
	const updateDataTable = () => {
		try {
			const newData = cryptoMarkets?.map((item: any) => {
				return {
					key: item?.id,
					name: item?.name,
					symbol: item?.symbol,
					lastPrice: new BigNumber(item?.current_price).toFormat(2),
					_24hChange: item?.price_change_percentage_24h,
					_24hVol: item?.total_volume,
					trade: 1,
					image: item?.image,
				};
			});
			setDataTable(newData);
		} catch (error) {}
	};
	useEffect(() => {
		if (cryptoMarkets) {
			updateDataTable();
		}
	}, [cryptoMarkets]);

	return (
		<Box mt={'118px'}>
			<Container>
				<Flex justifyContent={'space-between'} alignItems={'flex-end'}>
					<Box
						fontSize={{ base: '20px', md: '30px' }}
						data-aos="fade-right"
						data-aos-duration="1000"
					>
						Popular cryptocurrencies
					</Box>
					{/* <Box
						display={{ base: 'none', md: 'flex' }}
						alignItems={'center'}
						cursor={'pointer'}
						data-aos="fade-left"
						data-aos-duration="1000"
					>
						<Box mr={'18.4px'}>View more markets </Box>
						<Box>
							<Image src="/images/homepage/arrowRight-icon.svg" />
						</Box>
					</Box> */}
				</Flex>

				<Box data-aos="fade-up" data-aos-duration="1000">
					<TableUI isBorder={true} dataTable={dataTable} columns={columns} />
				</Box>

				{/* <Box
					data-aos="fade-up"
					data-aos-duration="1000"
					border={'0.6px solid #737283'}
					py={'16px'}
					maxW={'212.52px'}
					mx={'auto'}
					borderRadius={'8px'}
					mt={'20px'}
					display={{ md: 'none' }}
				>
					<Box
						display={{ base: 'flex', md: 'none' }}
						alignItems={'center'}
						cursor={'pointer'}
						justifyContent={'center'}
					>
						<Box mr={'18.4px'}>View more markets </Box>
						<Box>
							<Image src="/images/homepage/arrowRight-icon.svg" />
						</Box>
					</Box>
				</Box> */}
			</Container>
		</Box>
	);
};

export default PopularCryptocurrencies;
