import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Authentication from '.';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { ROUTES } from '../../routes/config';
import { useToast } from '@chakra-ui/react';
const formFields = [
	{
		key: 1,
		label: 'Email verification code',
		name: 'code',
		type: 'text',
		placeholder: 'Enter your code',
		required: true,
	},
];

const SercurityVerification = () => {
	const { register, handleSubmit } = useForm();
	const navigate = useNavigate();
	const toast = useToast();
	const [searchParams] = useSearchParams();
	const email = searchParams.get('email');
	const userIdentity = useSelector(
		(state: RootState) => state.system.userIdentity
	);
	const onSubmit = async (value: any) => {
		try {
			const res = await userIdentity?.confirmUser(value.code);
			if (res) {
				navigate(ROUTES.LOGIN);
			}
		} catch (error: any) {
			toast({
				title: 'Error',
				description: error?.response?.data?.message || 'Unknown error',
				status: 'error',
			});
		}
	};
	return (
		<Authentication
			title={'Security Verification'}
			titleButton={'Confirm'}
			subtitle={
				<>
					Please check your inbox for verification code send to{' '}
					<span style={{ color: '#FFBF1A', marginLeft: '4px' }}>{email}</span>
				</>
			}
			formFields={formFields}
			register={register}
			onSubmit={handleSubmit(onSubmit)}
		/>
	);
};

export default SercurityVerification;
