export default function SearchIcon(props: any) {
	return (
		<svg
			{...props}
			width={12}
			height={12}
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.6642 10.8358L10.0133 9.19085C10.9083 8.08523 11.3449 6.67837 11.233 5.26034C11.1212 3.84232 10.4695 2.52127 9.41238 1.56958C8.35522 0.617903 6.97318 0.108161 5.55124 0.145467C4.12931 0.182773 2.7759 0.764285 1.77009 1.77009C0.764285 2.7759 0.182773 4.12931 0.145467 5.55124C0.108161 6.97318 0.617903 8.35522 1.56958 9.41238C2.52127 10.4695 3.84232 11.1212 5.26034 11.233C6.67837 11.3449 8.08523 10.9083 9.19085 10.0133L10.8358 11.6642C10.8901 11.7189 10.9546 11.7623 11.0257 11.7919C11.0968 11.8215 11.173 11.8367 11.25 11.8367C11.327 11.8367 11.4033 11.8215 11.4743 11.7919C11.5454 11.7623 11.61 11.7189 11.6642 11.6642C11.7189 11.61 11.7623 11.5454 11.7919 11.4743C11.8215 11.4033 11.8367 11.327 11.8367 11.25C11.8367 11.173 11.8215 11.0968 11.7919 11.0257C11.7623 10.9546 11.7189 10.8901 11.6642 10.8358ZM1.33335 5.70835C1.33335 4.84305 1.58993 3.99719 2.07067 3.27773C2.5514 2.55826 3.23468 1.99751 4.03411 1.66637C4.83353 1.33524 5.7132 1.2486 6.56187 1.41741C7.41053 1.58622 8.19008 2.0029 8.80194 2.61475C9.41379 3.22661 9.83047 4.00616 9.99928 4.85483C10.1681 5.70349 10.0815 6.58316 9.75032 7.38259C9.41918 8.18201 8.85843 8.8653 8.13897 9.34603C7.4195 9.82676 6.57364 10.0833 5.70835 10.0833C4.54802 10.0833 3.43523 9.62241 2.61475 8.80194C1.79428 7.98147 1.33335 6.86867 1.33335 5.70835Z"
				fill="#737283"
			/>
		</svg>
	);
}
