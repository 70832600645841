import React from 'react';
import { chakra } from '@chakra-ui/system';
import { Box, Flex } from '@chakra-ui/layout';
import Container from '../../layouts/Container';
import { Button, Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes/config';

// style component atoms
const Wrapper = chakra(Box, {
	baseStyle: {
		height: 'calc(100vh - 72px)',
		w: '100%',
		background: {
			base: 'url(/images/homepage/intro-bg-mobile.png)',
			md: 'url(/images/homepage/intro-bg.png)',
		},
		bgSize: { md: 'cover' },
		bgRepeat: { md: 'no-repeat' },
		color: 'white',
		position: { base: 'relative' },
	},
});

const Title = chakra(Box, {
	baseStyle: {
		fontSize: { base: '24px', md: '38px' },
		lineHeight: { base: '40px', md: '48px' },
		mb: { base: '13px', md: '44px' },
		textAlign: { base: 'center', md: 'left' },
	},
});

const Subtitle = chakra(Box, {
	baseStyle: {
		fontSize: { base: '16px', md: '18px' },
		lineHeight: { base: '20px' },
		mb: { base: '25px' },
		display: 'flex',
		justifyContent: { base: 'center', md: 'flex-start' },
	},
});

const ButtonAuth = chakra(Button, {
	baseStyle: {
		background: 'linear-gradient(90deg, #FFBF1A 0%, #FCD535 100%)',
		borderRadius: '36px',
		py: '14px',
		fontSize: '16px',
		color: 'black',
		fontWeight: 500,
		w: { base: '100%', md: '384px' },
		_hover: {
			background: 'linear-gradient(90deg, #FFBF1A 0%, #FCD535 100%)',
		},
	},
});

const Footer = chakra(Box, {
	baseStyle: {
		w: '100%',
		bg: '#091229',
		position: 'absolute',
		bottom: 0,
		py: '14px',
	},
});

const ButtonGiff = chakra(Box, {
	baseStyle: {
		background: 'linear-gradient(90deg, #FFBF1A 0%, #FCD535 100%)',
		borderRadius: '4px',
		py: '4px',
		px: '10px',
		ml: { base: 0, md: '33.79px' },
		fontSize: '12px',
		color: 'black',
	},
});

// render ui
const Intro = () => {
	const account = useSelector((state: RootState) => state.auth.account);
	const navigate = useNavigate();
	return (
		<Wrapper>
			<Container>
				<Flex
					justifyContent={{ base: 'center', md: 'space-between' }}
					alignItems={'center'}
					height={'100%'}
					flexDirection={{ base: 'column', md: 'row' }}
					w={'100%'}
				>
					<Flex
						flexDirection={'column'}
						alignItems={{ base: 'center', md: 'flex-start' }}
						data-aos="fade-right"
						data-aos-duration="1000"
					>
						<Title>
							Buy, trade, and hold 350+ cryptocurrencies on Metaniex
						</Title>
						<Subtitle>
							<Image src="/images/homepage/money-icon.svg" mr={'10px'} />
							<Box>Join the world’s largest crypto exchange!</Box>
						</Subtitle>
						{/* {!(account && account.id) && (
							<ButtonAuth onClick={() => navigate(ROUTES.LOGIN)}>
								<Image src="/images/homepage/human.svg" mr={'10px'} />
								<Box>Sign up with Email or Phone</Box>
							</ButtonAuth>
						)} */}
					</Flex>
					<Box data-aos="fade-left" data-aos-duration="1000">
						<img src="/images/homepage/Astronaut.png" alt="" />
					</Box>
				</Flex>
			</Container>
			{/* <Footer>
				<Container>
					<Flex
						flexDir={{ base: 'column', md: 'row' }}
						alignItems={'center'}
						justifyContent={'center'}
						display={{ base: 'none', md: 'flex' }}
					>
						<Box mr={'8px'}>
							<img src="/images/homepage/giff-icon.svg" alt="" />
						</Box>
						<Box display={'flex'}>
							Register now — Get up to 100 USDT in trading fee rebate (for
							verified users)
						</Box>
						<Box>
							<ButtonGiff>Redeem Gift</ButtonGiff>
						</Box>
					</Flex>
					<Box>
						<Flex
							flexDir={{ base: 'column', md: 'row' }}
							alignItems={'center'}
							justifyContent={'center'}
							display={{ base: 'flex', md: 'none' }}
						>
							<Box
								maxW={'369px'}
								lineHeight={'24px'}
								mb={'7px'}
								fontSize={{ base: '14px' }}
							>
								<Box maxW={'369px'} display={'flex'} justifyContent={'center'}>
									<Image
										mr={'8px'}
										src="/images/homepage/giff-icon.svg"
										alt=""
									/>
									Register now
								</Box>
								<Box textAlign={'center'}>
									Get up to 100 USDT in trading fee rebate (for verified users)
								</Box>
							</Box>

							<Box>
								<ButtonGiff>Redeem Gift</ButtonGiff>
							</Box>
						</Flex>
					</Box>
				</Container>
			</Footer> */}
		</Wrapper>
	);
};

export default Intro;
