import React from 'react';
import PostAndCloseAds from './PostAndCloseAds';
import { Box } from '@chakra-ui/react';
import Container from '../../layouts/Container';
import Filter from './Filter';
import AdsData from './AdsData';

export default function Body() {
	return (
		<Box>
			<PostAndCloseAds />
			<Container>
				<Filter />
				<AdsData />
			</Container>
		</Box>
	);
}
