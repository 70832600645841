import Banner from '../Banner';

export default function AssetBanner() {
	const actions = ['deposit', 'withdraw', 'transfer'] as BannerAction[];

	const valuation = {
		balance: '0.00000000',
		currency: 'BTC',
	} as Valuation;

	return (
		<Banner title={'Asset Overview'} valuation={valuation} actions={actions} />
	);
}
