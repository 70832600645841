import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import MetaverseClient from 'metaverse-js';
import { UserIdentityClient } from 'user-identity-js';
import { getEnv } from '../../utils';
import { Asset } from 'metaverse-js/lib/proto/model/asset';
import { P2PTradingClient } from 'skychain-p2p-trading-js';
import { ChatRoomClient } from 'skychain-chat-js';
interface SystemState {
	modal: Modal;
	metaverseJs: MetaverseClient | null;
	userIdentity: UserIdentityClient | null;
	p2pTradingClient: P2PTradingClient;
	isSecurityDialog: boolean;
	chain: Chain;
	chatRoomClient?: ChatRoomClient;
	supportedAssets: Asset[];
	supportedChains: (string | undefined)[];
}

const initialState = {
	modal: null,
	userIdentity: new UserIdentityClient({
		baseUrl: `${getEnv('REACT_APP_BASE_URL')}identity`,
	}),
	metaverseJs: new MetaverseClient({
		baseUrl: `${getEnv('REACT_APP_BASE_URL')}metaverse`,
	}),
	p2pTradingClient: new P2PTradingClient({
		baseUrl: `${getEnv('REACT_APP_BASE_URL')}p2p`,
	}),
	chatRoomClient: new ChatRoomClient({
		host: 'api-testnet.skychain.live/colyseus',
		secure: true,
		debug: true,
	}),
	isSecurityDialog: false,
	chain: 'SPOT',
	supportedChains: [],
	supportedAssets: [],
} as SystemState;

const systemSlice = createSlice({
	name: 'systemSlice',
	initialState,
	reducers: {
		setModal(state, action: PayloadAction<Modal>) {
			state.modal = action.payload;
		},
		setTokenId(state, action: PayloadAction<string>) {
			state.metaverseJs?.setTokenId(action.payload);
			state.userIdentity?.setTokenId(action.payload);
			state.metaverseJs?.setTwoFactorToken(action.payload);
			state.p2pTradingClient?.setTokenId(action.payload);
			state.chatRoomClient?.setTokenId(action.payload);
		},
		changeStatusSecurityDialog(state, action: PayloadAction<boolean>) {
			state.isSecurityDialog = action.payload;
		},
		setChain(state, action: PayloadAction<Chain>) {
			state.chain = action.payload;
		},
		setSupportedAssets(state, action: PayloadAction<Asset[]>) {
			state.supportedAssets = action.payload;
		},
		setSupportedChains(state, action: PayloadAction<(string | undefined)[]>) {
			state.supportedChains = action.payload;
		},
	},
});

export const {
	setModal,
	setTokenId,
	changeStatusSecurityDialog,
	setChain,
	setSupportedAssets,
	setSupportedChains,
} = systemSlice.actions;
export default systemSlice.reducer;
