import { Box, Flex, Show, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import Container from '../../layouts/Container';
import BuySellSwitch from '../UI/BuySellSwitch';
import {
	AdOrderAssetSymbol,
	AdOrderType,
	QueryAdOrder,
} from 'skychain-p2p-trading-js/lib/dto';
import Refresh from './Refresh';

const CoinTabs = styled(Flex)``;

const ActiveMark = styled(Box)`
	position: absolute;
	bottom: 3px;
	left: 0;
	width: 100%;
	height: 2px;
	background: linear-gradient(90deg, #ffbf1a 0%, #fcd535 100%);
	border-radius: 2px 2px 0px 0px;
`;

// const cryptos = [
// 	{ key: 'usdt', symbol: 'usdt' },
// 	{ key: 'btc', symbol: 'btc' },
// 	{ key: 'busd', symbol: 'busd' },
// 	{ key: 'bnb', symbol: 'bnb' },
// 	{ key: 'eth', symbol: 'eth' },
// 	{ key: 'usdc', symbol: 'usdc' },
// ];

const cryptos = Object.values(AdOrderAssetSymbol).map((asset) => ({
	key: asset,
	symbol: asset,
}));

enum SwitchType {
	BUY = 'BUY',
	SELL = 'SELL',
}

export default function CryptoTabs({
	handleUpdateFilter,
	filterParams,
}: {
	handleUpdateFilter: (params: QueryAdOrder) => void;
	filterParams: QueryAdOrder;
}) {
	const switchValues = Object.values(SwitchType);
	const [activeValue, setActiveValue] = useState(SwitchType.BUY);

	// useEffect(() => {
	// 	if (filterParams.type === AdOrderType.BUY) {
	// 		setActiveValue(SwitchType[AdOrderType.BUY]);
	// 	}
	// 	if (filterParams.type === AdOrderType.SELL) {
	// 		setActiveValue(SwitchType[AdOrderType.SELL]);
	// 	}
	// }, [filterParams.type]);

	useEffect(() => {
		if (activeValue === SwitchType.BUY) {
			handleUpdateFilter({ ...filterParams, type: AdOrderType.SELL });
		} else {
			handleUpdateFilter({ ...filterParams, type: AdOrderType.BUY });
		}
	}, [activeValue]);

	return (
		<Box
			background={'#09122980'}
			height={{ base: 'auto', lg: '56px' }}
			padding={{ base: '12px 0px 0px', lg: '0px' }}
		>
			<Container>
				<Flex
					height={'100%'}
					alignItems={{ lg: 'center' }}
					direction={{ base: 'column', lg: 'row' }}
					gap={{ base: '0px', lg: '30px' }}
				>
					<Flex justifyContent={{ base: 'space-between', lg: 'flex-start' }}>
						<BuySellSwitch
							values={switchValues}
							activeValue={activeValue}
							setActiveValue={setActiveValue}
						/>

						<Show below="lg">
							<Refresh />
						</Show>
					</Flex>
					<CoinTabs
						height={{ lg: '100%', base: '56px' }}
						gap={'10px'}
						maxWidth={{ base: '100vw', lg: 'auto' }}
						overflow={'auto'}
					>
						{cryptos.map((crypto) => {
							return (
								<Flex
									key={crypto.key}
									height={'100%'}
									alignItems={'center'}
									padding={{ base: '0 10px', lg: '0 20px' }}
									position={'relative'}
									cursor={'pointer'}
									onClick={() => {
										handleUpdateFilter({
											...filterParams,
											assetSymbol: crypto.key,
										});
									}}
								>
									<Text textTransform={'uppercase'} color={'#737283'}>
										{crypto.symbol}
									</Text>
									{filterParams.assetSymbol &&
										crypto.key === filterParams.assetSymbol && <ActiveMark />}
								</Flex>
							);
						})}
					</CoinTabs>
				</Flex>
			</Container>
		</Box>
	);
}
