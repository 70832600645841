import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import MetaverseClient from 'metaverse-js';

const useWalletAddress = (network: string) => {
	const [address, setAddress] = useState<string | undefined>('');
	const metaverseJs = useSelector(
		(state: RootState) => state.system.metaverseJs
	);
	const getDepositAddress = async (
		metaverseJs: MetaverseClient,
		network: string
	) => {
		try {
			const res = await metaverseJs.getMyAddressDeposit(network);
			setAddress(res);
		} catch (error) {
			console.log('getDepositAddress', error);
		}
	};
	useEffect(() => {
		if (metaverseJs && network) {
			getDepositAddress(metaverseJs, network);
		}
	}, [metaverseJs, network]);

	return {
		address,
	};
};

export default useWalletAddress;
