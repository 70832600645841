import { Button, Text, chakra } from '@chakra-ui/react';
import React from 'react';
import RefreshIcon from '../Icons/RefreshIcon';

const RefreshButton = chakra(Button, {
	baseStyle: {
		background: '#484756',
		borderRadius: '5px',
		height: {
			base: 'max-content',
			lg: 'auto',
		},
		padding: {
			base: '7px 10px',
			lg: '10px 25px',
		},
	},
});

const RefreshText = chakra(Text, {
	baseStyle: {
		fontWeight: 400,
		marginLeft: '10px',
		color: '#ffffff',
	},
});

export default function Refresh() {
	return (
		<RefreshButton>
			<RefreshIcon />
			<RefreshText>Not now</RefreshText>
		</RefreshButton>
	);
}
