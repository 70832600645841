import { Box } from '@chakra-ui/react';
import { useState } from 'react';
import {
	AdOrderAssetSymbol,
	AdOrderFiatSymbol,
	AdOrderType,
	QueryAdOrder,
} from 'skychain-p2p-trading-js/lib/dto';
import Container from '../../layouts/Container';
import CryptoTabs from './CryptoTabs';
import FilterAndRefresh from './FilterAndRefresh';
import TradeData from './TradeData';

export default function Body() {
	const [filterParams, setFilterParams] = useState<QueryAdOrder>({
		type: AdOrderType.SELL,
		assetSymbol: AdOrderAssetSymbol.USDT,
		fiatSymbol: AdOrderFiatSymbol.BAHT,
	});

	const handleUpdateFilter = (params: QueryAdOrder) => {
		setFilterParams(params);
	};
	return (
		<div>
			<CryptoTabs
				handleUpdateFilter={handleUpdateFilter}
				filterParams={filterParams}
			/>
			<Box py={{ base: '0px', lg: '30px' }}>
				<FilterAndRefresh
					handleUpdateFilter={handleUpdateFilter}
					filterParams={filterParams}
				/>
				<Container>
					<TradeData filterParams={filterParams} />
				</Container>
			</Box>
		</div>
	);
}
