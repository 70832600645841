import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import TextGradient from '../UI/TextGradient';
import OutlineButton from '../UI/OutlineButton';
import { useDispatch } from 'react-redux';
import { setModal } from '../../redux/reducers/system.slice';

const Wrapper = styled(Box)`
	margin-top: 40px;
`;

const Header = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const Title = styled(Box)`
	font-weight: 500;
	font-size: 20px;
	line-height: 48px;
	color: #ffffff;
	@media screen and (max-width: 567px) {
		font-size: 16px;
	}
`;
export const Retangle = styled(Box)`
	background: linear-gradient(
		100.78deg,
		rgba(255, 255, 255, 0.116) 0.27%,
		rgba(255, 255, 255, 0.024) 102.68%
	);
	height: 1px;
	width: 100%;
`;
const Method = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	position: relative;
`;
const InfoWrapper = styled(Box)`
	display: flex;
	align-items: center;
	gap: 12px;
`;
const Image = styled.img`
	@media screen and (max-width: 567px) {
		width: 30px;
		height: 30px;
		object-fit: contain;
	}
`;
const Info = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;
	flex: 1;
`;
const InfoTitle = styled(Box)`
	font-size: 18px;
	line-height: 1;
	color: #ffffff;
`;
const InfoDescription = styled(Box)`
	font-size: 14px;
	line-height: 1;
	color: #737283;
	@media screen and (max-width: 567px) {
		font-size: 12px;
		margin-top: 10px;
		line-height: 18px;
	}
`;
const Methods = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 20px;
	margin: 16px auto;
	width: 100%;
`;

const StyledTextGradient = styled(TextGradient)`
	cursor: pointer;
	&:hover {
		filter: brightness(1.2);
	}
	@media screen and (max-width: 567px) {
		font-size: 14px;
	}
`;
const StyledOutlineButton = styled(OutlineButton)`
	@media screen and (max-width: 567px) {
		font-size: 14px;
		position: absolute;
		top: 0;
		right: 0;
		width: 92px;
		height: 30px;
	}
`;
const DepositFunds = () => {
	const dispatch = useDispatch();
	return (
		<Wrapper>
			<Header>
				<Title>Deposit Funds into Your Account</Title>
				<StyledTextGradient>Skip Guide</StyledTextGradient>
			</Header>
			<Retangle />
			<Methods>
				<Method pr={{ base: 0, lg: '65px' }}>
					<InfoWrapper>
						<Image src="/images/wallets/deposit.svg" />
						<Info>
							<InfoTitle>Deposit Crypto</InfoTitle>
							<InfoDescription>
								If you already have crypto currency, you can use deposit
								function to transfer them from other trading platforms or
								wallets to your account.
							</InfoDescription>
						</Info>
					</InfoWrapper>
					<StyledOutlineButton onClick={() => dispatch(setModal('deposit'))}>
						Deposit
					</StyledOutlineButton>
				</Method>
				{/* <Method pr={{ base: 0, lg: '65px' }}>
					<InfoWrapper>
						<Image src="/images/wallets/deposit.svg" />
						<Info>
							<InfoTitle>Express</InfoTitle>
							<InfoDescription>
								If you want to buy crypto directly using cash, this will be the
								best way for you.
							</InfoDescription>
						</Info>
					</InfoWrapper>
					<StyledOutlineButton>Buy</StyledOutlineButton>
				</Method> */}
			</Methods>
			<Retangle />
		</Wrapper>
	);
};

export default DepositFunds;
